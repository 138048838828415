import React, { Component } from 'react'
import {
    Link
} from "react-router-dom";

import withNavigateHook from './includes/withNavigateHook';
import { _getLocalLanguage, _getLocationCookie, _getLoginStatus, _setLocationCookie, _showOverlay } from './includes/Constants';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import { _getStaticPages } from './api/Common';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import HospitalMenu from './includes/HospitalMenu';
import HospitalHeader from './includes/HospitalHeader';
const language = _getLocalLanguage();

class ContactUs extends Component {
    constructor(props) {

        super(props);
        // state declaration
        this.state = {
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            user: {},
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide"
        }

    }
    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        let res = await _getLoginStatus();

        if (res['status']) {
            let content = await _getStaticPages('about_us');
            // console.log(content);
            await this.setState({
                user: res.data,
                content: content.data.result
            });
            await this.setState({
                user: res.data
            });
        } else {
            this.props.navigation('/home');
        }
        // _showOverlay();
    }
    render() {
        return (
            <React.Fragment>

                <Loader visibility={this.state.loader} />
                <HospitalHeader />
                <main role="main">

                    <section id="profile-editing-option-page" className="oparcheecommonCode">

                        <div className="profile-editing-optionContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg commonBoxInner">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    {/* <img className="responsive-img logoImg" src="images/small-logo.png" alt="Oparchee" /> */}

                                                    <h1>Contact Us</h1>

                                                </div>
                                                <div class="col s12">
                                                    <h1>Address:</h1>
                                                    <p>KDRR PRIVATE LIMITED</p>
                                                    <p>Flat No. B-102 Mountain View Appt. Bhatwadi Rees Tal-Khalapur Raigad</p>
                                                </div>
                                                <div class="col s12">
                                                    <h1>Reach Us at phone:</h1>
                                                    <p><a className='active link-color-blue' target="_blank" href="tel:+918423854269">+91 8423854269 </a></p>
                                                    <p><a className='active link-color-blue' target="_blank" href="tel:+917007661624">+91 7007661624</a></p>
                                                </div>
                                                <div class="col s12">
                                                    <h1>Reach Us at @email:</h1>
                                                    <p><a className='active link-color-blue' target="_blank" href="mailto:oparchee@gmail.com">oparchee@gmail.com </a></p>
                                                    <p><a className='active link-color-blue' target="_blank" href="mailto:support@oparchee.com">support@oparchee.com</a></p>
                                                </div>
                                                <div class="col s12">
                                                    <h1>Reach Us at Whatsapp:</h1>
                                                    <p><a className='active link-color-blue' target="_blank" href="https://wa.me/qr/I2UILYPVUH63B1/">Oparchee Whatsapp</a></p>
                                                </div>
                                                <div class="col s12 center-align">
                                                    <div className="mapDiv">
                                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120791.02787400104!2d73.06759846250003!3d18.899515699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e41189f931a7%3A0x7aea4945cec0df2f!2sMountain%20View%20Apartments!5e0!3m2!1sen!2sin!4v1679071260100!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120791.02787400104!2d73.06759846250003!3d18.899515699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e41189f931a7%3A0x7aea4945cec0df2f!2sMountain%20View%20Apartments!5e0!3m2!1sen!2sin!4v1679071260100!5m2!1sen!2sin" width="100%" height="350" style={{ "border": "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                        {/* <iframe src={'https://www.google.com/maps/search/?api=1&query='+ encodeURIComponent( this.props.location.state.address ) +   '&key=AIzaSyD7-bvtBa-9YNg-DfZWJsBKPBaUJVnArXs'} width="100%" height="350" style={{ "border": "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}

                                                    </div>
                                                </div>

                                            </div>



                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <HospitalMenu />

                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        )
    }
}

export default withNavigateHook(ContactUs);
