import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import { _logOut, _clearAllInterval, _getLocalLanguage } from './Constants';
import withNavigateHook from './withNavigateHook';
import { lang_en } from './language/en';
import { lang_hi } from './language/hi';
const language = _getLocalLanguage();
let $ = window.$;
class PatientsSideMenu extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            text_language: language.lang == 'en' ? lang_en : lang_hi
        }

    }

    /**
     * 
     */
    componentDidMount = async () => {
        await $('.sidenav').sidenav();
    }

    /**
    * 
    * @param {*} param 
    */
    _handleLogout = async (e) => {
        e.preventDefault();
        let res = await _logOut('Patient');
        this.props.navigation(res);
    }


    render() {

        return (
            <React.Fragment>

                <ul id="slide-out" className="sidenav">
                    <li className="menu-header-bg"><div className="user-view">
                        {/* <div className="background">
                            <img src="images/big-logo.png" />
                        </div> */}
                        <Link to="#user" className=""><img className="responsive-img menu-img" src="images/img3.png" /></Link>
                        <Link to="/patient-account"><span className="white-text name">{this.props.user.full_name}
                            <i className="material-icons right">keyboard_arrow_right </i>
                        </span>
                        </Link>

                    </div></li>
                    <li>
                        <Link to="/patient-account">
                            <img className="responsive-img menu-icon" src="images/menu-icon/user.png" alt="Account" />
                            {/* <i className="material-icons">person</i> */}
                            {this.state.text_language[0].account_text}
                        </Link>
                    </li>
                    <li>
                        <Link to="/about-us">
                        <img className="responsive-img menu-icon" src="images/menu-icon/about-us.png" alt="About Us" />
                            {/* <i className="material-icons">people</i> */}
                            {this.state.text_language[0].about_us_text}
                        </Link>
                    </li>
                    <li>
                        <Link to="/privacy-policy">
                        <img className="responsive-img menu-icon" src="images/menu-icon/privacy.png" alt="Privacy Policy" />
                            {/* <i className="material-icons">lock</i> */}
                            {this.state.text_language[0].privacy_policy_text}
                        </Link>
                    </li>
                    <li>
                        <Link to="/refund-policy">
                        <img className="responsive-img menu-icon" src="images/menu-icon/refund.png" alt="Refund Policy" />
                            {/* <i className="material-icons">people</i> */}
                            {this.state.text_language[0].refund_policy_text}
                        </Link>
                    </li>
                    <li>
                        <Link to="/terms-and-condition">
                        <img className="responsive-img menu-icon" src="images/menu-icon/terms.png" alt="Terms And Condition" />
                            {/* <i className="material-icons">people</i> */}
                            {this.state.text_language[0].terms_and_condition_text}
                        </Link>
                    </li>
                    {/* <li><Link to="#!"><i className="material-icons">chat</i> {this.state.text_language[0].faq_text}</Link></li> */}
                    <li>
                        <Link to="/contact-us">
                        <img className="responsive-img menu-icon" src="images/menu-icon/contact-us.png" alt="Contact Us" />
                            {/* <i className="material-icons">email</i> */}
                            {this.state.text_language[0].contact_us_text}
                        </Link>
                    </li>
                    <li><div className="divider"></div></li>
                    <li><p className='center-align'>{this.state.text_language[0].app_text} {this.state.text_language[0].version_text}: {this.state.text_language[0].v_text}-{window.localStorage.getItem("version")}</p></li>
                    {/* <li><a className="sidenav-close" href="#!">Clicking this will close Sidenav</a></li> */}
                </ul>

            </React.Fragment>

        )
    }
}

export default withNavigateHook(PatientsSideMenu)
