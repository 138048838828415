import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import DoctorsMenu from './includes/DoctorsMenu';
import DoctorsHeader from './includes/DoctorsHeader';
import { _getLoginStatus, _checkNull, _validateNumber, time_out, _showOverlay, _hideOverlay, _getUserDoctor } from './includes/Constants';
import { _getActiveBooking, _confirmBooking, _addBooking, _getDoctorSlots } from './api/Doctor';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import Loader from './includes/Loader';
let $ = window.$;
let date = new Date();
//const startDate = new Date(date.setDate(date.getDate() + 1));
//alert(startDate.setDate(startDate.getDate() + 1))
const startDate = date;
class AddBooking extends Component {
    /**
    * 
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            slot_list: [],
            user_doctor:{},
            message: '',
            type: '',
            visibility: 'hide',
            error_message: {
                name: '',
                age: '',
                problem: '',
                address: '',
                phone_number: ''
            },
            loader: "hide"
        }
    }

    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {
        $('select').formSelect();
        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            let res1 = await _getUserDoctor();
            let slot_list = await _getDoctorSlots(res1.data.id);
            await this.setState({
                user: res.data,
                slot_list: slot_list.data.result
            });
              //alert(startDate)
              await $("#booking_date").datepicker({
                format: 'dd-mm-yyyy',
                autoClose: true,
                minDate: startDate
                // disableDayFn:(current_date) =>this._handleDaysDisable(current_date)
            }).datepicker("setDate", startDate);
            $('#booking_date').val(startDate.getDate() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getFullYear());
            
            if (res['data']['user_type'] == 'Doctor') {
                if (res1['status']) {
                    await this.setState({
                        user_doctor: res1.data
                    });
                }

            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    /**
    * 
    * @param {*} e 
    */
    async _handleManualBooking(e) {
        e.preventDefault();
        await this.setState({
            loader: "show",
            message: '',
            type: 'success',
            visibility: 'hide'
        })
        // _showOverlay();
        const data = new FormData(e.target);
        let name = data.get('name');
        let age = data.get('age');
        let problem = data.get('problem');
        let address = data.get('address');
        let slot = data.get('slot');
        let phone_number = data.get('phone_number');
        let parchee_type = data.get('parchee_type');
        let booking_date = data.get('booking_date');
        let patient_id = 0;
        let res = await this._validateBooking(data);
        if (res['status']) {

            this.setState({
                error_message: res.error,
                type: 'success',
                // visibility: 'show',
                // loader: "hide"
            });

            let reg = await _addBooking(name, age, problem, address, 'Manual', this.state.user_doctor.id, slot, phone_number, parchee_type,booking_date,patient_id);
            if (reg.data.response == 'success') {
                this.setState({

                    message: reg.data.message,
                    type: 'success',
                    visibility: 'show',
                    loader: "hide"
                });
                setTimeout(() => {
                    return (
                        this.props.navigation("/doctors-dashboard")
                    )
                }, time_out);
                //_hideOverlay();
            } else {
                this.setState({
                    message: reg.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });
                //_hideOverlay();
            }

        } else {
            this.setState({
                error_message: res.error,
                loader: "hide",
                type: 'success',
                visibility: 'hide',
                loader: "hide"
            });
            // _hideOverlay();
        }

    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    _validateBooking = (data) => {
        let name = data.get('name');
        let age = data.get('age');
        let problem = data.get('problem');
        let address = data.get('address');
        let phone_number = data.get('phone_number');
        let response = [];
        response['status'] = false;
        response['error'] = [];

        if (typeof name != "undefined" && name != undefined && name.trim() != '' &&
            typeof age != "undefined" && age != undefined && age.trim() != '' &&
            typeof problem != "undefined" && problem != undefined && problem.trim() != '' &&
            typeof address != "undefined" && address != null && address.trim() != '' &&
            typeof phone_number != "undefined" && phone_number != null && phone_number.trim() != ''
        ) {
            if (!_validateNumber(age)) {
                response['error']['age'] = "Please provide a valid age in numbers.";
                if (!_validateNumber(phone_number)) {
                    response['error']['phone_number'] = "Please provide a valid phone number.";
                } else {
                    if (phone_number.toString().length == 10) {
                        // response['status'] = true;
                    } else {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    }

                }
            } else {
                if (!_validateNumber(phone_number)) {
                    response['error']['phone_number'] = "Please provide a valid phone number.";
                } else {
                    if (phone_number.toString().length == 10) {
                        response['status'] = true;
                    } else {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    }

                }
                //response['status'] = true;
            }
        } else {

            response['error']['name'] = _checkNull(name);
            response['error']['age'] = _checkNull(age);
            response['error']['problem'] = _checkNull(problem);
            response['error']['address'] = _checkNull(address);
            response['error']['phone_number'] = _checkNull(phone_number);
            if (response['error']['age'].trim() == '' || response['error']['age'] == null) {

                if (_validateNumber(age)) {
                    //response['status'] = true;
                } else {
                    response['error']['age'] = "Please provide a valid age in numbers.";

                }
            }
            if (response['error']['phone_number'].trim() == '' || response['error']['phone_number'] == null) {

                if (_validateNumber(phone_number)) {
                    if (phone_number.toString().length == 10) {
                        //response['status'] = true;
                    } else {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    }
                } else {
                    response['error']['phone_number'] = "Please provide a valid phone number";

                }
            }

        }

        return response;
    }

    /**
     * 
     * @returns 
     */
    _getSlotOptions = () => {

        if (this.state.slot_list.length > 0) {
            {this.initForm()}
            return (

                <React.Fragment>
                    
                    {
                        // console.log(this.state.slot_list.length)
                        this.state.slot_list.map((value, index) => {
                            //alert(value)
                            return (
                                //console.log(value)
                                <option value={value}>{value}</option>
                            )
                        })
                    }

                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                </React.Fragment>
            )
        }


    }

    initForm = () => {
        $('select').formSelect('destroy');
        $('select').formSelect();
    }

    render() {

        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <DoctorsHeader />
                <main role="main">

                    <section id="patient_details-page" className="oparcheecommonCode">

                        <div className="patient_detailsContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg white-bg center-align commonBoxInner">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    {/* <img className="responsive-img logoImg" src="images/logo.png" alt="Oparchee" /> */}

                                                    <h1>Details of Patient</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                <form method="post" onSubmit={(e) => this._handleManualBooking(e)}>

                                                    <div className="col s12">
                                                        <div className="input-field">
                                                            <select name="slot">
                                                                {
                                                                    this._getSlotOptions()
                                                                }

                                                            </select>
                                                            <label>Select appointment slot</label>
                                                        </div>
                                                        

                                                        <div className="input-field">
                                                            <select name="parchee_type" id="parchee_type">
                                                                <option value="New" selected>New</option>
                                                                <option value="Old">Old</option>
                                                            </select>
                                                            <label htmlFor="parchee_type">Parchee Type</label>
                                                        </div>
                                                        <div className="input-field">
                                                            <input type="text" className="datepicker validate" id="booking_date" name="booking_date" />
                                                            {/* <input type="text" placeholder={this.state.user.phone_number} defaultValue={this.state.user.phone_number}  /> */}

                                                            <label htmlFor="booking_date" className="active">Booking Date</label>
                                                            {/* <div className="error">{this.state.error_message.booking_date}</div> */}

                                                        </div>

                                                        <div className="input-field">

                                                            <input type="text" id="name" name="name" className="validate" />

                                                            <label htmlFor="name">Name</label>
                                                            <div className="error">{this.state.error_message.name}</div>

                                                        </div>
                                                        <div className="input-field">

                                                            <input type="text" id="phone_number" name="phone_number" className="validate" />

                                                            <label htmlFor="phone_number">Phone Number</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>

                                                        </div>

                                                        <div className="input-field">

                                                            <input type="text" id="age" name="age" className="validate" />

                                                            <label htmlFor="age">Age</label>
                                                            <div className="error">{this.state.error_message.age}</div>

                                                        </div>

                                                        <div className="input-field">

                                                            <input type="text" id="problem" name="problem" className="validate" />

                                                            <label htmlFor="problem">Problem</label>
                                                            <div className="error">{this.state.error_message.problem}</div>

                                                        </div>
                                                        <div className="input-field">

                                                            <textarea id="textarea1" name="address" className="materialize-textarea"></textarea>

                                                            <label htmlFor="textarea1">Address</label>
                                                            <div className="error">{this.state.error_message.address}</div>

                                                        </div>
                                                        {/* <Link to="/patient-book-appointment" className="btn btn-primary continuebtn">Submit</Link> */}
                                                        <button type="submit" className="btn btn-primary continuebtn">Submit</button>

                                                    </div>

                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <DoctorsMenu />
                {/* {
                    this.state.visibility == 'show'
                        ? */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                {/* //         :
                //         ''
                // } */}

            </React.Fragment>
        );
    }
}

export default withNavigateHook(AddBooking);
