import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import DoctorsMenu from './includes/DoctorsMenu';
import DoctorsHeader from './includes/DoctorsHeader';
import { _getLoginStatus, _showOverlay, _hideOverlay, time_out, refresh_time, _clearAllInterval, _clearInterval, _getUserDoctor } from './includes/Constants';
import { _getActiveBooking, _confirmBooking, _updateLiveBooking, _addBooking, _addPriscriptionImage } from './api/Doctor';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import Loader from './includes/Loader';
let $ = window.$;
let date = new Date();
const startDate = date;
// const startDate = new Date(date.setDate(date.getDate() + 1));
// alert(startDate.setDate(startDate.getDate() + 1))
class TodayBooking extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            default_list: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            user_doctor: {},
            set_interval_ref:0
        }
      
    }
    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {
        $('.modal').modal({
            dismissible: false
        });
        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            await this.setState({
                user: res.data
            });
            //alert(startDate)
            await $("#booking_date").datepicker({
                format: 'dd-mm-yyyy',
                autoClose: true,
                minDate: startDate
                // disableDayFn:(current_date) =>this._handleDaysDisable(current_date)
            }).datepicker("setDate", startDate);
            $('#booking_date').val(startDate.getDate() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getFullYear());

            if (res['data']['user_type'] == 'Doctor') {
                let res1 = await _getUserDoctor();
                //console.log(res1);
                if (res1['status']) {
                    await this.setState({
                        user_doctor: res1.data
                    });
                }

                await this._getUpdatedBooking();

                // setTimeout(async () => {
                //     await this._getAutoRefresh();
                // }, 20000);


            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    /**
    * 
    */
    _getUpdatedBooking = async () => {

        if (typeof this.state.user_doctor.id != "undefined") {
            //_showOverlay();
            this.setState({
                loader: "show"
            })
            let res1 = await _getActiveBooking(this.state.user_doctor.id);
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result,
                    default_list: res1.data.result,
                    loader: "hide"
                });
                // _hideOverlay();
            } else {
                this.setState({
                    list: [],
                    message: res1.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });
                //_hideOverlay();
            }
        }
        return;
    }

    /**
     * 
     * @returns 
     */
    _getAutoRefresh = async () => {
        
        let set_interval = setInterval(async () => {
            //console.log(this.set_interval);
            //await _clearInterval(this.state.set_interval);
            let res1 = await _getActiveBooking(this.state.user_doctor.id);
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result,
                    default_list: res1.data.result,
                    message: '',
                    type: 'success',
                    visibility: 'hide'
                });
            } else {
                this.setState({
                    list: [],
                    message: '',
                    type: 'error',
                    visibility: 'hide'
                });
            }
        }, refresh_time);

       await this.setState({
        set_interval_ref:set_interval
        });

        return;
    }

    /**
     * 
     */
    _getBookingList = () => {
        let total_counter = 0;

        if (Object.keys(this.state.list).length > 0) {

            return (
                <ul className="">



                    <li className={"active"} key={"list-0"}>
                        {/* <div className="collapsible-header">
                                          <h6>{Object.keys(this.state.list)[index]}</h6>
                                    </div> */}
                        <React.Fragment key={"collapsible-body-0"}>
                            {
                                Object.values(this.state.list).map((value1, index1) => {
                                    total_counter = total_counter + 1;
                                    return (
                                        <div className="#ffffff white lighten-5 z-depth-5 mb-10">
                                            <div key={"row-" + index1} className="row p-15">
                                                <div className="col s12">
                                                    <span className="black-text">
                                                        <p><b>Sr. No:</b>&nbsp;{total_counter}</p>
                                                        <p><b>Slot:</b>&nbsp;{value1.slot}</p>
                                                        <p><b>Token No:</b>&nbsp;{value1.booking_number}</p>
                                                        <p><b>Name:</b>&nbsp;{value1.patient_history.patient_details.name}</p>
                                                        <p><b>Age:</b>&nbsp;{value1.patient_history.patient_details.age}&nbsp;years</p>
                                                        <p><b>Problem:</b>&nbsp;{value1.patient_history.patient_details.problem}&nbsp;</p>
                                                        {
                                                            value1.token_status == 'open'
                                                                ?
                                                                <React.Fragment>
                                                                    <p className="detailBtn"><b>Token:</b>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {
                                                                            value1.token == "Pending"
                                                                                ?
                                                                                <React.Fragment>
                                                                                    <Link className="green-b" to="/show-patient-details" onClick={(e) => { this._updateLiveBooking(e, value1.id, 'Live') }} > In </Link>
                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    <Link className="red-b" to="/show-patient-details" onClick={(e) => { this._updateLiveBooking(e, value1.id, 'Done') }}> Out </Link>
                                                                                </React.Fragment>
                                                                                :
                                                                                (value1.token == "Live"
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <Link className="red-b" to="/show-patient-details" onClick={(e) => { this._updateLiveBooking(e, value1.id, 'Done') }}> Out </Link>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <b style={{ color: "green" }}>{value1.token}</b>
                                                                                )


                                                                        }

                                                                    </p>
                                                                </React.Fragment>

                                                                :
                                                                <React.Fragment><p className="detailBtn"><b>Token Status:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b style={{ color: "green" }}>{value1.token}</b></p></React.Fragment>
                                                                
                                                        }
                                                        <p className="detailBtn"><b>Next Appointment:</b>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <Link to="/" onClick={(e) => this._getFollowUpPatient(e, value1)} className="detailBtn" >Follow Up</Link>
                                                            &nbsp;
                                                        </p>
                                                        <p className="detailBtn"><b>Appointment Details:</b>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {/* <Link to="/show-patient-details" onClick={(e) => this._redirect(e, '/show-patient-details', value1)} state={{ data: value1 }}> Details </Link> */}
                                                            <Link to="/show-patient-details" state={{ data: value1 }}> Details </Link>
                                                        </p>
                                                        {
                                                            value1.patient_history.priscription_image == ""
                                                                ?
                                                                <p><b>Upload Prescription:</b>&nbsp;
                                                                    <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data">
                                                                        <input name="booking_id" id="booking_id" value={value1.patient_history.id} type="hidden" />

                                                                        <div className="input-field" >

                                                                            <div className="file-field input-field">

                                                                                <div className="btn">

                                                                                    <span><i className="material-icons">image</i></span>

                                                                                    <input type="file" name="priscription_image" accept="image/*" />

                                                                                </div>

                                                                                <div className="file-path-wrapper">

                                                                                    <input className="file-path validate" type="text" placeholder="Upload Prescription" />

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                        <button type="submit" className="btn btn-xs btn-primary continuebtn">Upload</button>

                                                                    </form>
                                                                </p>
                                                                :
                                                                ""


                                                        }


                                                    </span>
                                                </div>


                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </React.Fragment>

                    </li>

                </ul>
            )

        } else {
            return (

                <div className="col s12 center-align mt-20">
                    <img src="images/not-found.gif" alt="" className="circle responsive-img" />

                </div>

            )
        }

    }

    /**
     * 
     * @param {*} e 
     */
    async _handleManualBooking(e) {
        e.preventDefault();
        await this.setState({
            loader: "show",
        })
        // _showOverlay();
        const data = new FormData(e.target);
        let priscription_image = data.get('priscription_image');
        let booking_id = data.get('booking_id');
        let patient_id = 0;

        let reg = await _addPriscriptionImage(patient_id, priscription_image, booking_id);
        if (reg.data.response == 'success') {
            this.setState({
                message: reg.data.message,
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });

        } else {
            this.setState({
                message: reg.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            //_hideOverlay();
        }


    }

    /**
     * 
     * @param {*} e 
     * @param {*} data 
     */
    _getFollowUpPatient = async (e, data) => {
        e.preventDefault();
        // console.log(data)
        $('#name').val(data.patient_history.patient_details.name);
        $('#age').val(data.patient_history.patient_details.age);
        $('#phone_number').val(data.patient_history.patient_details.phone_number);
        $('#address').val(data.patient_history.patient_details.address);
        $('#problem').val(data.patient_history.patient_details.problem);
        $('#patient_id').val(data.patient_id);

        $('#follow-up-modal').modal('open');

    }

    /**
    * 
    * @param {*} booking_id 
    * @param {*} status 
    */
    _updateLiveBooking = async (e, booking_id, status) => {
        e.preventDefault();
        // alert('ww');
        //_showOverlay();
        await this.setState({
            loader: "show"
        })
        let res1 = await _updateLiveBooking(booking_id, status);
        if (res1.data.response == "success") {
            //_hideOverlay();
            this.setState({
                message: res1.data.message,
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });

            setTimeout(async () => {
                this._getUpdatedBooking();
            }, time_out);

        } else {
            this.setState({
                message: res1.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            //_hideOverlay();
        }
    }

    /**
     * 
     */
    _initAccordion = () => {
        $('.collapsible').collapsible()
    }
    /**
     * 
     * @returns 
     */
    _clearInterval = async () => {
        await _clearInterval(this.state.set_interval_ref);
        return;
    }

    /**
     * 
     * @param {*} e 
     * @param {*} page 
     */
    _redirect = async (e, page, value) => {
        e.preventDefault();
        await _clearAllInterval();
        //console.log(value)
        if (typeof value != null && value != "") {
            this.props.navigation({
                pathname: page, state: { data: value }
            });
        } else {
            this.props.navigation(page);
        }
    }



    /**
    * 
    * @param {*} e 
    */
    _handleFollowUpBooking = async (e) => {
        e.preventDefault();
        await this.setState({
            loader: "show",
            message: '',
            type: 'success',
            visibility: 'hide'
        })
        // _showOverlay();
        const data = new FormData(e.target);
        let name = data.get('name');
        let age = data.get('age');
        let problem = data.get('problem');
        let address = data.get('address');
        let slot = 'Morning';
        let phone_number = data.get('phone_number');
        let parchee_type = 'Old';
        let booking_date = data.get('booking_date');
        let patient_id = data.get('patient_id');
        let reg = await _addBooking(name, age, problem, address, 'Manual', this.state.user_doctor.id, slot, phone_number, parchee_type, booking_date, patient_id);
        if (reg.data.response == 'success') {
            this.setState({

                message: "Follow Up booking added succeesully.",
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });
            setTimeout(() => {
                $('#follow-up-modal').modal('close');
            }, time_out);
            //_hideOverlay();
        } else {
            this.setState({
                message: reg.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            //_hideOverlay();
        }
    }

    /**
     * 
     * @returns 
     */
    _followUpBookingModal = () => {
        return (
            <div id="follow-up-modal" className="modal  br-radius custom-modal ">
                <div className="modal-content">
                    <div className='m-center'>
                        <h4>Follow Up Booking</h4>
                    </div>

                </div>
                <form method="post" onSubmit={(e) => this._handleFollowUpBooking(e)} className="w-80-p mt-20">
                    <input name="patient_id" id="patient_id" type="hidden" />
                    <div className="input-field">
                        <input id="name" name="name" type="text" readonly />
                        <label htmlFor="name" className="active">Name</label>
                    </div>
                    <div className="input-field">
                        <input id="age" name="age" type="text" readonly />
                        <label htmlFor="age" className="active">Age</label>
                    </div>
                    <div className="input-field">
                        <input id="problem" name="problem" type="text" readonly />
                        <label htmlFor="problem" className="active">Problem</label>
                    </div>
                    <div className="input-field">
                        <input id="phone_number" name="phone_number" type="text" readonly />
                        <label htmlFor="phone_number" className="active">Phone Number</label>
                    </div>
                    <div className="input-field">
                        <input id="address" name="address" type="text" readonly />
                        <label htmlFor="address" className="active">Address</label>
                    </div>
                    <div className="input-field">
                        <input type="text" className="datepicker validate" id="booking_date" name="booking_date" />

                        <label htmlFor="booking_date" className="active">Booking Date</label>

                    </div>

                    <div className="modal-footer p-20 w-80-p">
                        <a href="#!" className="modal-close btn red-b float-l">Cancel</a>
                        {/* <Link to="/" className="modal-close btn green-b float-r">Update</Link> */}
                        <button type="submit" className="modal-close btn green-b float-r">Submit</button>

                    </div>
                </form>
            </div>
        )
    }


    /**
     * 
     * @param {*} e 
     */
    _handleFilter = async(e) => {
        //console.log(this.state.set_interval_ref);
        await _clearInterval(this.state.set_interval_ref);
        //console.log(this.state.set_interval_ref);
        let searchStr = e.target.value;
        let arr = this.state.default_list;
        let new_array = [];
        // console.log(arr);
        // console.log(searchStr);
        new_array = arr.filter(x => x.patient_history.patient_details.name.toLowerCase().includes(searchStr.toLowerCase()));
        //new_array = arr.filter(x => console.log(x.patient_history.patient_details.name.toLowerCase()));
        //console.log(new_array);
        await this.setState({
            list: new_array
        });

    }

    /**
     * 
     * @returns 
     */
    render() {
        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <DoctorsHeader interval={this.state.set_interval_ref} />
                <main role="main">

                    <section id="doctor_dashboard-page" className="oparcheecommonCode">

                        <div className="doctor_dashboardContent">

                            <div className="container">
                                <div className="row">


                                    <div className="col s12 mt-10 ">

                                        <div className="strip z-depth-5">

                                            <div className="row">

                                                <div className="col s3 left-align">

                                                    {/* <i className="material-icons">chevron_left</i> */}

                                                </div>

                                                <div className="col s6 center-align">

                                                    <div className="bookingTxt"><h3>
                                                        <i className="material-icons">calendar_today</i>
                                                        <span>Today's Booking</span></h3></div>

                                                </div>

                                                <div className="col s3 right-align">

                                                    {/* <i className="material-icons">chevron_right</i> */}

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col s12 mt-10">
                                        <div className="locationName center-align z-depth-5" id="stickySearch">
                                            <form className="navbar-form" role="search" autoComplete="off">
                                                <div className="input-group" id="searchDiv">
                                                    <input type="text" className="form-control" onKeyUp={(e) => { this._handleFilter(e) }} placeholder="Search..." name="filter-doc" id="filter-doc" />
                                                    <div className="input-group-btn">
                                                        <button className="btn btn-default searchBtn" type="btn">
                                                            <i className="material-icons">search</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                    <div className="col s12 mt-10">
                                        {
                                            this._getBookingList()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                    </section>
                </main>
                {
                    this._followUpBookingModal()
                }
                <DoctorsMenu />

                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment >
        );
    }
}

export default withNavigateHook(TodayBooking);
