import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import { time_out, _checkNull } from './includes/Constants';
import { _savePassword } from './api/Common';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import Loader from './includes/Loader';
class GeneratePassword extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            error_message: {
                password: '',
                confirm_password: ''
            },
            table: '',
            message: '',
            type: '',
            visibility: 'hide',
            token: '',
            loader:'hide'
        }
    }

    /**
     * 
     */
    componentDidMount = () => {
        let searchParams = new URLSearchParams(window.location.search);
        let token = searchParams.get('forget-token');
        let table = searchParams.get('type');
        this.setState({
            token: token,
            table: table
        })
    }

    /**
     * 
     * @param {*} e 
     */
    _handleResetPassword = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        let password = data.get('password');
        let confirm_password = data.get('confirm_password');
        let res = await _checkNull(password);
        let res1 = await _checkNull(confirm_password);
        if (res != '') {
            if (res1 != '') {
                this.setState({
                    error_message: {
                        confirm_password: res1,
                        password: res
                    }
                });
            } else {
                this.setState({
                    error_message: {
                        password: res
                    }
                });
            }


        } else {
            if (res1 != '') {
                this.setState({
                    error_message: {
                        confirm_password: res1,
                    }
                });
            } else {
                if (password == confirm_password) {
                    this.setState({
                        loader:"show"
                    });
                    let res2 = await _savePassword(password, this.state.token, this.state.table);

                    if (res2.data.response == 'success') {
                        this.setState({
                            error_message: {
                                confirm_password: '',
                                password: ''
                            },
                            message: res2.data.message,
                            type: 'success',
                            visibility: 'show',
                            loader:"hide"
                        });
                        setTimeout(() => {
                            if (this.state.table == 'Patient') {
                                return (this.props.navigation("/home"))
                            } else {
                                return (this.props.navigation("/home"))
                            }
                        }, time_out);
                    } else {
                        this.setState({
                            error_message: {
                                confirm_password: '',
                                password: ''
                            },
                            message: res2.data.error,
                            type: 'error',
                            visibility: 'show',
                            loader:"hide"
                        });

                    }

                } else {
                    this.setState({
                        error_message: {
                            confirm_password: 'Confirm password should be same as password.',
                            password: ''
                        }
                    });
                }
            }


        }
    }


    render() {

        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <main role="main">

                    <section id="login-page" className="oparcheecommonCode">

                        <div className="loginContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg commonBox">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" />

                                                    <h1>Password Recover</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                <div className="col s12">

                                                    <form onSubmit={(e) => this._handleResetPassword(e)} method="post" >

                                                        <div className="input-field">

                                                            <input type="password" id="password" name="password" className="validate" />

                                                            <label htmlFor="password">Password</label>
                                                            <div className="error">{this.state.error_message.password}</div>

                                                        </div>
                                                        <div className="input-field">

                                                            <input type="password" id="confirm_password" name="confirm_password" className="validate" />

                                                            <label htmlFor="confirm_password">Confirm Password</label>
                                                            <div className="error">{this.state.error_message.confirm_password}</div>

                                                        </div>

                                                        <button type="submit" className="btn btn-primary continuebtn">Reset Password</button>
                                                    </form>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(GeneratePassword);
