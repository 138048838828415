import React, { Component } from 'react';

import {
  Link
} from "react-router-dom";
import { _updateNotificationToken } from '../api/Common';
import { _logOut, _clearAllInterval, _getLoginStatus,_getUserDoctor } from './Constants';
import DoctorsSideMenu from './DoctorsSideMenu';
import withNavigateHook from './withNavigateHook';
let $ = window.$;
class DoctorsHeader extends Component {

  /**
  * 
  * @param {*} props 
  */
  constructor(props) {
    super(props);
    this.action = window.location.pathname.split("/").pop();
    this.state = {
      user: {},
      user_doctor:{}
    }

  }

  /**
  * 
  */
  componentDidMount = async () => {
    let res = await _getLoginStatus();

    if (res['status']) {
      await this.setState({
        user: res.data
      });
    }

    let res1= await _getUserDoctor();
    //console.log(res1);
    if (res1['status']) {
        await this.setState({
          user_doctor: res1.data
        });
      }

    await _clearAllInterval();
  }



  /**
   * 
   * @param {*} param 
   */
  _handleLogout = async (e) => {
    e.preventDefault();
    await _updateNotificationToken('',this.state.user.id,'Doctor','logout')
    let res = await _logOut('Doctor');
    this.props.navigation(res);
  }

  /**
     * 
     * @param {*} e 
     */
  _goBack = (e) => {
    e.preventDefault();
    this.props.navigation(-1)
  }


  /**
   * 
   */
  _closeSideNav = () => {
    $('.sidenav').sidenav('close')
  }

  render() {

    return (

      <React.Fragment>
        <header>
          {
            this._closeSideNav()
          }
         
          <div className="oparchee-bg1">

            <div className="container">

              <div className="row">

                <div className="col s8">

                  <div className="loginTxt left-align">
                    {
                      this.action != "doctors-dashboard" && this.action != "doctors-history" && this.action != "doctors-equipment" && this.action != "doctors-account" 
                      && this.action != "doctor-list" ?
                        <React.Fragment>
                          <Link to="/" onClick={(e) => { this._goBack(e) }}> <i className="material-icons">arrow_back</i>  </Link>
                          {/* {this.action.replaceAll('-', ' ').replaceAll('doctors', '').replaceAll('doctor', '').toUpperCase()} */}
                        </React.Fragment>
                        :
                        <a href="#!" data-target="slide-out" className="button-collapse sidenav-trigger show-on-large"><i className="material-icons">menu</i></a>
                    }
                    { this.state.user_doctor != null &&  typeof this.state.user_doctor.first_name != 'undefined' && this.state.user_doctor.first_name!=null  ?' (Dr. '+this.state.user_doctor.first_name+' '+this.state.user_doctor.last_name+')':''}


                  </div>

                </div>

                <div className="col s4">

                  <div className="skipTxt right-align">
                    <Link to="/doctors-dashboard" > <i className="material-icons" title="Home">home</i></Link> 
                    {/* O-Parchee */}
                    {/* <Link to="/" onClick={(e) => { this._handleLogout(e) }}>Logout</Link> */}
                  </div>

                </div>

              </div>

            </div>

          </div>

        </header>
        <DoctorsSideMenu user={this.state.user} />
      </React.Fragment>
    );
  }
}

export default withNavigateHook(DoctorsHeader);
