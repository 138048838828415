import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import DoctorsMenu from './includes/DoctorsMenu';
import { _getLoginStatus, _showOverlay, _hideOverlay, _logOut, FILE_URL, _getUserDoctor, login_r } from './includes/Constants';
import { _getActiveBooking, _confirmBooking } from './api/Doctor';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import DoctorsHeader from './includes/DoctorsHeader';
import { _updateNotificationToken } from './api/Common';
import LogOutModal from './includes/LogOutModal';

class DoctorsAccount extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            user_doctor: {},
            message: '',
            type: '',
            visibility: 'hide'
        }
    }
    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {
        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Doctor') {
                let res1 = await _getUserDoctor();
                //console.log(res1);
                if (res1['status']) {
                    await this.setState({
                        user_doctor: res1.data
                    });
                }
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    /**
     * 
     * @param {*} param 
     */
    _handleLogout = async (e) => {
        e.preventDefault();
        await this.setState({
             message: "Logged out successfully",
            type: 'success',
            visibility: 'show',
            loader: "hide"
        });
        await _updateNotificationToken('', this.state.user.id, 'Doctor', 'logout')
        let res = await _logOut('Doctor');
        setTimeout(() => {
            this.props.navigation(res);
        }, login_r);
    }

    render() {

        return (
            <React.Fragment>
                <DoctorsHeader />
                <main role="main">
                    <section id="account-page" className="oparcheecommonCode">
                        <div className="accountContent">
                            <section className="profileBox">
                                <div className="container">

                                    <div className="row">
                                        <div className="col s12 negative-margin-bottom">

                                            <div className="card">

                                                <img className="responsive-img doctorImg" src={typeof this.state.user_doctor.image != "undefined" && this.state.user_doctor.image != "" && this.state.user_doctor.image != null ? FILE_URL + this.state.user_doctor.image : "images/img3.png"} alt="" />
                                                {

                                                    <React.Fragment>
                                                        <div className="doctorName center-align">Dr {(typeof this.state.user_doctor.first_name != "undefined") && (typeof this.state.user_doctor.last_name != "undefined") ? this.state.user_doctor.first_name + " " + this.state.user_doctor.last_name : ""}</div>
                                                        {/* <h5 class="grayTxt center-align">{typeof this.state.user.doctor_detail.type != "undefined"?this.state.user.doctor_details.type:""}</h5> */}
                                                    </React.Fragment>

                                                }

                                            </div>

                                        </div>

                                        <div className="col s12 negative-margin-bottom">

                                            <ul className="moreList">
                                                <li>
                                                    <Link to="/doctors-edit-profile">
                                                        <i className="material-icons">account_circle</i> <strong>Edit Profile</strong> <span><i className="material-icons">keyboard_arrow_right
                                                        </i></span>
                                                    </Link>
                                                </li>

                                                {/* <li>
                                                    <Link to="/doctors-clinic-setting">
                                                        <i className="material-icons">local_hospital</i> <strong>Hospital Settings</strong> <span><i className="material-icons">keyboard_arrow_right
                                                        </i></span>
                                                    </Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/doctors-hospital-timing">
                                                        <i className="material-icons">access_time</i> <strong>Doctor Timings</strong> <span><i className="material-icons">keyboard_arrow_right
                                                        </i></span>
                                                    </Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="/doctor-change-password">
                                                        <i className="material-icons">lock</i> <strong>Change Password</strong> <span><i className="material-icons">keyboard_arrow_right
                                                        </i></span>
                                                    </Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/doctors-account">
                                                        <i className="material-icons">help</i> <strong>Help & Support</strong> <span><i className="material-icons">keyboard_arrow_right
                                                        </i></span>
                                                    </Link>
                                                </li>

                                            </ul>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col s12 negative-margin-bottom">
                                            <button type="btn" className="btn btn-primary continuebtn log-btn" onClick={(e) => { this._handleLogout(e) }}>Logout</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="row">
                            <div className='col s12'>
                                <p className='center-align'>App Version: V-{window.localStorage.getItem("version")}</p>
                            </div>

                        </div>
                    </section>
                </main>
                {/* {
                    this.state.visibility == 'show'
                        ?
                        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                        :
                        ''
                } */}
                <LogOutModal message={this.state.message} type={this.state.type} visibility={this.state.visibility} />

                <DoctorsMenu />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(DoctorsAccount);
