/**
 * Component for the No internet Connection
 */
/* eslint-disable */
import React, { Component } from 'react'


export default class NoConnectivity extends Component {
    render() {
        return (
            <React.Fragment>

                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg center-align commonBox">

                                            <div className="row mb-40">

                                                <div className="col s12">
                                                    <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" />
                                                </div>
                                                <div className="col s2"></div>
                                                <div className="col s8">
                                                    <h6>
                                                        Oops ! It seems you dont have an Internet Connection   <i className="material-icons">cell_wifi</i>.
                                                    </h6>
                                                    <h6>Please connect to Internet to use the Oparchee App.</h6>
                                                </div>
                                                <div className="col s2"></div>
                                            </div>


                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>


                </main>
                {/* <div className="row">
                    <div className="col s12">
                        <center>
                            <div className="middle-text">
                            <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" />
                            <h6>
                                Oops ! It seems you dont have an Internet Connection   <i className="material-icons">cell_wifi</i>.
                            </h6>
                            <h6>Please connect to Internet to continue.</h6>
                            <img className="responsive-img logoImg" src="images/no-internet.png" alt="Oparchee" />
          
                            </div>
                       
                        </center>
                    </div>
                </div> */}


            </React.Fragment >
        )
    }
}
