import React, { Component } from 'react'
import axios from 'axios';
import {API_URL, FIREBASE_API_URL, _getToken} from '../includes/Constants';
const headers = {
    "Content-Type":"application/json"
    // ,
    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    // "Access-Control-Allow-Origin": "*"
};

const fcm_header={
    "Content-Type":"application/json",
    "Authorization": "key=BIv6dEk4fSH4MPdAqZF_V4UOi3GlkinFhXhSt_nfkjNBZTq5FPgnIMOAa7SdtHjY0qz7b7QLZaulH4XMdVEn4gE"
}



/**
 * 
 * @param {*} email 
 * @param {*} password 
 */
export const _loginUser =  async(phone_number,password,user_type) =>{
    const url = `${API_URL}`+user_type+`-login.json`;
    let formData = {phone_number : phone_number,password:password}
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}
 

export const _checkAppVersion =  async(version) =>{
    const url = `${API_URL}version.json?version=${version}`;
    let formData = {version:version}
    return axios({
        url: url,
        method : 'GET',
        headers: headers,
        crossDomain:true,
        //data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}


/**
 * 
 */
export const _getCountryCode =  async() =>{
    const url = `${API_URL}country-code.json`;
    return axios({
        url: url,
        method : 'GET',
        headers: headers,
        crossDomain:true,
  
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 */
export const _getCities =  async() =>{
    const url = `${API_URL}get-cities.json`;
    return axios({
        url: url,
        method : 'GET',
        headers: headers,
        crossDomain:true,
  
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}
 
/**
 * 
 * @param {*} page 
 * @returns 
 */
export const _getStaticPages =  async(page) =>{
    const url = `${API_URL}get-static-pages.json`;
    let formData = {page:page}
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 * @param {*} type 
 */
export const _getDashboardBookingCount= async(doctor_id)=>{
   
    const url = `${API_URL}get-dashboard-booking-count.json`;
    let formData = {doctor_id:doctor_id}
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}

/**
 * 
 * @param {*} email 
 * @param {*} first_name 
 * @param {*} last_name 
 * @param {*} user_name 
 * @param {*} country_code 
 * @param {*} phone_number 
 * @param {*} organisation 
 * @param {*} terms 
 * @param {*} cookies 
 */
export const _registerUser= async(full_name,phone_number,password,)=>{
   
    //const url = `${API_URL}registration.json`;
   // console.log('1');
    const url = `${API_URL}registration.json`;
    let formData = {full_name:full_name,phone_number:phone_number,password:password}
   
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}
/**
 * 
 * @param {*} type 
 * @param {*} value 
 */
export const _checkFields= async(type,value)=>{
    const url = `${API_URL}validate-field.json`;
    let formData = {filter_name:type,filter_value:value}
   
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}
/**
 * 
 * @param {*} token 
 */
export const _validateRegistrationToken= async(token,type)=>{
    const url = `${API_URL}validate-registration-token.json`;
    let formData = {token:token,type:type}
   
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}


/**
 * 
 * @param {*} token 
 */
export const _validateToken= async(token)=>{
    let headers = {
        'Content-Type':'application/json',
        'Authorization': 'Bearer '+token,
    };
    const url = `${API_URL}validate-jwt-token.json`;
    let formData = {token:token}
   
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}

/**
 * 
 * @param {*} email 
 * @param {*} type 
 * @returns 
 */
export const _forgotPassword= async(email,type)=>{
    
    const url = `${API_URL}resets-password.json`;
    let formData = {email:email,type:type}
   
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}

/**
 * 
 * @param {*} password 
 * @param {*} token 
 */
 export const _savePassword= async(password,token,type)=>{
    const url = `${API_URL}generate-password.json`;
    let formData = {token:token,password:password,type:type}
   
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}

export const _changePassword= async(password,id,type)=>{
    const url = `${API_URL}change-password.json`;
    let formData = {id:id,password:password,type:type}
   
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}

/**
 * 
 * @param {*} token 
 * @param {*} id 
 * @param {*} type 
 * @returns 
 */
export const _updateNotificationToken= async(token,id,type,page)=>{
    const url = `${API_URL}update-notification-token.json`;
    let formData = {id:id,token:token,type:type,page:page}
   return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
}

// export const _sendPushNotification =async(title,body)=>{
//     let icon="https://oparchee.com/img/favicon.png";
//     let token =await _getToken();
//     let new_token="";
//       if(typeof token !='undefined' && token !=null && token['status'] == true){
//         new_token=token['token'];
//       }
//     let data={
//         "data": {
//           "notification": {
//               "title": title,
//               "body": body,
//               "icon": icon,
//           }
//         },
//         "to": new_token
//       };
//     return axios({
//         url: url,
//         method : 'POST',
//         headers: headers,
//         crossDomain:true,
//         data:formData
//     }).then(function(response){
//         return response;
//     }).catch(function(error){
//         return error.response;
//     });
    

// }


 


