import React, { Component } from 'react'
import {
    Link,
} from "react-router-dom";
import PatientPageHeader from './includes/PatientPageHeader';
import PatientMenu from './includes/PatientMenu';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import { _getCities } from './api/Common';
import { FILE_URL, _getLocalLanguage, _getLocationCookie, _getLoginStatus } from './includes/Constants';
import { _getGovernmentHospitals } from './api/Patient';
import withNavigateHook from './includes/withNavigateHook';
const language = _getLocalLanguage();


class GovermentHospitals extends Component {

    /**
    * 
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            data: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            selected_location: _getLocationCookie(),
            hospital_list: {}
        }
        // console.log(this.props.location);
    }

    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {

        let res = await _getLoginStatus();
        let hospital_list = await _getGovernmentHospitals(res.data.id, this.state.selected_location);

        if (res['status']) {
            await this.setState({
                user: res.data,
                hospital_list: hospital_list.data.result
            });
            await this.setState({
                user: res.data
            });
        } else {
            this.props.navigation('/home');
        }
        // _showOverlay();
    }

    /**
     * 
     */
    _makeGovernmentHospital = () => {
        if (Object.keys(this.state.hospital_list).length > 0) {
            return (

                Object.values(this.state.hospital_list).map((value, index) => {

                    return (
                        <div className="card hoverable">
                            <div className="row  mb-10">
                                <div className="col s12">
                                    <Link   to="/gov-hospital-department" state={{ "name":value.name,"id":value.id }} >
                                        <div>

                                            <img className="responsive-img w-per-80 p-20" src={FILE_URL+ value.image} alt={value.name} />
                                            <h6>
                                                {value.name}
                                            </h6>
                                            <p>
                                                {value.address}
                                            </p>
                                            <p>
                                                {value.pincode}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })


            )
        }

    }

    /**
     * 
     * @returns 
     */
    render() {
        return (
            <React.Fragment>

                <PatientPageHeader />
                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">

                                        <div className="white-bg center-align commonBoxInner">
                                            <div className="row">
                                                <div className="col s12 center-align ">
                                                    <h1>{this.state.text_language[0].government_text} {this.state.text_language[0].hospitals_text}</h1>
                                                </div>

                                                <div className="col s12 center-align ">
                                                    {/* <img className="responsive-img" src="images/medical-emergency-banner.png" alt="Medical Emergency" /> */}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col s12'>
                                                    {
                                                        this._makeGovernmentHospital()
                                                    }

                                                </div>


                                            </div>
                                            {/* <div className="row">
                                                <div className="col s12">
                                                    <div className="card blue-grey darken-1 ">
                                                        <div className='row p-20 white-text'>
                                                            <div className="col s12">
                                                                <img className="responsive-img w-100" src="images/call-center-service.png" alt="Call Center" />
                                                            </div>
                                                            <div className="col s12">
                                                                <p>
                                                                    {this.state.text_language[0].emergency_order_confirmation_call_text}
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <PatientMenu />
            </React.Fragment>
        );
    }

}

export default withNavigateHook(GovermentHospitals);
