
import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import DoctorsMenu from './includes/DoctorsMenu';
import DoctorsHeader from './includes/DoctorsHeader';
import { _getLoginStatus, _showOverlay, _hideOverlay, _clearAllInterval, _clearInterval, refresh_time } from './includes/Constants';
import { _getAllBooking, _getHospitalDoctorListArray } from './api/Doctor';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import Loader from './includes/Loader';
import HospitalHeader from './includes/HospitalHeader';
import HospitalMenu from './includes/HospitalMenu';
const startDate = new Date();
let counter = 0;
let $ = window.$;
class HospitalHistory extends Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            doctor_list: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            selected_user: 0,
            selected_type: 'Hospital'
        }

    }
    /**
      * 
      * @returns 
      */
    componentDidMount = async () => {
        let app = this;

        //alert(  $("#datepicker").datepicker("getDate"));
        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            await this.setState({
                user: res.data,
                selected_user: res.data.id
            });
            await $("#datepicker").datepicker({
                format: 'yyyy-mm-dd',
                onSelect: function (e) {
                    app._handleChange(e);
                },
                autoClose: true
            }).datepicker("setDate", startDate);
            $('#datepicker').val(startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate());
            //alert(startDate);
            if (res['data']['user_type'] == 'Doctor') {
                let res1 = await _getHospitalDoctorListArray(res['data']['id']);
                if (res1.data.response == "success") {
                    this.setState({
                        doctor_list: res1.data.result,
                    });
                }
                //await this._getUpdatedBooking(res);
                setTimeout(async () => {
                    await this._getAutoRefresh();
                }, 20000);
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
        $('select').formSelect();
    }



    /**
    * 
    */
    _getUpdatedBooking = async (res) => {
        //alert(this.state.selected_type);
        if (typeof res.data.id != "undefined" && res.data.id != null) {
            //_showOverlay();
            await this.setState({
                loader: "show",
                message: '',
                type: 'success',
                visibility: 'hide',
            })
            let date = $("#datepicker").val();

            if (typeof date != "undefined" && date != "") {
                let res1 = await _getAllBooking(this.state.selected_user, date, this.state.selected_type);
                if (res1.data.response == "success") {
                    //alert(date);
                    this.setState({
                        list: res1.data.result,
                        message: res1.data.message,
                        type: 'success',
                        visibility: 'hide',
                        loader: "hide"
                    });
                    //_hideOverlay();
                } else {
                    //alert(2)
                    this.setState({
                        list: [],
                        message: res1.data.error,
                        type: 'error',
                        visibility: 'show',
                        loader: "hide"
                    });
                    //_hideOverlay();
                }
            } else {
                await this.setState({
                    loader: "hide"
                })
            }


        }
        return;
    }

    /**
   * 
   * @returns 
   */
    _getAutoRefresh = async () => {

        this.set_interval = setInterval(async () => {
            //alert(this.state.selected_user)

            await _clearInterval(this.set_interval);
            let date = $("#datepicker").val();

            let res1 = await _getAllBooking(this.state.selected_user, date, this.state.selected_type);
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result,
                    message: '',
                    type: 'success',
                    visibility: 'hide'
                });

            } else {
                if (counter == 0) {
                    this.setState({
                        list: [],
                        message: res1.data.error,
                        type: 'error',
                        visibility: 'show'
                    });
                }
            }
            counter = counter + 1;
        }, refresh_time);

        return;
    }

    /**
     * 
     */
    _getBookingList = () => {
        let total_counter=0;
        if (Object.keys(this.state.list).length > 0) {
            if (this.state.selected_type == 'Doctor') {
                return (
                    Object.values(this.state.list).map((value, index) => {
                        //console.log(value);
                        return (
                            Object.values(value).map((value1, index1) => {
                                total_counter=total_counter+1;
                                return (
                                    <div key={"div-" + index1} className="card-panel grey lighten-5 z-depth-1">
                                        <div className="row valign-wrapper">

                                            <div className="col s3">

                                                <div className="ribbonTxt">New</div>

                                                <img src="images/img3.png" alt="" className="circle responsive-img img-50" />

                                            </div>

                                            <div className="col s6">

                                            <span className="black-text">
                                                <p><b>S.no:</b>&nbsp;{total_counter}</p>
                                                <p><b>Slot:</b>&nbsp;{value1.slot}</p>
                                                <p><b>Name:</b>&nbsp;{value1.patient_history.patient_details.name}</p>
                                                <p><b>Age:</b>&nbsp;{value1.patient_history.patient_details.age}&nbsp;years</p>
                                                <p><b>Problem:</b>&nbsp;{value1.patient_history.patient_details.problem}</p>
                                                <p><b>Address:</b>&nbsp;{value1.patient_history.patient_details.address}&nbsp;</p>
                                            </span>

                                            </div>
                                            <div className="col s3">
                                                <p className="detailBtn">

                                                    <Link to="/show-patient-details" state={{ data: value1 }}> Details </Link>
                                                </p>

                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        )

                    })
                )
            } else {
                return (
                    Object.values(this.state.list).map((value, index) => {
                      
                        return (
                            Object.values(value).map((value1, index1) => {
                                //console.log(Object.keys(this.state.list));
                                return (
                                    Object.values(value1).map((value2, index2) => {
                                        total_counter=total_counter+1;
                                        return (
                                            <div key={"div-" + index2} className="card-panel grey lighten-5 z-depth-1">
                                                <div className="row valign-wrapper">

                                                    <div className="col s3">

                                                        {
                                                            value2.parchee_type == 'New' ?
                                                                <div className="ribbonTxt">New</div>
                                                                :
                                                                ''

                                                        }
                                                        <img src="images/img3.png" alt="" className="circle responsive-img img-50" />

                                                    </div>

                                                    <div className="col s9">

                                                        <span className="black-text">
                                                            <p><b>S.no:</b>&nbsp;{total_counter}</p>
                                                            {/* <p><b>Appointment date:</b>&nbsp;{value2.booking_date}</p> */}
                                                            <p><b>Slot:</b>&nbsp;{value2.slot}</p>
                                                            <p><b>Status:</b>&nbsp;<span className={value2.status == "Approved" ? 'green-text' : 'red-text'}>{value2.status}</span></p>
                                                            <p><b>Name:</b>&nbsp;{value2.patient_history.patient_details.name}</p>
                                                            <p><b>Age:</b>&nbsp;{value2.patient_history.patient_details.age}&nbsp;years</p>
                                                            <p><b>Problem:</b>&nbsp;{value2.patient_history.patient_details.problem}</p>
                                                            <p><b>Address:</b>&nbsp;{value2.patient_history.patient_details.address}&nbsp;</p>
                                                            {
                                                                typeof value2.patient_history.priscription_image != null && value2.patient_history.priscription_image != ""
                                                                    ? <p><b>Priscription Image:</b><br /> <img src={"https://oparchee.com/files/" + value2.patient_history.priscription_image} alt="" className="circle responsive-img  center-align" /></p>
                                                                    :
                                                                    ""
                                                            }
                                                            {/* <p><b>Slot:</b>&nbsp;{value2.slot}</p>
                                                            <p><b>Name:</b>&nbsp;{value2.patient_history.patient_details.name}</p>
                                                            <p><b>Age:</b>&nbsp;{value2.patient_history.patient_details.age}&nbsp;years</p> */}
                                                        </span>
                                                        <hr />
                                                    </div>
                                                    {/* <div className="col s3">
                                                            <p className="detailBtn">

                                                                <Link to="/show-patient-details" state={{ data: value2 }}> Details </Link>
                                                            </p>

                                                        </div> */}

                                                </div>

                                            </div>
                                        )
                                    })
                                )
                            })
                        )

                    })
                )
            }



        } else {
            return (

                <div className="col s12 center-align mt-20">
                    <img src="images/not-found.gif" alt="" className="circle responsive-img" />

                </div>



            )
        }

    }

    /**
     * 
     */
    _handleIconClick = () => {
        //alert('ggggg')
        $("#datepicker").datepicker("open");
    }

    /**
     * 
     */
    _handleDateAdd = async () => {
        var date = new Date($("#datepicker").val());
        var newdate = new Date(date);
        newdate.setDate(newdate.getDate() + 1); // minus the date
        var nd = new Date(newdate);
        $("#datepicker").datepicker("setDate", nd);
        let res = [];
        res.data = this.state.user;
        //await this._getUpdatedBooking(res);
    }

    /**
     * 
     */
    _handleDateSubtract = async () => {
        //alert($("#datepicker").val());
        var date = new Date($("#datepicker").val());
        var newdate = new Date(date);
        newdate.setDate(newdate.getDate() - 1); // minus the date
        var nd = new Date(newdate);
        $("#datepicker").datepicker("setDate", nd);
        let res = [];
        res.data = this.state.user;
        //await this._getUpdatedBooking(res);
    }

    /**
     * 
     */
    _handleChange = async (date) => {
        //console.log(date)
        $('#datepicker').val(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
        await _clearAllInterval();
        let res = [];
        res.data = this.state.user;
        await this._getUpdatedBooking(res);
    }

    /**
     * 
     * @returns 
     */
    _showDate = () => {
        let get_date = new Date($('#datepicker').val());
        let show_date = get_date.getDate() + '-' + (get_date.getMonth() + 1) + '-' + get_date.getFullYear();

        return (
            <h6 id="show_date" className="center-align">Date: {show_date}</h6>
        )
    }

    /**
     * 
     * @returns 
     */
    _getDoctor = () => {
        if (this.state.doctor_list.length > 0) {
            return (
                <React.Fragment>
                    <option value={0}  >All Doctor</option>
                    {
                        Object.values(this.state.doctor_list).map((value, index) => {
                            return (
                                <option key={'option-doctor-' + index} value={value.id}>{value.first_name + ' ' + value.last_name}</option>
                            )
                        })
                    }
                </React.Fragment>
            )
        } else {
            return (
                <option value={0} >All Doctor</option>
            );
        }


    }

    /**
     * 
     * @param {*} e 
     */
    _doctorSelectEvent = async (e) => {

        setTimeout(async () => {
            e.blur();
        }, 100);
        let type = "Doctor";

        if (e.target.value == 0) {
            type = "Hospital";
            await this.setState({
                selected_user: this.state.user.id,
                selected_type: type,
                list: []
            });
            await _clearAllInterval();
            let res = [];
            res.data = this.state.user;

            if (startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() == $('#datepicker').val()) {
                await this._getAutoRefresh(res);
            } else {
                await this._getUpdatedBooking(res);
            }

        } else {
            await this.setState({
                selected_user: e.target.value,
                selected_type: type,
                list: []
            });
            //alert(type)
            await _clearAllInterval();
            let res = [];
            res.data = this.state.user;

            await this._getUpdatedBooking(res);
        }

        // }, 10);
    }


    render() {

        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <HospitalHeader />

                <main role="main">

                    <section id="dashboard-page" className="oparcheecommonCode">

                        <div className="dashboardContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">

                                        <div className="strip">

                                            <div className="row pt-20">

                                                <div className="col s3 left-align">

                                                    <i className="material-icons pointer-p" onClick={() => this._handleDateSubtract()}>chevron_left</i>

                                                </div>

                                                <div className="col s6 center-align">

                                                    <div className="bookingTxt"><h3>
                                                        <i className="material-icons pointer-p" onClick={() => { this._handleIconClick() }}> calendar_today</i>
                                                        <span>Booking History</span></h3>
                                                    </div>
                                                    <input onChange={() => { this._handleChange() }} id="datepicker" style={{ "visibility": "hidden", "height": "0px" }} />
                                                </div>

                                                <div className="col s3 right-align">

                                                    <i className="material-icons pointer-p" onClick={() => this._handleDateAdd()}>chevron_right</i>

                                                </div>

                                            </div>
                                            <form>
                                                <div className="col s12">
                                                    <div className="input-field">
                                                        <select name="select_doctor" onChange={(e) => { this._doctorSelectEvent(e) }} defaultValue={0} >

                                                            {
                                                                this._getDoctor()
                                                            }

                                                        </select>
                                                        {/* <label>Doctor's List</label> */}
                                                        {/* <div className="error">{this.state.error_message.state}</div> */}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12">
                                        {this._showDate()}

                                        {this._getBookingList()}
                                    </div>
                                </div>


                                {/* <div className="col s12">

                                        <img className="responsive-img" src="images/banner2.jpg" alt="" />

                                        <a href="" className="upgradebtn"><span>Upgrade to Oparchee Plus</span> <i className="material-icons">arrow_forward</i></a>

                                        <div className="locationName center-align">

                                            <img className="responsive-img" src="images/stethoscope.png" alt="" /> <strong>Doctors in Mumbai</strong>

                                        </div>

                                    </div> */}





                            </div>

                        </div>

                    </section>

                </main>
                <HospitalMenu />
                {/* {
                    this.state.visibility == 'show'
                        ?
                        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                        :
                        ''
                } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(HospitalHistory);
