import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import PatientHeader from './includes/PatientHeader';
import PatientMenu from './includes/PatientMenu';
import { _getLoginStatus, _showOverlay, _hideOverlay, _getLocalLanguage, FILE_URL } from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
import { _getDoctorDetails } from './api/Patient';
import Toast from './includes/Toast';
import PatientPageHeader from './includes/PatientPageHeader';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
const language = _getLocalLanguage();
let $ = window.$;
class PatientDoctorProfile extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            data: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi
        }
        //console.log(this.props.location.state);
    }

    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        $('.tabs').tabs();
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Patient') {
                await this._getDoctorDetails();
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }


    /**
     * 
     */
    _getDoctorDetails = async () => {

        if (Object.keys(this.state.user).length > 0) {
            //_showOverlay();
            await this.setState({
                loader: "show"
            })
            let res1 = await _getDoctorDetails(this.props.location.state.doctor_id);
            if (res1.data.response == "success") {

                this.setState({
                    data: res1.data.result
                });
                await this.setState({
                    loader: "hide"
                })
            } else {
                this.setState({
                    data: [],
                    message: res1.data.error,
                    type: 'error',
                    visibility: 'show'
                });
                await this.setState({
                    loader: "hide"
                })
            }
        }
        return;
    }

    /**
     * 
     * @returns 
     */
    _getDoctorDetailCardHtml = () => {
        //alert(Object.keys(this.state.data).length)
        if (Object.keys(this.state.data).length > 0) {
            return (
                <div className="card">
                    {
                        typeof this.state.data.image != "undefined" && this.state.data.image != null
                            ?
                            <img className="responsive-img doctorImg" src={FILE_URL + this.state.data.image} alt="" />
                            :
                            <img className="responsive-img doctorImg" src="images/img3.png" alt="" />
                    }


                    <div className="doctorName center-align">Dr. {this.state.data.first_name + " " + this.state.data.last_name}</div>

                    <h5 className="grayTxt center-align">{this.props.location.state.medical_department}</h5>

                    <div className="row">

                        <div className="col s6">

                            <p className="grayTxt locationTxt"><a href=""><i className="material-icons">place</i> {this.state.data.doctor_detail.address}, {this.state.data.doctor_detail.city}, {this.state.data.doctor_detail.state}</a></p>

                        </div>

                        <div className="col s6 right-align">

                            <p className="grayTxt hourTxt"><a href=""><i className="material-icons">watch_later</i> <strong className="greenTxt">Open Between :</strong> {this.state.data.doctor_detail.timing}</a></p>

                        </div>

                    </div>

                </div>
            )
        }
    }

    /**
     * 
     */
    _initiatetabs = () => {
        $('.tabs').tabs();
    }

    /**
     * 
     */
    _getDoctorDetailsProfileCard = () => {
        if (Object.keys(this.state.data).length > 0) {
            return (
                <React.Fragment>
                    <div className="row">
                        <h6 className="">Dr. {this.state.data.first_name + " " + this.state.data.last_name}</h6>

                        

                    </div>

                    <div className="row">
                    <p className="grayTxt">Specialization / Degree : {this.state.data.doctor_detail.specialization_text}</p>
                    <p className="grayTxt">Education / College : {this.state.data.doctor_detail.college}</p>
                    <p className="grayTxt">Experience : {this.state.data.doctor_detail.experience}</p>
                    <p className="grayTxt">Reviews : 
                    <img src="images/star.png" alt="" className="square responsive-img h-40" />
                    <img src="images/star.png" alt="" className="square responsive-img h-40" />
                    <img src="images/star.png" alt="" className="square responsive-img h-40" />
                    <img src="images/star.png" alt="" className="square responsive-img h-40" />
                    <img src="images/star.png" alt="" className="square responsive-img h-40" />
                    </p>

                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <img src="images/not-found.gif" alt="" className="circle responsive-img" />
            )
        }
    }

    render() {

        return (
            <React.Fragment>
                {/* <PatientHeader /> */}
                <Loader visibility={this.state.loader} />
                <PatientPageHeader />
                <main role="main">

                    <section className="profileBox negative-margin-bottom">

                        <div className="container">

                            <div className="row">

                                <div className="col s12">

                                    {this._getDoctorDetailCardHtml()}

                                </div>

                            </div>

                        </div>

                    </section>

                    <section id="profile-page" className="oparcheecommonCode">

                        <div className="profileContent">

                            <div className="container-fluid">

                                <div className="row">

                                    <div className="col s12 no-padding">

                                        <ul className="tabs center-align">

                                            <li className="tab col s6"><a className="active" href="#test1">{this.state.text_language[0].info_text}</a></li>

                                            <li className="tab col s6"><a className="active" href="#test2">{this.state.text_language[0].distance_text}</a></li>

                                        </ul>



                                    </div>

                                </div>



                                <div className="row mt-20">

                                    <div className="col s12">

                                        <div id="test1" className="col s12">



                                            <div className="white-bg center-align">
                                                {
                                                    this._getDoctorDetailsProfileCard()
                                                }
                                            </div>

                                        </div>

                                        <div id="test2" className="col s12">

                                            <div className="mapDiv">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1779.9423757988857!2d81.04907690793907!3d26.843617688971484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be24b2dd84811%3A0x920dd5d729322fe1!2sDeoria%2C%20Uttar%20Pradesh%20226010!5e0!3m2!1sen!2sin!4v1672936451537!5m2!1sen!2sin" width="100%" height="350" style={{ "border": "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                {/* <iframe src={'https://www.google.com/maps/search/?api=1&query='+ encodeURIComponent( this.props.location.state.address ) +   '&key=AIzaSyD7-bvtBa-9YNg-DfZWJsBKPBaUJVnArXs'} width="100%" height="350" style={{ "border": "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col s12">

                                        <Link to="/patient-details" state={{ "doctor_id": this.props.location.state.doctor_id }} className="book-appointmentbtn">{this.state.text_language[0].book_text} {this.state.text_language[0].appointment_text}</Link>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                {this._initiatetabs()}
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );

    }
}

export default withNavigateHook(PatientDoctorProfile);
