/* eslint-disable */
import React, { Component } from 'react'
// import { ToastContainer, toast } from 'react-toastify';
// import Notifications, { notify } from 'react-notify-toast';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
const customId = "custom-id-yes";
let $ = window.$;
 
export default class Toast extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message,
            type: this.props.type,
            visibility: this.props.visibility
        }
        //this.show = notify.createShowQueue();

    }
 
    /**
     * function to show the toast based on the visibility props
     */
    _showToast = () => {
        
        //alert(this.props.visibility+'===='+this.props.type+'===='+this.props.message)
           if (this.props.visibility == 'show') {
                toast(this.props.message,
                    {   duration: 2500,
                        id: customId,
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            }
     }

  
    /**
     * 
     * @returns 
     */
    render() {

        return (
            <React.Fragment>
                
                <div className="main">
                 
                    <Toaster
                        position="bottom-center"
                        reverseOrder={false}
                    />
                    {/* {  toast.remove()} */}
                    {this._showToast()}
                </div>
            </React.Fragment>

        )
    }
}
