import React, { Component } from 'react';
import { _showOverlay, _hideOverlay, _validateRegistration,time_out, _getLocalLanguage } from './includes/Constants';
import {_registerUser} from './api/Common';
import {
    Link,useNavigate 
} from "react-router-dom";
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import Copyright from './includes/Copyright';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
const language= _getLocalLanguage();
 
class PatientRegistration extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            type: '',
            visibility: 'hide',
            error_message: {
                full_name: '',
                phone_number:'',
                password:'',
                confirm_password:''
          },
            registration_error: '',
            registration_success: '',
            text_language:language.lang == 'en'?lang_en:lang_hi
         }
         
    }

    /**
     * 
     * @param {*} value 
     */
     async componentDidMount() {
    //     document.getElementById('email').addEventListener("blur", async function(e) {
    //         let  {error_message} =registration.state;
         
    //         let res= await _checkFields('email',e.target.value);
    //         if(res.data.response == 'success'){
    //           error_message.email=res.data.error;
    //           registration.setState({
    //               error_message:error_message,
    //               okay:false
    //           });
    //         }
    //   });
     }

     /**
      * 
      * @param {*} e 
      */
     async _handleRegistration (e){
        e.preventDefault();
        const data = new FormData(e.target);
        //console.log(data);
        
       // if (this.state.captcha != null && this.state.captcha != '' && this.state.terms != false) {
             let res=await _validateRegistration(data);
             if(res['status']){
      
                this.setState({
                    error_message:res.error
                });
                let reg=this._registerUser(data);
             }else{
                this.setState({
                    error_message:res.error
                });
             }
            
             
        // }else{
        //     let res=await _validateRegistration(data);
        //      this.setState({
        //         error_message:res.error
        //     });
            
        // }
       
    }

    /**
     * 
     * @param {*} data 
     */
    _registerUser =async(data)=>{
        _showOverlay();
        
        let full_name =data.get('full_name');
        let phone_number =data.get('phone_number');
        let password =data.get('password');
        
        let res =await _registerUser(full_name,phone_number,password);
        if(res.data.response == 'success'){
            let data =res.data.result;
            this.setState({
                  registration_success:res.data.message,
                  registration_error:'',
                  message:res.data.message,
                  type:'success',
                  visibility:'show'
               });
               setTimeout(() => {
                 return (
                    this.props.navigation("/home")
                 )
               }, time_out);
              // document.getElementById('bb-login').scrollIntoView(); 
        }else{
           this.setState({
            registration_error:res.data.error,
            message:res.data.error,
            type:'error',
            visibility:'show'
           });
          // document.getElementById('bb-login').scrollIntoView(); 
        }
        _hideOverlay();
    }

    /**
     * 
     * @returns 
     */
    render() {



        return (
            <React.Fragment>
                <main role="main">
                    <section id="registration-page" className="oparcheecommonCode">
                        <div className="registrationContent">
                            <div className="container">
                                <div className="row">
                                    <div className="col s12">
                                        <div className="white-bg commonBox" id="bb-login">
                                        <div className="row">
                                                <div className="col 6">
                                                    <Link to="/" title="Back"> <i className="material-icons back-icon">arrow_back</i></Link>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s12 center-align">
                                                    <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" />
                                                    <h1>{this.state.text_language[0].registration_text}</h1>
                                                </div>
                                            </div>
                                            <div className="row" >
                                            {/* {typeof this.state.registration_error != 'undefined' && this.state.registration_error !='' ?<div id="login-error" className="login-error mb-4 text-center">{'Warning: '+this.state.registration_error}</div>:''}
                                            {typeof this.state.registration_success != 'undefined' && this.state.registration_success !='' ?<div id="reg-success" className="bg-success mb-4 text-center">{'Success: '+this.state.registration_success}</div>:''} */}
                                     
                                                <form  method="post" onSubmit={(e) => this._handleRegistration(e)}>
                                                    <div className="col s12 center-align">
                                                        <div className="input-field">
                                                            <input  type="text" id="p_full_name" name="full_name" className="validate" />
                                                            <label htmlFor="p_full_name">{this.state.text_language[0].full_name_text}</label>
                                                            <div className="error">{this.state.error_message.full_name}</div>
                                                        </div>
                                                        <div className="input-field">
                                                            <input  type="text" id="p_phone_number" name="phone_number" className="validate" />
                                                            <label htmlFor="p_phone_number">{this.state.text_language[0].mobile_text} {this.state.text_language[0].number_text}</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>
                                                        </div>
                                                        <div className="input-field">
                                                            <input id="password" type="password" name="password"  className="validate" />
                                                            <label htmlFor="password">{this.state.text_language[0].password_text}</label>
                                                            <div className="error">{this.state.error_message.password}</div>
                                                        </div>
                                                        <div className="input-field">
                                                            <input  id="confirm_password" type="password" name="confirm_password"  className="validate" />
                                                            <label htmlFor="confirm_password">{this.state.text_language[0].confirm_text} {this.state.text_language[0].password_text}</label>
                                                            <div className="error">{this.state.error_message.confirm_password}</div>
                                                        </div>
                                                        {/* <Link className="btn btn-primary continuebtn" to="/patient-login">Register</Link> */}
                                                        <button type="submit" className="btn btn-primary continuebtn"> {this.state.text_language[0].registration_text}</button>
                                                        <p className="blackTxt center-align">{this.state.text_language[0].already_have_account_text} &nbsp;&nbsp;<Link style={{color:"red"}} to="/">{this.state.text_language[0].login_text} {this.state.text_language[0].here_text}</Link></p>
                                                        {/* <a href="http://oparchee.desicoding.com/" className="btn btn-primary continuebtn">Register</a> */}
                                                    </div>
                                                </form>
                                            </div>

                                            <Copyright />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientRegistration);
