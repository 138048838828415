import React, { Component } from 'react'
import Loader from './includes/Loader';
import PatientMenu from './includes/PatientMenu';
import PatientPageHeader from './includes/PatientPageHeader';
import withNavigateHook from './includes/withNavigateHook'
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import { FILE_URL, _getLocalLanguage, _getLocationCookie, _getLoginStatus } from './includes/Constants';
import { _getMedicalDeliveryHistory } from './api/Patient';
import Toast from './includes/Toast';

const language = _getLocalLanguage();

let $ = window.$;

class PreviousMedicalDelivery extends Component {
  /**
   * 
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      list: [],
      message: '',
      type: '',
      visibility: 'hide',
      loader: "hide",
      text_language: language.lang == 'en' ? lang_en : lang_hi
    }
    //console.log(document);
  }

  /**
 * 
 * @returns 
 */
  componentDidMount = async () => {

    let res = await _getLoginStatus();
    let app = this;

    if (res['status']) {
      await this.setState({
        user: res.data
      });
      if (res['data']['user_type'] == 'Patient') {
        await this._getPatientHistory(res);
      } else {
        this.props.navigation('/home');
      }

    } else {
      this.props.navigation('/home');
    }

  }

  /**
    * 
    */
  _getPatientHistory = async (res) => {

    if (typeof res.data.id != "undefined" && res.data.id != null) {
      //_showOverlay();
      await this.setState({
        loader: "show",
        message: '',
        type: 'success',
        visibility: 'hide',
      })
      let date = $("#datepicker").val();
      let res1 = await _getMedicalDeliveryHistory(res.data.id);
      if (res1.data.response == "success") {
        //console.log(res1.data.result)
        this.setState({
          list: res1.data.result,
          loader: "hide",
        });
        // _hideOverlay();
      } else {
        this.setState({
          list: [],
          message: res1.data.error,
          type: 'error',
          visibility: 'show',
          loader: "hide"
        });
        // _hideOverlay();
      }
    }
    return;
  }


  /**
   * 
   */
  _getBookingList = () => {

    if (Object.keys(this.state.list).length > 0) {
      return (
        Object.values(this.state.list).map((value, index) => {

          return (

            <div key={"div-" + index} className="card-panel grey lighten-5 z-depth-1">
              <div className="row valign-wrapper">

                {/* <div className="col s3">
                         <img src="images/img3.png" alt="" className="circle responsive-img img-50" />
                    </div> */}

                <div className="col s12">

                  <span className="black-text">
                    <p><b>{this.state.text_language[0].delivery_text} {this.state.text_language[0].date_text}:</b>&nbsp;{value.delivery_date}</p>
                    <p><b>{this.state.text_language[0].delivery_type_text}:</b>&nbsp;{value.delivery_type}</p>

                    <p><b>{this.state.text_language[0].name_text}:</b>&nbsp;{value.name}</p>
                    <p><b>{this.state.text_language[0].phone_text} {this.state.text_language[0].number_text}:</b>&nbsp;{value.phone_number}</p>
                    <p><b>{this.state.text_language[0].city_text}:</b>&nbsp;{value.city}</p>
                    <p><b>{this.state.text_language[0].address_text}:</b>&nbsp;{value.address}</p>
                    <p><b>{this.state.text_language[0].status_text}:</b>&nbsp;<span className={value.status == 'Pending' ? 'red-tex' : 'green-text'}>{value.status}</span></p>
                    <p><b>{this.state.text_language[0].amount_text}:</b>&nbsp;{value.amount}</p>
                    <p><b>{this.state.text_language[0].payment_text} {this.state.text_language[0].status_text}:</b>&nbsp;{value.payment_status}</p>
                    <p><b>{this.state.text_language[0].delivery_text} {this.state.text_language[0].charges_text}:</b>&nbsp;{value.delivery_charges}</p>
                    <p><b>{this.state.text_language[0].doctor_prescription_image_text}:</b></p>
                    <p>
                      {
                        value.image != '' ?
                          <img src={FILE_URL + value.image} alt={this.state.text_language[0].doctor_prescription_image_text} className="responsive-img" />
                          :
                          this.state.text_language[0].file_not_found_text
                      }

                    </p>
                  </span>

                </div>


              </div>

            </div>
          )



        })
      )
    } else {
      return (

        <div className="col s12 center-align mt-20">
          <img src="images/not-found.gif" alt="" className="circle responsive-img" />

        </div>



      )
    }

  }

  /**
    * 
    * @returns 
    */
  render() {

    return (
      <React.Fragment>
        {/* <PatientHeader /> */}
        <Loader visibility={this.state.loader} />
        <PatientPageHeader />
        <main role="main">

          <section id="dashboard-page" className="oparcheecommonCode">
            <div className="dashboardContent">
              <div className="container">

                <div className="row">
                  <div className="col s12">
                    <div className="strip">
                      <h1 className='center-align'>{this.state.text_language[0].medical_delivery_history_text}</h1>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col s12">
                    {this._getBookingList()}
                  </div>

                </div>

              </div>

            </div>

          </section>

        </main>
        <PatientMenu />

        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
      </React.Fragment>
    );
  }
}

export default withNavigateHook(PreviousMedicalDelivery);
