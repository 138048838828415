import React, { Component } from 'react'
import { _getLoginStatus } from './includes/Constants';
import DoctorsHeader from './includes/DoctorsHeader';
import DoctorsMenu from './includes/DoctorsMenu';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';

class DoctorsAccountSetting extends Component {
    /**
     * 
     * @param {*} props 
     */
        constructor(props) {
            super(props);
            this.state = {
                user: {},
                list: [],
                message: '',
                type: '',
                visibility: 'hide',
                error_message: {
                    first_name: '',
                    last_name: '',
                    phone_number: ''
                },
                loader:"hide"
            }
        }
        /**
         * 
         * @returns 
         */
        componentDidMount = async () => {
            let res = await _getLoginStatus();
            //console.log(res);
            if (res['status']) {
                await this.setState({
                    user: res.data
                });
                if (res['data']['user_type'] == 'Doctor') {
                   console.log(res.data);
                } else {
                    this.props.navigation('/home');
                }
    
            } else {
                this.props.navigation('/home');
            }
     
        }

  render() {
    return (
       <React.Fragment>
                 <Loader visibility={this.state.loader}/>
                 <DoctorsHeader />
                
                <main role="main">

                    <section id="profile-editing-option-page" className="oparcheecommonCode">

                        <div className="profile-editing-optionContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    {/* <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" /> */}

                                                    <h1>Account Settings</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                
                                            <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data">

                                                    <div className="col s12">

                                                        <div className="input-field">

                                                            <input placeholder="" type="text"  defaultValue={this.state.user.first_name} name="first_name"  id="first_name" className="validate" />

                                                            <label className="active" htmlFor="first_name">First Name</label>
                                                            <div className="error">{this.state.error_message.first_name}</div>

                                                        </div>
                                                        
                                                        <div className="input-field">

                                                            <input placeholder="" type="text"     defaultValue={this.state.user.last_name} name="last_name"   id="last_name" className="validate" />

                                                            <label className="active" htmlFor="last_name">Last Name</label>
                                                            <div className="error">{this.state.error_message.last_name}</div>
                                                        </div>

                                                        <div className="input-field">

                                                            <input placeholder="" type="text"   defaultValue={this.state.user.phone_number} name="phone_number"   id="phone_number" className="validate" />

                                                            <label className="active" htmlFor="phone_number">Phone</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>

                                                        </div>

                                                        <div className="input-field">

                                                            <input placeholder="" type="email"  defaultValue={this.state.user.email} id="email" name="email" className="validate" />

                                                            <label className="active" htmlFor="email">Email</label>
                                                            {/* <div className="error">{this.state.error_message.email}</div> */}
                                                        </div>
                                                       

                                                        <h6 className="grayTxt">Profile Picture</h6>

                                                        <div className="input-field">

                                                            <div className="file-field input-field">

                                                                <div className="btn">

                                                                    <span><i className="material-icons">image</i></span>

                                                                    <input type="file" name="image" accept="image/*" />

                                                                </div>

                                                                <div className="file-path-wrapper">

                                                                    <input className="file-path validate" type="text" placeholder="Upload profile picture" />

                                                                </div>

                                                            </div>

                                                        </div>

                                                        <button type="submit" className="btn btn-primary continuebtn">Submit</button>

                                                    </div>

                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <DoctorsMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
    )
  }
}

export default withNavigateHook(DoctorsAccountSetting)
