import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";

import withNavigateHook from './includes/withNavigateHook';
import { _getLocalLanguage, _getLocationCookie, _getLoginStatus, _setLocationCookie, _showOverlay } from './includes/Constants';
import PatientPageHeader from './includes/PatientPageHeader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import { _getCities } from './api/Common';
import PatientMenu from './includes/PatientMenu';
const language = _getLocalLanguage();


class PatientSelection extends Component {

    constructor(props) {

        super(props);
        // state declaration
        this.state = {
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            user: {},
            location_list: {},
            selected_location: _getLocationCookie()
        }

    }

    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {

        let res = await _getLoginStatus();

        if (res['status']) {
            let location = await _getCities();
            await this.setState({
                user: res.data,
                location_list: location.data.result
            });
            await this.setState({
                user: res.data
            });
        } else {
            this.props.navigation('/home');
        }
        // _showOverlay();
    }

    /**
   * 
   * @returns 
   */
    _getLocationList = () => {

        if (Object.keys(this.state.location_list).length > 0) {
            return (
                <React.Fragment>
                    {
                        Object.values(this.state.location_list).map((value, index) => {
                            return (
                                <li className={this.state.selected_location == value ? "active" : ""}><Link to="/" onClick={(e) => { this._handleLocationChange(e, value) }}>{value}</Link></li>
                            )
                        })
                    }
                </React.Fragment>
            )

        }

    }

    _handleLocationChange = async (e, location) => {
        e.preventDefault();
        await _setLocationCookie(location)
        await this.setState({
            selected_location: location
        });
    }

    render() {

        return (
            <React.Fragment>
                <PatientPageHeader />
                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                {/* <div className="row mt-20">

                                    <div className="col s12"> */}


                                <div className='white-bg center-align commonBoxInner m-center'>
                                    <div className="row pt-20">
                                        <div className="col s12">
                                            <div className="selectLocationDiv right-align">

                                                <a className='dropdown-trigger' href='#' data-target='dropdown2'><i className="material-icons">location_on</i> <strong>{this.state.selected_location}</strong> <i className="material-icons">arrow_drop_down</i></a>

                                                <ul id='dropdown2' className='dropdown-content'>
                                                    {this._getLocationList()}
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            <div className="card darken-3">
                                                <div className='row'>

                                                    <div className="col s12">
                                                        <img className="responsive-img w-per-80 p-20" src="images/medical-delivery-banner.png" alt="Medical Delivery Banner" />
                                                    </div>
                                                    <div className="col s12">
                                                        <div className="card-content black-text">
                                                            {/* <span className="card-title">Book Appointment with Wide Variety of Doctors</span> */}
                                                            <p><b>{this.state.text_language[0].dynamic_note_text} &#128515;</b></p>
                                                            <p className='pt-10'><b>  {this.state.text_language[0].extra_text} </b></p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            <div className="card blue-grey darken-1">
                                                <div className='row'>
                                                    <div className="col s12 white-text">

                                                        {
                                                            this.state.user.type == 'Single'
                                                                ?
                                                                <img className="responsive-img h-150" src="images/medical-team.png" alt="Medical Team" />
                                                                // <img className="responsive-img h-150" src="images/logo-icon.png" alt="Oparchee" />
                                                                
                                                                :
                                                                this.state.user.type == 'Agent'
                                                                    ?

                                                                    <React.Fragment>
                                                                        <img className="responsive-img h-150" src="images/special-tag.png" alt="Medical Team" />

                                                                        <p>{this.state.text_language[0].agent_offer_text}</p>
                                                                    </React.Fragment>
                                                                    :
                                                                    ''

                                                        }

                                                    </div>
                                                    <div className="col s12">
                                                        <div className="card-content white-text">
                                                            {/* <span className="card-title">Book Appointment with Wide Variety of Doctors</span> */}

                                                            <p className='pb-10'>{this.state.text_language[0].verified_oparchee_doctors}</p>
                                                            <div className="col s12 pb-10">

                                                                <Link className="waves-effect waves-light btn-large" style={{ "position": "unset" }} to="/patient-dashboard">
                                                                    {this.state.text_language[0].consult_text} {this.state.text_language[0].now_text}
                                                                </Link>

                                                            </div>
                                                            
                                                            {/* <div className="col s3"> <img className="responsive-img h-50" src="images/cardiology.png" alt="Cardiology" /></div>
                                                            <div className="col s3"><img className="responsive-img h-50" src="images/orthopedics.png" alt="Orthopedics" /></div>
                                                            <div className="col s3"><img className="responsive-img h-50" src="images/dentist.png" alt="Dentist" /></div>
                                                            <div className="col s3"><img className="responsive-img h-50" src="images/kidney.png" alt="Urology" /> </div> */}
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">

                                        <div className="col s12">
                                            <h1 className=''>
                                                {/* <span className="btn-floating grey"><i className="material-icons">star</i></span> */}
                                                <span> {this.state.text_language[0].featured_services_text}</span></h1>
                                        </div>

                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                {/* <Link className="" to="/patient-dashboard"> */}
                                                <Link className="" to="/other-doctor-booking">
                                                    <img className="responsive-img h-50" src="images/appointment.png" alt="Appointment Booking" /><br /> {this.state.text_language[0].book_text} {this.state.text_language[0].parchee_text}
                                                </Link>
                                            </button>

                                        </div>

                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                {/* <Link className="" to="/other-doctor-booking"> */}
                                                <Link className="" to="/government-hospitals">
                                                    <img className="responsive-img h-50 " src="images/hospital.png" alt="Government Hospital" /><br />{this.state.text_language[0].government_text} {this.state.text_language[0].hospitals_text}
                                                    {/* <img className="responsive-img h-50" src="images/medical-appointment.png" alt="Other Appointment Booking" /><br />{this.state.text_language[0].other_text} {this.state.text_language[0].parchee_text} */}
                                                </Link>
                                            </button>

                                        </div>


                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link to="/patient-emergency">
                                                    <img className="responsive-img h-50" src="images/emergency.png" alt="Emergency" /><br />{this.state.text_language[0].emergency_text}
                                                </Link>
                                            </button>

                                        </div>

                                    </div>
                                    <div className="row">
                                        {/* </div>
                                        </div>
                                        <div className="row mb-10 ">
                                            <div className='white-bg center-align commonBoxInner m-center'> */}

                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link to="/medical-lab">
                                                    <img className="responsive-img h-50" src="images/medical-lab.png" alt="Labs" /><br />{this.state.text_language[0].book_text} {this.state.text_language[0].labs_text}
                                                </Link>
                                            </button>

                                        </div>

                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link to="/medical-delivery">
                                                    <img className="responsive-img h-50" src="images/medical-delivery.png" alt="Medical Delivery" /><br />{this.state.text_language[0].medical_text} {this.state.text_language[0].delivery_text}
                                                </Link>
                                            </button>

                                        </div>

                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link to="/ambulance-service">
                                                    <img className="responsive-img h-50" src="images/ambulance.png" alt="Labs" /><br />{this.state.text_language[0].ambulance_text} {this.state.text_language[0].service_text}
                                                </Link>
                                            </button>

                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="col s12">
                                            <h1 className=''> <span> {this.state.text_language[0].consult_specialist_doctors_text}</span></h1>
                                            <small>{this.state.text_language[0].click_on_icon_text}</small>
                                        </div>

                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link className="" to="/specialist-doctor-list" state={{ 'doctor_id': 10 }}>
                                                    <img className="responsive-img h-50" src="images/general-physician.png" alt="General Physician" /><br />{this.state.text_language[0].general_physician_text}
                                                </Link>
                                            </button>

                                        </div>

                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link className="" to="/specialist-doctor-list" state={{ 'doctor_id': 25 }}>
                                                    <img className="responsive-img h-50" src="images/orthopedist.png" alt="Bone Specialist" /><br />{this.state.text_language[0].bone_text} {this.state.text_language[0].specialist_text}
                                                </Link>
                                            </button>

                                        </div>


                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link to="/specialist-doctor-list" state={{ 'doctor_id': 11 }}>
                                                    <img className="responsive-img h-50" src="images/dentist.png" alt="Dentist" /><br />{this.state.text_language[0].dentist_text}
                                                </Link>
                                            </button>

                                        </div>

                                    </div>
                                    <div className="row">


                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link to="/specialist-doctor-list" state={{ 'doctor_id': 12 }}>
                                                    <img className="responsive-img h-50" src="images/stomach.png" alt="Stomach" /><br />{this.state.text_language[0].gastro_text} {this.state.text_language[0].specialist_text}
                                                </Link>
                                            </button>

                                        </div>

                                        <div className="col s4 hoverable">

                                            <button className="btn commonBtn">
                                                <Link to="/specialist-doctor-list" state={{ 'doctor_id': 9 }}>
                                                    <img className="responsive-img h-50" src="images/mental-health.png" alt="Heart & Neuro" /><br />{this.state.text_language[0]['heart_&_neuro_text']}
                                                </Link>
                                            </button>

                                        </div>

                                        <div className="col s4 hoverable" >

                                            <button className="btn commonBtn">
                                                <Link to="/specialist-doctor-list" state={{ 'doctor_id': 23 }}>
                                                    <img className="responsive-img h-50" src="images/female-doctor.png" alt="Female Specialist" /><br />{this.state.text_language[0].female_text} {this.state.text_language[0].specialist_text}
                                                </Link>
                                            </button>

                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            <div className="card blue-grey darken-1">
                                                <div className='row'>
                                                    <div className="col s12">

                                                    </div>
                                                    <div className="col s12">
                                                        <div className="card-content white-text">
                                                            {/* <span className="card-title">Book Appointment with Wide Variety of Doctors</span> */}
                                                            <h1>{this.state.text_language[0].have_health_issues_text}</h1>
                                                            {/* <h6>Consult top doctors</h6> */}
                                                            <div className="row">
                                                                <div className="col s4"> <img className="responsive-img h-50" src="images/shoulder-pain.png" alt="Shoulder Pain" /> <p>{this.state.text_language[0].shoulder_text} {this.state.text_language[0].pain_text}</p></div>
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/stomach-pain.png" alt="Stomach Pain" /><p>{this.state.text_language[0].stomach_text} {this.state.text_language[0].pain_text}</p></div>
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/headache.png" alt="Headache" /><p>{this.state.text_language[0].headche_text}</p></div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/back-pain.png" alt="Back Pain" /><p>{this.state.text_language[0].back_text} {this.state.text_language[0].pain_text}</p> </div>
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/cough.png" alt="Cough" /><p>{this.state.text_language[0].cough_text}</p> </div>
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/fever.png" alt="Fever" /><p>{this.state.text_language[0].fever_text}</p></div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col s4"> <img className="responsive-img h-50" src="images/acne.png" alt="Acne" /><p>{this.state.text_language[0].acne_text}</p></div>
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/hypertension.png" alt="Hypertension" /><p>{this.state.text_language[0].blood_pressure_text} </p></div>
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/diabetes.png" alt="diabetes" /><p>{this.state.text_language[0].diabetes_text}</p> </div>
                                                            </div>
                                                            <div className="row mt-20">
                                                                <div className="col s12">

                                                                    <Link className="waves-effect waves-light btn-small" style={{ "position": "unset" }} to="/patient-dashboard">
                                                                        {this.state.text_language[0].consult_text} {this.state.text_language[0].now_text}
                                                                    </Link>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            {/* <div className='row'>
                                                <div className="col s12"> */}

                                            {/* <span className="card-title">Book Appointment with Wide Variety of Doctors</span> */}
                                            <h1>{this.state.text_language[0].community_message_text}</h1>
                                            {/* <h6>Consult top doctors</h6> */}
                                            <div className="row">
                                                <div className="col s4"> <img className="responsive-img h-50" src="images/hospital.png" alt="Hospitals" /> <h6>{this.state.text_language[0].hospitals_text} (15+)</h6></div>
                                                <div className="col s4"><img className="responsive-img h-50" src="images/doctor-team.png" alt="Doctors" /><h6>{this.state.text_language[0].doctors_text} (150+)</h6></div>
                                                <div className="col s4"><img className="responsive-img h-50" src="images/users.png" alt="Headache" /><h6>{this.state.text_language[0].users_text} (2500+)</h6></div>
                                            </div>
                                            {/* </div>
                                            </div> */}
                                        </div>

                                    </div>

                                    {/* <div className="row">
                                        <div className="col s12">
                                            <div className="card blue-grey darken-1">
                                                <div className='row'>
                                                    <div className="col s12">
                                                        <div className="card-content white-text">
                                                            <span className="card-title">Book Appointment with Wide Variety of Doctors</span>
                                                            <h1>We have a large community of doctors in Deoria.</h1>

                                                            <div className="row">
                                                                <div className="col s4"> <img className="responsive-img h-50" src="images/hospital.png" alt="Shoulder Pain" /> <h6>Hospitals (15+)</h6></div>
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/doctor-team.png" alt="Stomach Pain" /><h6>Doctors (50+)</h6></div>
                                                                <div className="col s4"><img className="responsive-img h-50" src="images/users.png" alt="Headache" /><h6>Users (1000+)</h6></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div div className="row pt-20" >
                                        <div className="col s12">
                                            <div className="card blue-grey darken-1">
                                                <div className='row'>
                                                    <div className="col s12">
                                                        <div className="card-content white-text">
                                                            <h1>{this.state.text_language[0].vision_motto_text}</h1>
                                                            <p className="justify-text">
                                                                {this.state.text_language[0].our_mission_motto_text}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div >

                                {/* </div>

                                </div> */}

                            </div >

                        </div >

                    </section >

                </main >

                <PatientMenu />
            </React.Fragment >
        );
    }
}

export default withNavigateHook(PatientSelection);
