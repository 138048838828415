import React, { Component } from 'react';

import {
    Link, useLocation
} from "react-router-dom";
import DoctorsMenu from './includes/DoctorsMenu';
import DoctorsHeader from './includes/DoctorsHeader';
import { _getLoginStatus, _showOverlay, _hideOverlay, FILE_URL } from './includes/Constants';
import { _getAllBooking } from './api/Doctor';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
class ShowPatientDetails extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'hide'

        }
       //console.log(this.props.location.state.data)
    }

    _getPatientDetails = () => {
        return (
            <div  className="card-panel grey lighten-5 z-depth-1">
                <div className="row valign-wrapper">
                       <div className="col s12">
                            <h3><span  className="center-align">Patient Details</span></h3>
                        <span className="black-text">
                            <p><b>Appointment date:</b>&nbsp;{this.props.location.state.data.booking_date}</p>
                            <p><b>Slot:</b>&nbsp;{this.props.location.state.data.slot}</p>
                            <p><b>Status:</b>&nbsp;<span className={this.props.location.state.data.status== "Approved"?'green-text':'red-text'}>{this.props.location.state.data.status}</span></p>
                            <p><b>Name:</b>&nbsp;{this.props.location.state.data.patient_history.patient_details.name}</p>
                            <p><b>Age:</b>&nbsp;{this.props.location.state.data.patient_history.patient_details.age}&nbsp;years</p>
                            <p><b>Problem:</b>&nbsp;{this.props.location.state.data.patient_history.patient_details.problem}</p>
                            <p><b>Phone Number:</b>&nbsp;{this.props.location.state.data.patient_history.patient_details.phone_number}</p>
                            <p><b>Address:</b>&nbsp;{this.props.location.state.data.patient_history.patient_details.address}&nbsp;</p>
                             {
                               typeof this.props.location.state.data.patient_history.priscription_image!=null && this.props.location.state.data.patient_history.priscription_image !="" 
                               ? <p><b>Priscription Image:</b><br/> <img src={FILE_URL+this.props.location.state.data.patient_history.priscription_image} alt="" className="circle responsive-img  center-align" /></p>
                               :
                               ""
                             }
                           
                        </span>

                    </div>
                    

                </div>

            </div>
        )
    }


    render() {

        return (
            <React.Fragment>
                <DoctorsHeader />
                <div className="row">
                    <div className="col s12">
                        {this._getPatientDetails()}
                    </div>
                </div>
                <DoctorsMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(ShowPatientDetails);
