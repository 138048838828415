import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import { _getLoginStatus } from './includes/Constants';

import HospitalHeader from './includes/HospitalHeader';
import HospitalMenu from './includes/HospitalMenu';
class HospitalEmergency extends Component {
    /**
   * 
   * @param {*} props 
   */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide"
        }
    }

    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {

        let res = await _getLoginStatus();
        if (res['status']) {
            await this.setState({
                user: res.data
            });

            if (res['data']['user_type'] == 'Doctor') {

            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    render() {

        return (
            <React.Fragment>
                <HospitalHeader />
                <main role="main">

                    <section id="equipment-page" className="oparcheecommonCode">

                        <div className="equipmentContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">


                                        <div className='card blue-grey darken-1 text-center'>
                                            <div className="row">
                                                <div className="col s12">

                                                    <h2 className="white-text center-align">Service Not Available Yet.</h2>

                                                </div>

                                            </div>


                                        </div>


                                    </div>



                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <HospitalMenu />
            </React.Fragment>
        );
    }
}

export default HospitalEmergency;
