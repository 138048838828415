import React, { Component } from 'react'
import { _checkNull, _getLoginStatus, _validateNumber } from './includes/Constants';
import DoctorsHeader from './includes/DoctorsHeader';
import DoctorsMenu from './includes/DoctorsMenu';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook'
import { states } from './includes/States';
import { _editDoctorClinicDetails } from './api/Doctor';
let $ = window.$;
class DoctorsClinicSetting extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'show',
            error_message: {
                clinic_name: '',
                city: '',
                pincode: '',
                state: '',
                address: '',
                additional_phone: '',
                specialization_text: ''
            },
            loader: "hide",
        }
    }
    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {

        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Doctor') {
                //console.log(res.data);
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
        $('select').formSelect();
    }

    /**
     * 
     */
    _getState = () => {
        return (
            <React.Fragment>
                <option disabled value >Select State</option>
                {
                    Object.values(states).map((value, index) => {
                        return (
                            Object.values(value).map((value1, index1) => {
                                return (
                                    <option key={'option-state-' + index1} value={value1}>{value1}</option>
                                )
                            })
                        )

                    })
                }
            </React.Fragment>
        )

    }

    /**
     * 
     * @param {*} e 
     */
    async _handleManualBooking(e) {
        e.preventDefault();
        //_showOverlay();
        await this.setState({
            loader: "show"
        })
        const data = new FormData(e.target);

        let clinic_name = data.get('clinic_name');
        let city = data.get('city');
        let state = data.get('state');
        let pincode = data.get('pincode');
        let address = data.get('address');
        let additional_phone = data.get('additional_phone');
        let specialization_text = data.get('specialization_text');
        let res = await this._validateBooking(data);
        if (res['status']) {

            this.setState({
                error_message: res.error
            });

            let reg = await _editDoctorClinicDetails(clinic_name, city, state, pincode, address,additional_phone,specialization_text, this.state.user.id);
            if (reg.data.response == 'success') {
       
                if (window.localStorage) {
                    await window.localStorage.setItem('user', JSON.stringify(reg.data.result));
                }
                this.setState({
                    message: reg.data.message,
                    type: 'success',
                    visibility: 'show',
                    loader: "hide"
                });

             } else {
                this.setState({
                    message: reg.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });
             }
        } else {
            this.setState({
                error_message: res.error,
                loader: "hide"
            });
            //_hideOverlay();
        }

    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    _validateBooking = (data) => {
        let clinic_name = data.get('clinic_name');
        let city = data.get('city');
        let state = data.get('state');
        let pincode = data.get('pincode');
        let address = data.get('address');
        let additional_phone = data.get('additional_phone');
        let specialization_text = data.get('specialization_text');
        
        let response = [];
        response['status'] = false;
        response['error'] = [];

        if (typeof clinic_name != "undefined" && clinic_name != undefined && clinic_name.trim() != '' &&
            typeof city != "undefined" && city != undefined && city.trim() != '' &&
            typeof state != "undefined" && state != undefined && state.trim() != '' &&
            typeof pincode != "undefined" && pincode != undefined && pincode.trim() != '' &&
            typeof address != "undefined" && address != undefined && address.trim() != '' &&
            typeof additional_phone != "undefined" && additional_phone != undefined && additional_phone.trim() != '' &&
            typeof specialization_text != "undefined" && specialization_text != undefined && specialization_text.trim() != ''
        ) {
            if (!_validateNumber(pincode)) {
                response['error']['pincode'] = "Please provide a valid pincode in numbers.";
            } else {
                if (pincode.toString().length == 6) {
                    response['status'] = true;
                } else {
                    response['error']['pincode'] = "Please provide a valid pincode";
                }
                //response['status'] = true;
            }
        } else {
            
            response['error']['clinic_name'] = _checkNull(clinic_name);
            response['error']['city'] = _checkNull(city);
            response['error']['state'] = _checkNull(state);
            response['error']['pincode'] = _checkNull(pincode);
            response['error']['address'] = _checkNull(address);
            response['error']['additional_phone'] = _checkNull(additional_phone);
            response['error']['specialization_text'] = _checkNull(specialization_text);

            if (response['error']['pincode'].trim() == '' || response['error']['pincode'] == null) {

                if (_validateNumber(pincode)) {
                    if (pincode.toString().length == 6) {
                        //response['status'] = true;
                    } else {
                        response['error']['pincode'] = "Please provide a valid pincode.";
                    }
                } else {
                    response['error']['pincode'] = "Please provide a valid pincode in numbers.";

                }
            }
            
         }

        return response;
    }



    render() {
        return (
            <React.Fragment>
                {/* {console.log(this.state.user.doctor_detail)} */}
                <Loader visibility={this.state.loader} />
                <DoctorsHeader />

                <main role="main">

                    <section id="profile-editing-option-page" className="oparcheecommonCode">

                        <div className="profile-editing-optionContent">

                            <div className="container">

                                <div className="row">
                                    {
                                        this.state.user != {} && typeof this.state.user.doctor_detail != "undefined"
                                            ?
                                            <div className="col s12 l4 offset-l4">

                                                <div className="white-bg commonBoxInner">

                                                    <div className="row">

                                                        <div className="col s12 center-align">

                                                            {/* <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" /> */}

                                                            <h1>Hospital Settings</h1>

                                                        </div>

                                                    </div>

                                                    <div className="row">


                                                        <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data">

                                                            <div className="col s12">

                                                                <div className="input-field">
                                                                    <input placeholder="" type="text" defaultValue={this.state.user.doctor_detail.clinic_name} name="clinic_name" id="clinic_name" className="validate" />
                                                                    <label className="active" htmlFor="clinic_name">Hospital Name</label>
                                                                    <div className="error">{this.state.error_message.clinic_name}</div>
                                                                </div>



                                                                <div className="input-field">
                                                                    <input placeholder="" type="text" defaultValue={this.state.user.doctor_detail.address} name="address" id="address" className="validate" />

                                                                    <label className="active" htmlFor="last_name">Address</label>
                                                                    <div className="error">{this.state.error_message.address}</div>
                                                                </div>

                                                                <div className="input-field">

                                                                    <input placeholder="" type="text" defaultValue={this.state.user.doctor_detail.city} name="city" id="city" className="validate" />
                                                                    <label className="active" htmlFor="city">City</label>
                                                                    <div className="error">{this.state.error_message.city}</div>

                                                                </div>
                                                                <div className="input-field">

                                                                    <input placeholder="" type="text" defaultValue={this.state.user.doctor_detail.pincode} id="pincode" name="pincode" className="validate" />
                                                                    <label className="active" htmlFor="pincode">Pincode</label>
                                                                    <div className="error">{this.state.error_message.pincode}</div>
                                                                </div>
                                                                <div className="input-field">
                                                                    <select name="state" defaultValue={this.state.user.doctor_detail.state != "undefined" ? this.state.user.doctor_detail.state : 'Select State'} >

                                                                        {
                                                                            this._getState()
                                                                        }

                                                                    </select>
                                                                    <label>State</label>
                                                                    <div className="error">{this.state.error_message.state}</div>
                                                                </div>
                                                                <div className="input-field">
                                                                    <input placeholder="" type="text" defaultValue={this.state.user.doctor_detail.specialization_text} name="specialization_text" id="additional_phone" className="validate" />
                                                                    <label className="active" htmlFor="specialization_text">Slogan Text</label>
                                                                    <div className="error">{this.state.error_message.specialization_text}</div>
                                                                </div>
                                                                <div className="input-field">
                                                                    <input placeholder="" type="text" defaultValue={this.state.user.doctor_detail.additional_phone} name="additional_phone" id="additional_phone" className="validate" />
                                                                    <label className="active" htmlFor="additional_phone">Hospital Contact No.</label>
                                                                    <div className="error">{this.state.error_message.additional_phone}</div>
                                                                </div>
                                                                <button type="submit" className="btn btn-primary continuebtn">Submit</button>

                                                            </div>

                                                        </form>

                                                    </div>

                                                </div>

                                            </div>
                                            :
                                            <div className="col s12 center-align">
                                                <img src="images/not-found.gif" alt="" className="circle responsive-img" />
                                            </div>
                                    }


                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <DoctorsMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        )
    }
}

export default withNavigateHook(DoctorsClinicSetting)
