import React, { Component } from 'react'
import {
    Link
} from "react-router-dom";
import PatientHeader from './includes/PatientHeader';
import PatientMenu from './includes/PatientMenu';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import { _addBooking, _addOtherBooking, _getUnregisterDoctorList, _getUpdatedCharges } from './api/Patient';
import { _getLoginStatus, _checkNull, _validateNumber, time_out, _showOverlay, _hideOverlay, _getLocalLanguage, _getLocationCookie, _checkSpecialChar } from './includes/Constants';
import PatientPageHeader from './includes/PatientPageHeader';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import BookingConfirmModal from './includes/BookingConfirmModal';
const language = _getLocalLanguage();
let $ = window.$;

let date = new Date();
//const startDate = new Date(date.setDate(date.getDate() + 1));
const startDate = date;
class UnregisteredDoctorBooking extends Component {
    /**
    * 
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            message: '',
            type: '',
            visibility: 'hide', //'hide'
            doctor_list: props.location.state,
            charges: {},
            error_message: {
                name: '',
                age: '',
                problem: '',
                address: '',
                phone_number: '',
                doctor_name: "",
                patient_address: ""
            },
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            form_data: {},
            send_date: '',
            selected_location: _getLocationCookie()
        }
        // console.log(props.location)
    }
    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {
        $('select').formSelect();

        let res = await _getLoginStatus();
        let set_start_date = await this._setStartDate(this.state.doctor_list.doctor_sitting, startDate);
        //console.log(res);
        if (res['status']) {
            let updated_charges = await _getUpdatedCharges(res.data.id, res.data.type);
            await this.setState({
                user: res.data,
                charges: updated_charges.data.result
            });


            await $("#booking_date").datepicker({
                format: 'dd-mm-yyyy',
                autoClose: true,
                minDate: set_start_date,
                disableDayFn: (current_date) => this._handleDaysDisable(current_date, this.state.doctor_list.doctor_sitting)
                // disableDayFn:(current_date) =>this._handleDaysDisable(current_date)
            }).datepicker("setDate", startDate);
            $('#booking_date').val(startDate.getDate() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getFullYear());
            if (res['data']['user_type'] == 'Patient') {

            } else {
                this.props.navigation('/home');
            }
            //console.log(this.state.doctor_list)
        } else {
            this.props.navigation('/home');
        }
    }

    /**
     * 
     * @param {*} doctor_sitting 
     * @param {*} date 
     * @returns 
     */
    _setStartDate = (doctor_sitting, date) => {
        let new_start_date = date;
        let week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        if (typeof doctor_sitting != "undefined" && doctor_sitting != null) {
            let day = date.getDay();
            if (typeof doctor_sitting[week[day]] != 'undefined' && doctor_sitting[week[day]] != null) {
                if (doctor_sitting[week[day]] == 'True') {
                    new_start_date = startDate
                    //return false
                } else {

                    new_start_date = new Date(date.setDate(date.getDate() + 1));
                    this._setStartDate(doctor_sitting, new_start_date);
                    //return true;
                }
            }
        }
        return new_start_date;
    }





    /**
     * 
     * @param {*} e 
     */
    async _handleManualBooking(e) {
        e.preventDefault();
        //_showOverlay();
        await this.setState({
            loader: "show"
        })
        const data = new FormData(e.target);

        let name = data.get('name');
        let age = data.get('age');
        let problem = data.get('problem');
        let address = data.get('address');
        let booking_date = data.get('booking_date');;
        let priscription_image = data.get('priscription_image');
        let phone_number = data.get('phone_number');
        let doctor_name = data.get('doctor_name');
        let booking_amount = data.get('booking_amount');
        let patient_address = data.get('patient_address');
        let city = data.get('city');
        let res = await this._validateBooking(data);
        if (res['status']) {
            const [day, month, year] = booking_date.split('-');

            const send_date = [year, month, day].join('-');
            this.setState({
                error_message: res.error,
            });

            let reg = await _addOtherBooking(name, age, problem, address, priscription_image, this.state.user.id, phone_number, booking_date, doctor_name, booking_amount, patient_address, city);
            if (reg.data.response == 'success') {
                this.setState({
                    message: reg.data.message,
                    type: 'success',
                    visibility: 'show',
                    loader: "hide",
                    form_data: data,
                    send_date: send_date
                });
            } else {
                this.setState({
                    message: reg.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });

            }

        } else {
            this.setState({
                error_message: res.error,
                loader: "hide"
            });
            //_hideOverlay();
        }

    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    _validateBooking = (data) => {
        let name = data.get('name');
        let age = data.get('age');
        let problem = data.get('problem');
        let address = data.get('address');
        let phone_number = data.get('phone_number');
        let doctor_name = data.get('doctor_name');
        let patient_address = data.get('patient_address');

        let response = [];
        response['status'] = false;
        response['error'] = [];

        if (typeof name != "undefined" && name != undefined && name.trim() != '' &&
            typeof age != "undefined" && age != undefined && age.trim() != '' &&
            typeof problem != "undefined" && problem != undefined && problem.trim() != '' &&
            typeof address != "undefined" && address != null && address.trim() != '' &&
            typeof phone_number != "undefined" && phone_number != null && phone_number.trim() != '' &&
            typeof doctor_name != "undefined" && doctor_name != null && doctor_name.trim() != '' &&
            typeof patient_address != "undefined" && patient_address != null && patient_address.trim() != ''
        ) {
            
            response['error']['name'] = _checkSpecialChar(name);
            response['error']['problem'] = _checkSpecialChar(problem);
            response['error']['patient_address'] = _checkSpecialChar(patient_address);
            //response['error']['patient_address'] = _checkSpecialChar(patient_address);
            // response['error']['doctor_name'] = _checkSpecialChar(doctor_name);
            if (response['error']['name'].trim() == '' && response['error']['problem'].trim() == '' && response['error']['patient_address'].trim() == ''
             ) {
                if (!_validateNumber(age)) {
                    response['error']['age'] = "Please provide a valid age in numbers.";
                    if (!_validateNumber(phone_number)) {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    } else {
                        if (phone_number.toString().length == 10) {
                            // response['status'] = true;
                        } else {
                            response['error']['phone_number'] = "Please provide a valid phone number.";
                        }

                    }
                } else {
                    if (!_validateNumber(phone_number)) {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    } else {
                        if (phone_number.toString().length == 10) {
                            response['status'] = true;
                        } else {
                            response['error']['phone_number'] = "Please provide a valid phone number.";
                        }

                    }
                    //response['status'] = true;
                }

            }

        } else {

            response['error']['name'] = _checkNull(name);
            response['error']['age'] = _checkNull(age);
            response['error']['problem'] = _checkNull(problem);
            response['error']['address'] = _checkNull(address);
            response['error']['phone_number'] = _checkNull(phone_number);
            response['error']['doctor_name'] = _checkNull(doctor_name);
            response['error']['patient_address'] = _checkNull(patient_address);


            if (response['error']['age'].trim() == '' || response['error']['age'] == null) {

                if (_validateNumber(age)) {
                    //response['status'] = true;
                } else {
                    response['error']['age'] = "Please provide a valid age in numbers.";

                }
            }
            if (response['error']['phone_number'].trim() == '' || response['error']['phone_number'] == null) {

                if (_validateNumber(phone_number)) {
                    if (phone_number.toString().length == 10) {
                        //response['status'] = true;
                    } else {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    }
                } else {
                    response['error']['phone_number'] = "Please provide a valid phone number";

                }
            }

        }

        return response;
    }

    /**
     * 
     * @param {*} e 
     */
    _handleChange = (e) => {
        if (e.target.value == 'Other') {
            $('#name').val('');
        }
        if (e.target.value == 'Self') {
            $('#name').val(this.state.user.full_name);
        }
    }

    /**
     * 
     * @param {*} current_date 
     * @param {*} doctor_sitting 
     * @returns 
     */
    _handleDaysDisable = (current_date, doctor_sitting) => {
        let week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        if (typeof doctor_sitting != "undefined" && doctor_sitting != null) {
            let day = current_date.getDay();
            if (typeof doctor_sitting[week[day]] != 'undefined' && doctor_sitting[week[day]] != null) {
                if (doctor_sitting[week[day]] == 'True') {
                    return false
                } else {
                    return true;
                }
            }
        }
        // console.log(this.state.doctor_list)
    }


    render() {

        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <PatientPageHeader />
                {/* <PatientHeader /> */}
                <main role="main">

                    <section id="patient_details-page" className="oparcheecommonCode">

                        <div className="patient_detailsContent">

                            <div className="container">


                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg commonBoxInner">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    {/* <img className="responsive-img logoImg" src="images/logo.png" alt="Oparchee" /> */}

                                                    <h1>{this.state.text_language[0].patient_text} {this.state.text_language[0].details_text}</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data" autoComplete='off'>

                                                    <div className="col s12">
                                                        <div className="input-field">
                                                            <select name="patient_type" onChange={(e) => { this._handleChange(e) }}>
                                                                <option defaultValue="Self">{this.state.text_language[0].self_text}</option>
                                                                <option value="Other">{this.state.text_language[0].other_text}</option>
                                                            </select>
                                                            <label>{this.state.text_language[0].appointment_text} {this.state.text_language[0].whom_text} {this.state.text_language[0].for_text} </label>
                                                        </div>

                                                        <div className="input-field">

                                                            <input type="text" placeholder={this.state.user.full_name} id="name" defaultValue={this.state.user.full_name} name="name" className="validate" />

                                                            <label htmlFor="name" className="active">{this.state.text_language[0].name_text}</label>
                                                            <div className="error">{this.state.error_message.name}</div>

                                                        </div>
                                                        <div className="input-field">

                                                            <textarea id="textarea2" name="patient_address" className="materialize-textarea"></textarea>

                                                            <label htmlFor="textarea2">{this.state.text_language[0].patient_text} {this.state.text_language[0].address_text}</label>
                                                            <div className="error">{this.state.error_message.patient_address}</div>
                                                        </div>
                                                        <div className="input-field">

                                                            <input type="text" placeholder={this.state.user.phone_number} defaultValue={this.state.user.phone_number} id="phone_number" name="phone_number" className="validate" />

                                                            <label htmlFor="phone_number" className="active">{this.state.text_language[0].phone_text} {this.state.text_language[0].number_text}</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>

                                                        </div>



                                                        <div className="input-field">

                                                            <input name="age" type="text" placeholder={this.state.user.age} defaultValue={this.state.user.age} id="age" className="validate" />

                                                            <label htmlFor="age" className="active">{this.state.text_language[0].age_text}</label>
                                                            <div className="error">{this.state.error_message.age}</div>
                                                        </div>

                                                        <div className="input-field">

                                                            <input name="problem" type="text" id="problem" className="validate" />

                                                            <label htmlFor="problem" className="active">{this.state.text_language[0].problem_text}</label>
                                                            <div className="error">{this.state.error_message.problem}</div>
                                                        </div>
                                                        <div className="input-field">

                                                            {/* <input name="doctor_name" onChange={(e) => this._handleDoctorChange(e)} type="text" id="doctor_name" className="validate" /> */}
                                                            <input name="doctor_name" defaultValue={this.state.doctor_list.doctor_name} type="text" id="doctor_name" className="validate" />
                                                            <label htmlFor="doctor_name" className="active">{this.state.text_language[0].doctor_text} {this.state.text_language[0].name_text}</label>
                                                            <div className="error">{this.state.error_message.doctor_name}</div>
                                                        </div>
                                                        <div className="input-field">

                                                            <textarea id="textarea1" name="address" className="materialize-textarea">{this.state.doctor_list.address}</textarea>

                                                            <label htmlFor="textarea1" className="active">{this.state.text_language[0].doctor_text} {this.state.text_language[0].address_text}</label>
                                                            <div className="error">{this.state.error_message.address}</div>
                                                        </div>
                                                        <div className="input-field">
                                                            <input type="text" className="datepicker validate" id="booking_date" name="booking_date" />
                                                            {/* <input type="text" placeholder={this.state.user.phone_number} defaultValue={this.state.user.phone_number}  /> */}

                                                            <label htmlFor="booking_date" className="active">{this.state.text_language[0].booking_text} {this.state.text_language[0].date_text}</label>
                                                            <div className="error">{this.state.error_message.booking_date}</div>

                                                        </div>
                                                        <div className="input-field">

                                                            <input name="booking_amounts" defaultValue={this.state.doctor_list.registration_fees} disabled="true" type="number" id="booking_amounts" className="validate" />

                                                            <label htmlFor="booking_amounts" className="active">{this.state.text_language[0].doctor_text} {this.state.text_language[0].fees_text}</label>
                                                            {/* <div className="error">{this.state.error_message.doctor_name}</div> */}
                                                        </div>

                                                        <div className="input-field">
                                                            {
                                                                this.state.user.type == 'Agent'
                                                                    ?
                                                                    <input name="app_charge" defaultValue={this.state.charges.other_booking_price} disabled="true" type="number" id="app_charge" className="validate" />

                                                                    :
                                                                    <input name="app_charge" defaultValue={this.state.charges.other_booking_price} disabled="true" type="number" id="app_charge" className="validate" />

                                                            }

                                                            <label htmlFor="app_charges" className="active">{this.state.text_language[0].app_text} {this.state.text_language[0].charges_text} (&#8377;)</label>
                                                            {/* <div className="error">{this.state.error_message.doctor_name}</div> */}
                                                        </div>
                                                        <input name="booking_amount" defaultValue={this.state.doctor_list.registration_fees} type="hidden" id="booking_amount" className="validate" />
                                                        <input name="app_charges" defaultValue={this.state.charges.other_booking_price} type="hidden" id="app_charges" className="validate" />
                                                        <input name="city" defaultValue={this.state.selected_location} type="hidden" id="city" className="validate" />


                                                        <h6 className="grayTxt">{this.state.text_language[0].prescription_text} {this.state.text_language[0].image_text}</h6>

                                                        <div className="input-field">

                                                            <div className="file-field input-field">

                                                                <div className="btn">

                                                                    <span><i className="material-icons">image</i></span>

                                                                    <input type="file" name="priscription_image" accept="image/*" />

                                                                </div>

                                                                <div className="file-path-wrapper">

                                                                    <input className="file-path validate" type="text" placeholder={this.state.text_language[0].upload_multiple_text} />

                                                                </div>

                                                            </div>

                                                        </div>
                                                        {/* <Link to="/patient-book-appointment" className="btn btn-primary continuebtn">Submit</Link> */}
                                                        <button type="submit" className="btn btn-primary continuebtn">{this.state.text_language[0].submit_text}</button>

                                                    </div>

                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <PatientMenu />
                <BookingConfirmModal send_date={this.state.send_date} form_data={this.state.form_data} message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                {/* {
                    this.state.visibility == 'show'
                        ?
                        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                        :
                        ''
                } */}
                {/* <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} /> */}
            </React.Fragment>
        );
    }
}

export default withNavigateHook(UnregisteredDoctorBooking);


