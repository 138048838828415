import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import { _editDoctorProfile } from './api/Doctor';
import { _checkNull, _getLoginStatus, _validateNumber, _validateEmail, _getUserDoctor, time_out } from './includes/Constants';
import DoctorsHeader from './includes/DoctorsHeader';
import DoctorsMenu from './includes/DoctorsMenu';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
let $ = window.$;
let date = new Date();
//const startDate = new Date(date.setDate(date.getDate() + 1));
//alert(startDate.setDate(startDate.getDate() + 1))
const startDate = date;
class DoctorsEditProfile extends Component {
    /**
      * 
      * @param {*} props 
      */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'hide',
            user_doctor: {},
            error_message: {
                first_name: '',
                last_name: '',
                phone_number: '',
                specialization_text:'',
                passout_year:'',
                college:''
            },
            loader: "hide"
        }
    }
    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {
        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            await this.setState({
                user: res.data
            });
             
            if (res['data']['user_type'] == 'Doctor') {
                let res1 = await _getUserDoctor();
                if (res1['status']) {
                    await this.setState({
                        user_doctor: res1.data
                    });
                    //console.log(this.state.user_doctor)
                }
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    /**
 * 
 * @param {*} e 
 */
    async _handleManualBooking(e) {
        e.preventDefault();
        //_showOverlay();
        await this.setState({
            loader: "show"
        })
        const data = new FormData(e.target);

        let first_name = data.get('first_name');
        let last_name = data.get('last_name');
        let specialization_text = data.get('specialization_text');
        let passout_year = data.get('passout_year');
        let college = data.get('college');
        // let phone_number = data.get('phone_number');
        // let email = data.get('email');

        let image = data.get('image');

        let res = await this._validateBooking(data);
        if (res['status']) {

            this.setState({
                error_message: res.error
            });

            let reg = await _editDoctorProfile(first_name, last_name, '', '', image, this.state.user_doctor.id, 'Doctor',college,passout_year,specialization_text);
            if (reg.data.response == 'success') {
                if (window.localStorage) {
                    await window.localStorage.setItem('user_doctor', JSON.stringify(reg.data.result));
                    setTimeout(() => {
                        return (this.props.navigation("/doctors-account"))
                    }, time_out);
                    // await window.localStorage.setItem('user_token',JSON.stringify(res.data.token));
                    // window.location.href='/asset-pwa/engineer/dashboard';
                }
                this.setState({
                    message: reg.data.message,
                    type: 'success',
                    visibility: 'show',
                    loader: "hide"
                });

                //_hideOverlay();
            } else {
                this.setState({
                    message: reg.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });
                // _hideOverlay();
            }

        } else {
            this.setState({
                error_message: res.error,
                loader: "hide"
            });
            //_hideOverlay();
        }

    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    _validateBooking = (data) => {
        // alert('jj')
        let first_name = data.get('first_name');
        let last_name = data.get('last_name');
        let specialization_text = data.get('specialization_text');
        let passout_year = data.get('passout_year');
        let college = data.get('college');
        // let phone_number = data.get('phone_number');
        // let email = data.get('email');

        let response = [];
        response['status'] = false;
        response['error'] = [];

        if (typeof first_name != "undefined" && first_name != undefined && first_name.trim() != '' &&
            typeof last_name != "undefined" && last_name != undefined && last_name.trim() != ''  &&
            typeof specialization_text != "undefined" && specialization_text != undefined && specialization_text.trim() != '' &&
            typeof passout_year != "undefined" && passout_year != undefined && passout_year.trim() != '' &&
            typeof college != "undefined" && college != undefined && college.trim() != ''
             ) {
            response['status'] = true;

        } else {

            response['error']['first_name'] = _checkNull(first_name);
            response['error']['last_name'] = _checkNull(last_name);
            response['error']['specialization_text'] = _checkNull(specialization_text);
            response['error']['passout_year'] = _checkNull(passout_year);
            response['error']['college'] = _checkNull(college);
        
        }

        return response;
    }


    render() {
       if(Object.values(this.state.user_doctor).length >0){
        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <DoctorsHeader />
                <main role="main">

                    <section id="profile-editing-option-page" className="oparcheecommonCode">

                        <div className="profile-editing-optionContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg commonBoxInner">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    {/* <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" /> */}

                                                    <h1>Profile Editing Option</h1>

                                                </div>

                                            </div>

                                            <div className="row">


                                                <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data" autoComplete='off'>

                                                    <div className="col s12">

                                                        <div className="input-field">

                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.first_name} name="first_name" id="first_name" className="validate" />

                                                            <label className="active" htmlFor="first_name">First Name</label>
                                                            <div className="error">{this.state.error_message.first_name}</div>

                                                        </div>

                                                        <div className="input-field">

                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.last_name} name="last_name" id="last_name" className="validate" />

                                                            <label className="active" htmlFor="last_name">Last Name</label>
                                                            <div className="error">{this.state.error_message.last_name}</div>
                                                        </div>
                                                        <div className="input-field">

                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.college} name="college" id="college" className="validate" />

                                                            <label className="active" htmlFor="college">College</label>
                                                            <div className="error">{this.state.error_message.college}</div>
                                                        </div>
                                                        <div className="input-field">
                                                      
                                                            <input placeholder="" type="number" defaultValue={this.state.user_doctor.doctor_detail.passout_year} name="passout_year" id="passout_year" className="validate" />

                                                            <label className="active" htmlFor="passout_year">Passout Year</label>
                                                            <div className="error">{this.state.error_message.passout_year}</div>
                                                        </div>
                                                        <div className="input-field">

                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.specialization_text} name="specialization_text" id="specialization_text" className="validate" />

                                                            <label className="active" htmlFor="specialization_text">Degree / Specialization</label>
                                                            <div className="error">{this.state.error_message.specialization_text}</div>
                                                        </div>
                                                        {/* 
                                                        <div className="input-field">

                                                            <input placeholder="" type="text"   defaultValue={this.state.user_doctor.phone_number} name="phone_number"   id="phone_number" className="validate" />

                                                            <label className="active" htmlFor="phone_number">Phone</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>

                                                        </div> */}

                                                        {/* <div className="input-field">

                                                            <input placeholder="" type="email"  defaultValue={this.state.user_doctor.email} id="email" name="email" className="validate" />

                                                            <label className="active" htmlFor="email">Email</label>
                                                          <div className="error">{this.state.error_message.email}</div> 
                                                        </div> */}


                                                        <h6 className="grayTxt">Profile Picture</h6>

                                                        <div className="input-field">

                                                            <div className="file-field input-field">

                                                                <div className="btn">

                                                                    <span><i className="material-icons">image</i></span>

                                                                    <input type="file" name="image" accept="image/*" />

                                                                </div>

                                                                <div className="file-path-wrapper">

                                                                    <input className="file-path validate" type="text" placeholder="Upload profile picture" />

                                                                </div>

                                                            </div>

                                                        </div>

                                                        <button type="submit" className="btn btn-primary continuebtn">Submit</button>

                                                    </div>

                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <DoctorsMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
       }else{

       }
       
    }
}

export default withNavigateHook(DoctorsEditProfile);
