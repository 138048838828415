import React, { Component } from 'react'
import { _getLocalLanguage } from './Constants';
import { lang_en } from './language/en';
import { lang_hi } from './language/hi';
const language = _getLocalLanguage();
class Loader extends Component {

     /**
     * 
     * @param {*} props 
     */
      constructor(props) {
        super(props);
        this.state = {
            text_language: language.lang == 'en' ? lang_en : lang_hi
        }
       
       
    }

    render() {
        return (
            <div  className={this.props.visibility == "hide"?"loading hide-loader":"loading"}>
                <div className="container">
                    <div className="row">
                        <div className="col s12">
                            <div className="loadingBox">
                                <div className="row">
                                    {/* <div className="col s12"><h5>Heading</h5></div> */}
                                    <div className="col s3">
                                        <div className="preloader-wrapper big active">
                                            <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s9 center-align mt-20">
                                        <span >{this.state.text_language[0].loading_wait_text}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Loader
