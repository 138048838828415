import React, { Component } from 'react'
import { _addMedicalDelivery, _getUpdatedCharges } from './api/Patient';
import { _checkImageNull, _checkNull, _checkSpecialChar, _getLocalLanguage, _getLocationCookie, _getLoginStatus, _validateNumber } from './includes/Constants';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import Loader from './includes/Loader';
import MedicalConfirmModal from './includes/MedicalConfirmModal';
import PatientMenu from './includes/PatientMenu';
import PatientPageHeader from './includes/PatientPageHeader';
import withNavigateHook from './includes/withNavigateHook';
const language = _getLocalLanguage();
let $ = window.$;

let date = new Date();
//const startDate = new Date(date.setDate(date.getDate() + 1));
const startDate = date;

class MakeMedicalDelivery extends Component {

    /**
   * 
   * @param {*} props 
   */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            message: '',
            type: '',
            visibility: 'hide', //'hide'
            doctor_list: props.location.state,
            charges: {},
            error_message: {
                name: '',
                priscription_image: '',
                booking_date: '',
                address: '',
                phone_number: '',
            },
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            form_data: {},
            send_date: '',
            selected_location: _getLocationCookie(),
            form_values: {
                name: '',
                booking_date: '',
                address: '',
                phone_number: '',
                delivery_type: '',
                delivery_charges: ''
            }
        }
        // console.log(props.location)
    }


    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        $('select').formSelect();
        let res = await _getLoginStatus();
        if (res['status'] == true) {
            let updated_charges = await _getUpdatedCharges(res.data.id, res.data.type);
            //console.log(updated_charges)
            await this.setState({
                user: res.data,
                charges: updated_charges.data.result
            });
            await $("#booking_date").datepicker({
                format: 'dd-mm-yyyy',
                autoClose: true,
                minDate: startDate,
            }).datepicker("setDate", startDate);
            $('#booking_date').val(startDate.getDate() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getFullYear());

            if (res['data']['user_type'] == 'Patient') {

            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
    }

    /**
   * 
   * @param {*} e 
   */
    async _handleManualBooking(e) {
        e.preventDefault();
        //_showOverlay();
        await this.setState({
            loader: "show"
        })
        const data = new FormData(e.target);
        let delivery_type = data.get('delivery_type');
        let name = data.get('name');
        let booking_date = data.get('delivery_date');
        let priscription_image = data.get('priscription_image');
        let phone_number = data.get('phone_number');
        let delivery_charges = delivery_type == 'Normal' ? this.state.charges.normal_delivery : this.state.charges.urgent_delivery;
        let address = data.get('patient_address');
        let city = data.get('city');
        let res = await this._validateBooking(data);

        if (res['status']) {
            const [day, month, year] = booking_date.split('-');
            const send_date = [year, month, day].join('-');
            this.setState({
                error_message: res.error,
                form_values: {
                    name: name,
                    booking_date: booking_date,
                    address: address,
                    phone_number: phone_number,
                    delivery_type: delivery_type,
                    delivery_charges: delivery_charges
                }
            });

            let reg = await _addMedicalDelivery(name, address, priscription_image, this.state.user.id, phone_number, booking_date, city, delivery_charges, delivery_type);
            if (reg.data.response == 'success') {
                this.setState({
                    message: reg.data.message,
                    type: 'success',
                    visibility: 'show',
                    loader: "hide",
                    form_data: data,
                    send_date: send_date
                });
            } else {
                this.setState({
                    message: reg.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });

            }

        } else {
            this.setState({
                error_message: res.error,
                loader: "hide"
            });

        }

    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    _validateBooking = (data) => {
        let name = data.get('name');
        let booking_date = data.get('delivery_date');;
        let priscription_image = data.get('priscription_image');
        let phone_number = data.get('phone_number');
        let delivery_charges = data.get('app_charges');
        let address = data.get('patient_address');
        let city = data.get('city');
        let delivery_type = data.get('delivery_type');
        // console.log(delivery_type);

        let response = [];
        response['status'] = false;
        response['error'] = [];

        if (typeof name != "undefined" && name != undefined && name.trim() != '' &&
            typeof booking_date != "undefined" && booking_date != undefined && booking_date.trim() != '' &&
            typeof delivery_charges != "undefined" && delivery_charges != undefined && delivery_charges.trim() != '' &&
            typeof address != "undefined" && address != null && address.trim() != '' &&
            typeof phone_number != "undefined" && phone_number != null && phone_number.trim() != '' &&
            typeof priscription_image.name != "undefined" && priscription_image.name != null && priscription_image.name.trim().trim() != ''
        ) {


            if (!_validateNumber(phone_number)) {
                response['error']['phone_number'] = "Please provide a valid phone number.";
            } else {
                if (phone_number.toString().length == 10) {
                    response['error']['name'] = _checkSpecialChar(name);
                    if (response['error']['name'].trim() == '' || response['error']['name'] == null) {
                        response['error']['address'] = _checkSpecialChar(address);
                        if (response['error']['address'].trim() == '' || response['error']['address'] == null) {
                            response['status'] = true;
                        } else {

                        }
                    } else {
                        response['error']['address'] = _checkSpecialChar(address);
                        if (response['error']['address'].trim() == '' || response['error']['address'] == null) {
                            //response['status'] = true;
                        } else {

                        }
                    }

                } else {
                    response['error']['phone_number'] = "Please provide a valid phone number.";
                }

            }

        } else {

            response['error']['name'] = _checkNull(name);
            response['error']['booking_date'] = _checkNull(booking_date);
            response['error']['address'] = _checkNull(address);
            response['error']['phone_number'] = _checkNull(phone_number);
            response['error']['priscription_image'] = _checkImageNull(priscription_image);
            if (response['error']['phone_number'].trim() == '' || response['error']['phone_number'] == null) {

                if (_validateNumber(phone_number)) {
                    if (phone_number.toString().length == 10) {
                        //response['status'] = true;
                    } else {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    }
                } else {
                    response['error']['phone_number'] = "Please provide a valid phone number";

                }
            }
        }

        return response;
    }

    /**
    * 
    * @param {*} e 
    */
    _handleChange = (e) => {
        //console.log(this.state.charges);
        if (e.target.value == 'Normal') {
            $('#urgent-delivery-text').addClass('d-none');
            $('#app_charge').val(this.state.charges.normal_delivery);
            $('#app_charges').val(this.state.charges.normal_delivery);
        }
        if (e.target.value == 'Urgent') {
            $('#urgent-delivery-text').removeClass('d-none');
            $('#app_charge').val(this.state.charges.urgent_delivery);
            $('#app_charges').val(this.state.charges.urgent_delivery);
        }

    }

    /**
     * 
     * @param {*} e 
     */
    _handleChargesChange =(e) =>{
        $('#app_charges').val(e.target.value);
    }

    /**
     * 
     * @param {*} e 
     */
    _getUpdatedImage = (e) => {
        var file_id = e.target.attributes.id.value;
        $("#uploaded-image").attr("src", URL.createObjectURL(e.target.files[0]));
     }


    render() {

        return (
            <React.Fragment>

                <Loader visibility={this.state.loader} />
                <PatientPageHeader />
                {/* <PatientHeader /> */}
                <main role="main">

                    <section id="patient_details-page" className="oparcheecommonCode">

                        <div className="patient_detailsContent">

                            <div className="container">


                                <div className="row">

                                    <div className="col s12">

                                        <div className="white-bg commonBoxInner">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    <h1>{this.state.text_language[0].order_details_text}</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data" autoComplete='off'>

                                                    <div className="col s12">
                                                        <div className="input-field">
                                                            <select name="delivery_type" onChange={(e) => { this._handleChange(e) }}>
                                                                <option value="Normal" defaultValue="Normal">{this.state.text_language[0].normal_text}</option>
                                                                <option value="Urgent">{this.state.text_language[0].urgent_text}</option>
                                                            </select>
                                                            <label>{this.state.text_language[0].delivery_type_text}</label>
                                                        </div>

                                                        <div className="input-field">

                                                            <input type="text" placeholder={this.state.user.full_name} id="name" defaultValue={this.state.user.full_name} name="name" className="validate" />

                                                            <label htmlFor="name" className="active">{this.state.text_language[0].name_text}</label>
                                                            <div className="error">{this.state.error_message.name}</div>

                                                        </div>
                                                        <div className="input-field">

                                                            <textarea id="textarea2" name="patient_address" className="materialize-textarea"></textarea>

                                                            <label htmlFor="textarea2" className="active"> {this.state.text_language[0].address_text}</label>
                                                            <div className="error">{this.state.error_message.address}</div>
                                                        </div>
                                                        <div className="input-field">

                                                            <input type="text" placeholder={this.state.user.phone_number} defaultValue={this.state.user.phone_number} id="phone_number" name="phone_number" className="validate" />

                                                            <label htmlFor="phone_number" className="active">{this.state.text_language[0].phone_text} {this.state.text_language[0].number_text}</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>

                                                        </div>

                                                        <div className="input-field">
                                                            <input type="text" className="datepicker validate" id="booking_date" name="delivery_date" />
                                                            {/* <input type="text" placeholder={this.state.user.phone_number} defaultValue={this.state.user.phone_number}  /> */}

                                                            <label htmlFor="booking_date" className="active">{this.state.text_language[0].booking_text} {this.state.text_language[0].date_text}</label>
                                                            <div className="error">{this.state.error_message.booking_date}</div>

                                                        </div>
                                                        <div className="input-field">
                                                            {
                                                                this.state.user.type == 'Agent'
                                                                    ?
                                                                    <input name="app_charge" defaultValue={this.state.charges.normal_delivery}  onChange={(e)=>{this._handleChargesChange(e)}}  type="number" id="app_charge" className="validate" />

                                                                    :
                                                                    <input name="app_charge" defaultValue={this.state.charges.normal_delivery}  onChange={(e)=>{this._handleChargesChange(e)}}   type="number" id="app_charge" className="validate" />

                                                            }
                                                            <label htmlFor="app_charge" className="active">{this.state.text_language[0].app_text} {this.state.text_language[0].charges_text} (&#8377;)</label>
                                                            <small id="urgent-delivery-text" className='d-none'>{this.state.text_language[0].km_charges_text}</small>
                                                        </div>
                                                        <input name="app_charges" value={this.state.charges.normal_delivery} type="hidden" id="app_charges" className="validate" />
                                                        <input name="city" value={this.state.selected_location} type="hidden" id="city" className="validate" />


                                                        <h6 className="grayTxt">{this.state.text_language[0].prescription_text} {this.state.text_language[0].image_text}</h6>

                                                        <div className="input-field">

                                                            <div className="file-field input-field">

                                                                <div className="btn">

                                                                    <span><i className="material-icons">image</i></span>

                                                                    <input type="file" id="priscription_image" name="priscription_image" onChange={(e) => this._getUpdatedImage(e)} accept="image/*" />

                                                                </div>

                                                                <div className="file-path-wrapper">

                                                                    <input className="file-path validate" type="text" placeholder={this.state.text_language[0].upload_multiple_text} />

                                                                </div>

                                                            </div>
                                                            <div className="error">{this.state.error_message.priscription_image}</div>

                                                        </div>
                                                        <div className='center-align'>
                                                            <img src="images/file.png" alt="" className="responsive-img" id="uploaded-image" />
                                                        </div>


                                                        {/* <Link to="/patient-book-appointment" className="btn btn-primary continuebtn">Submit</Link> */}
                                                        <button type="submit" className="btn btn-primary continuebtn">{this.state.text_language[0].submit_text}</button>

                                                    </div>

                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <PatientMenu />

                <MedicalConfirmModal form_values={this.state.form_values} send_date={this.state.send_date} form_data={this.state.form_data} message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}
export default withNavigateHook(MakeMedicalDelivery);

