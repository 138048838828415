// import React from "react";
// import firebase from "firebase";
// import { getMessaging, getToken} from 'firebase/messaging';
// import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { _updateNotificationToken } from "./components/api/Common";
// import { onBackgroundMessage } from "firebase/messaging/sw";

const firebaseConfig = {
  apiKey: "AIzaSyB_noX6r_4uWyXtPpZNo1sFAMLPR-ovV94",
  authDomain: "oparchee.firebaseapp.com",
  projectId: "oparchee",
  storageBucket: "oparchee.appspot.com",
  messagingSenderId: "656518656969",
  appId: "1:656518656969:web:4479d73d1709168bcf6e18",
  measurementId: "G-B16X6XZRPB"
};

const fapp = initializeApp(firebaseConfig);
const messaging = getMessaging(fapp);
export default fapp;
export const _googlePushToken = getToken(messaging, {
  vapidKey:
    "BIv6dEk4fSH4MPdAqZF_V4UOi3GlkinFhXhSt_nfkjNBZTq5FPgnIMOAa7SdtHjY0qz7b7QLZaulH4XMdVEn4gE",
})
  .then(async (currentToken) => {
    if (currentToken) {
      if (window.localStorage) {
        await window.localStorage.setItem('user_token', JSON.stringify(currentToken));
      }
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });

onMessage(messaging, (payload) => {
 // console.log("Message received. ", payload);
  // let recieved_payload=JSON.stringify(JSON.parse(payload));
  let recieved_payload= JSON.parse(payload.data.message);
  //let recieved_payload=JSON.stringify(payload);
  console.log(recieved_payload);
  const notificationTitle = recieved_payload.notification.title;
  const notificationOptions = {
    body:recieved_payload.notification.body,
    icon:recieved_payload.android.notification.icon,
    color:recieved_payload.android.notification.color,
  };

  //self.registration.showNotification(notificationTitle, notificationOptions);
});

 

