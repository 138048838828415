import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import DoctorsMenu from './includes/DoctorsMenu';
import DoctorsHeader from './includes/DoctorsHeader';
import { _getLoginStatus, _showOverlay, _hideOverlay, _clearInterval, refresh_time, time_out, _getUserDoctor } from './includes/Constants';
import { _getPendingBooking, _confirmBooking, _updateBooking } from './api/Doctor';
import Toast from './includes/Toast';
import Loader from './includes/Loader';
import withNavigateHook from './includes/withNavigateHook';
let $ = window.$;
let date = new Date();
const startDate = date;
class BookingPending extends Component {
    /**
    * 
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            user_doctor: {},
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide"
        }
    }

    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {
        $('.modal').modal({
            dismissible: false
        });
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
                //alert(startDate)
                await $("#booking_date").datepicker({
                    format: 'dd-mm-yyyy',
                    autoClose: true,
                    minDate: startDate
                    // disableDayFn:(current_date) =>this._handleDaysDisable(current_date)
                }).datepicker("setDate", startDate);
                $('#booking_date').val(startDate.getDate() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getFullYear());
    
            if (res['data']['user_type'] == 'Doctor') {
                let res1 = await _getUserDoctor();
                //console.log(res1);
                if (res1['status']) {
                    await this.setState({
                        user_doctor: res1.data
                    });
                }
                await this._getUpdatedBooking();
                setTimeout(async () => {
                    await this._getAutoRefresh();
                }, 20000);
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    /**
     * 
     */
    _getUpdatedBooking = async () => {
        //alert('')
        //if(Object.keys(this.state.user).length >0){
        // _showOverlay();
        await this.setState({
            loader: "show"
        })
        let res1 = await _getPendingBooking(this.state.user_doctor.id);
        if (res1.data.response == "success") {

            this.setState({
                list: res1.data.result,
                message: res1.data.message,
                type: 'success',
                visibility: 'hide',
                loader: "hide"
            });
            //_hideOverlay();
        } else {
            this.setState({
                list: [],
                message: res1.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            //_hideOverlay();
        }
        //} 
        return;
    }

    /**
     * 
     */
    _getBookingList = () => {
        //console.log(this.state.list)
        {
            setTimeout(() => {
                this._initAccordion()
            }, 100);

        }

        if (Object.keys(this.state.list).length > 0) {
            return (
                <ul className="collapsible">
                    {
                        Object.values(this.state.list).map((value, index) => {

                            return (
                                <li className={index == 0 ? "active" : ""} key={"list-" + index}>
                                    <div className="collapsible-header">
                                        {/* <i className="material-icons">filter_drama</i> */}
                                        <h6>{Object.keys(this.state.list)[index]}</h6>
                                    </div>
                                    <React.Fragment key={"collapsible-body-" + index}>
                                        <div className="collapsible-body cp">
                                            {
                                                Object.values(value).map((value1, index1) => {

                                                    return (

                                                        <div key={"div-" + index1} className="row valign-wrapper">

                                                            <div className="col s12">

                                                                {/* <div className="ribbonTxt">New</div> */}
                                                                <span className="black-text">
                                                                    <p><b>Slot:</b>&nbsp;{value1.slot}</p>
                                                                    <p><b>Date:</b>&nbsp;{value1.booking_date}</p>
                                                                    <p><b>Name:</b>&nbsp;{value1.patient_history.patient_details.name}</p>
                                                                    <p><b>Age:</b>&nbsp;{value1.patient_history.patient_details.age}&nbsp;years</p>
                                                                    <p><b>Phone Number:</b>&nbsp;{value1.patient_history.patient_details.phone_number}</p>
                                                                    {
                                                                        typeof value1.patient_history.priscription_image != null && value1.patient_history.priscription_image != ""
                                                                            ? <p><b>Priscription Image:</b><br /> <img src={"https://oparchee.com/files/" + value1.patient_history.priscription_image} alt="" className="circle responsive-img  center-align" /></p>
                                                                            :
                                                                            ""
                                                                    }
                                                                    {
                                                                        typeof value1.status != null && value1.status == "Pending"
                                                                            ?
                                                                            <p className="detailBtn"><b>Status Action:</b>
                                                                                &nbsp; &nbsp; &nbsp;
                                                                                <Link to="/" className="green-b" onClick={(e) => { this._confirmBooking(e, value1.id, 'Approved') }}> Accept</Link>
                                                                                &nbsp; &nbsp; &nbsp;
                                                                                <Link to="/" className="red-b" onClick={(e) => { this._confirmBooking(e, value1.id, 'Cancelled') }}> Cancel</Link>
                                                                            </p>
                                                                            :
                                                                            <p className="detailBtn"><b>Status:</b><b className={value1.status== "Approved"?'green-text':'red-text'}> {value1.status}</b> </p>
                                                                    }

                                                                </span>
                                                            </div>

                                                            {/* <div className="col s2">
                                                                <p className="detailBtn">
                                                                    <Link to="/" onClick={(e) => { this._confirmBooking(e, value1.id, 'Approved') }}> Accept</Link>
                                                                </p>
                                                            </div> */}

                                                            <div className="col s3">
                                                                <p className="detailBtn">
                                                                    <Link  to="/" onClick={(e) => { this._reSchedule(e, value1) }}> Reschedule</Link>
                                                                </p>
                                                            </div>

                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>
                                    </React.Fragment>
                                </li>


                            )

                        })
                    }
                </ul>
            )
        } else {
            return (


                <div className="col s12 center-align mt-20">
                    <img src="images/not-found.gif" alt="" className="circle responsive-img" />

                </div>



            )
        }

    }

    /**
     * 
     * @param {*} e 
     * @param {*} data 
     */
    _reSchedule = (e,data) =>{
        e.preventDefault();
        console.log(data)
        let booking_date=new Date(data.booking_date);
        $('#booking_date').val(booking_date.getDate() + '-' + (booking_date.getMonth() + 1) + '-' + booking_date.getFullYear());
        $('#id').val(data.id);
        $('#re-schedule-modal').modal('open');
        // console.log(data);
    }

    /**
     * 
     * @param {*} e 
     */
    _handleReschedule=async(e)=>{
        e.preventDefault();
        await this.setState({
            loader: "show",
       })
        // _showOverlay();
        const data = new FormData(e.target);
        let booking_date = data.get('booking_date');
        let booking_id = data.get('id');
        let reg = await _updateBooking( booking_date, booking_id);
        if (reg.data.response == 'success') {
            this.setState({

                message: "Reschedule for the booking has been done.",
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });
            setTimeout(() => {
                $('#re-schedule-modal').modal('close');
            }, time_out);
            //_hideOverlay();
        } else {
            this.setState({
                message: reg.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            //_hideOverlay();
        }
    }


    /**
     * 
     */
    _reScheduleModal = () => {
         return (
            <div id="re-schedule-modal" className="modal  br-radius custom-modal ">
                <div className="modal-content">
                    <div className='m-center'>
                        <h4>Reschedule Booking</h4>
                    </div>

                </div>
                <form method="post" onSubmit={(e) => this._handleReschedule(e)} className="w-80-p mt-20">
                    <input name="id" id="id" type="hidden" />
                    <div className="input-field">
                        <input type="text" className="datepicker validate" id="booking_date" name="booking_date" />

                        <label htmlFor="booking_date" className="active">Booking Date</label>

                    </div>

                    <div className="modal-footer p-20 w-80-p">
                        <a href="#!" className="modal-close btn red-b float-l">Cancel</a>
                        {/* <Link to="/" className="modal-close btn green-b float-r">Update</Link> */}
                        <button type="submit" className="modal-close btn green-b float-r">Submit</button>

                    </div>
                </form>
            </div>
        )
    }

    /**
    * 
    */
    _initAccordion = () => {
        $('.collapsible').collapsible()
    }

    /**
     * 
     * @param {*} booking_id 
     * @param {*} status 
     */
    _confirmBooking = async (e, booking_id, status) => {
        e.preventDefault();
        // alert('ww');
        //_showOverlay();
        await this.setState({
            loader: "show"
        })
        let res1 = await _confirmBooking(booking_id, status);
        if (res1.data.response == "success") {
            //_hideOverlay();
            this.setState({
                message: res1.data.message,
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });

            setTimeout(async () => {
                this._getUpdatedBooking();
            }, time_out);

        } else {
            this.setState({
                message: res1.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            //_hideOverlay();
        }
    }

    /**
    * 
    * @returns 
    */
    _getAutoRefresh = async () => {

        this.set_interval = setInterval(async () => {
            //console.log(this.set_interval);
            await _clearInterval(this.set_interval);
            let res1 = await _getPendingBooking(this.state.user_doctor.id);
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result,
                    message: '',
                    type: 'success',
                    visibility: 'hide'
                });
                //console.log(this.state.list)
                //_hideOverlay();
            } else {
                this.setState({
                    list: [],
                    message: '',
                    type: 'error',
                    visibility: 'hide'
                });
            }
        }, refresh_time);

        return;
    }


    render() {
        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <DoctorsHeader />
                <main role="main">

                    <section id="doctor_dashboard-page" className="oparcheecommonCode">

                        <div className="doctor_dashboardContent">

                            <div className="container">

                                <div className="row">


                                    <div className="col s12">

                                        <div className="strip">

                                            <div className="row">

                                                <div className="col s3 left-align">

                                                    {/* <i className="material-icons">chevron_left</i> */}

                                                </div>

                                                <div className="col s6 center-align">

                                                    <div className="bookingTxt"><h3>
                                                        <i className="material-icons">calendar_today</i>
                                                        <span>Future Bookings</span></h3></div>

                                                </div>

                                                <div className="col s3 right-align">

                                                    {/* <i className="material-icons">chevron_right</i> */}

                                                </div>

                                            </div>

                                        </div>
                                        {
                                            this._getBookingList()
                                        }

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>
              
                </main>
                {
                    this._reScheduleModal()
                }
                <DoctorsMenu />
                {/* {
                    this.state.visibility == 'show'
                        ?
                        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                        :
                        ''
                } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment >
        );
    }
}

export default withNavigateHook(BookingPending);
