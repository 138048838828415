import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import { _getLocalLanguage } from './Constants';
import { lang_en } from './language/en';
import { lang_hi } from './language/hi';
const language = _getLocalLanguage();
class PatientMenu extends Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            text_language: language.lang == 'en' ? lang_en : lang_hi
        }
        this.action = window.location.pathname.split("/").pop();
    }

    render() {

        return (
            <React.Fragment>
                <footer className="footerSection">

                    <div className="container">

                        <div className="row">

                            <div className="col s12">

                                <ul>

                                    <li className={this.action == 'patient-selection' ? "active" : "" || this.action == 'patient-dashboard' ? "active" : ""
                                        || this.action == 'patient-doctor-profile' ? "active" : "" || this.action == 'patient-details' ? "active" : ""
                                            || this.action == 'patient-book-appointment' ? "active" : "" || this.action == 'other-doctor-booking' ? "active" : ""
                                                || this.action == 'specialist-doctor-list' ? "active" : "" || this.action == 'unregistered-doctor-booking' ? "active" : ""
                                                    || this.action == 'medical-delivery' ? "active" : "" || this.action == 'make-medical-delivery' ? "active" : ""
                                                        || this.action == 'previous-medical-delivery' ? "active" : ""
                                    }>
                                        <Link to="/patient-selection" title="Home">
                                            {/* <i className="material-icons">add_box</i><br />{this.state.text_language[0].oparchee_text} */}
                                            <i className="material-icons" title="Home">home</i><br />{this.state.text_language[0].oparchee_text}
                                        </Link>
                                    </li>

                                    <li className={this.action == 'patient-live' ? "active" : ""}>
                                        <Link to="/patient-live" title="Status">
                                            <i className="material-icons">live_tv</i><br />{this.state.text_language[0].status_text}
                                        </Link>
                                    </li>

                                    {/* <li className="middleIcon">
                                        <Link to="">
                                            <i className="material-icons">camera_alt</i>
                                        </Link>
                                    </li> */}

                                    <li className={this.action == 'patient-history' ? "active" : ""}>
                                        <Link to="/patient-history" title="History">
                                            <i className="material-icons">hourglass_full</i><br />{this.state.text_language[0].history_text}
                                        </Link>
                                    </li>

                                    <li className={this.action == 'patient-account' ? "active" : "" || this.action == 'patient-change-password' ? "active" : "" || this.action == 'patient-edit-profile' ? "active" : ""}>
                                        <Link to="/patient-account" title="Account">
                                            <i className="material-icons">person</i><br />{this.state.text_language[0].account_text}
                                        </Link>
                                    </li>

                                </ul>

                            </div>

                        </div>

                    </div>

                </footer>
            </React.Fragment>
        );
    }
}

export default PatientMenu;
