import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import DoctorsMenu from './includes/DoctorsMenu';
import DoctorsHeader from './includes/DoctorsHeader';
import { _getLoginStatus, _showOverlay, _hideOverlay, time_out, refresh_time, _clearAllInterval, _clearInterval, _getUserDoctor } from './includes/Constants';
import { _getActiveBooking, _confirmBooking } from './api/Doctor';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import Loader from './includes/Loader';
import { _getDashboardBookingCount } from './api/Common';
import HospitalMenu from './includes/HospitalMenu';
import HospitalHeader from './includes/HospitalHeader';
import { Chart } from 'react-google-charts';
let $ = window.$;
//let google = window.google;

class DoctorsDashboard extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            user_doctor: {},
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide"
        }
        this.set_interval = 0;
    }

    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {

        let res = await _getLoginStatus();
        if (res['status']) {
            await this.setState({
                user: res.data
            });

            if (res['data']['user_type'] == 'Doctor') {
                let res1 = await _getUserDoctor();
                //console.log(res1);
                if (res1['status']) {
                    await this.setState({
                        user_doctor: res1.data
                    });
                }
                await this._getDashboardBookingCount(res1);
                setTimeout(async () => {
                    await this._getAutoRefresh();
                }, 20000);
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }



    }

    /**
   * 
   */
    _getDashboardBookingCount = async (res) => {

        if (typeof res.data.id != "undefined") {
            //_showOverlay();
            this.setState({
                loader: "show"
            })
            let res1 = await _getDashboardBookingCount(res.data.id);
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result,
                    loader: "hide"
                });
                // _hideOverlay();
            } else {
                this.setState({
                    list: [],
                    message: res1.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });
                //_hideOverlay();
            }
        }
        return;
    }

    /**
         * 
         * @returns 
         */
    _getAutoRefresh = async () => {

        this.set_interval = setInterval(async () => {
            //console.log(this.set_interval);
            await _clearInterval(this.set_interval);
            let res1 = await _getDashboardBookingCount(this.state.user_doctor.id);
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result,
                    message: '',
                    type: 'success',
                    visibility: 'hide'
                });
                //console.log(this.state.list)
                //_hideOverlay();
            } else {
                this.setState({
                    list: [],
                    message: '',
                    type: 'error',
                    visibility: 'hide'
                });
            }
        }, refresh_time);

        return;
    }

    /**
     * 
     * @returns 
     */
    render() {

        return (
            <React.Fragment>

                <Loader visibility={this.state.loader} />
                <DoctorsHeader />
                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">


                                        <div className='white-bg center-align commonBoxInner m-center'>

                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="card blue-grey darken-1">
                                                        <div className="row  mb-10">
                                                            <div className="col s12">
                                                                <h2 className="white-text">Welcome Dr. {this.state.user_doctor.first_name + ' ' + this.state.user_doctor.last_name}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col s6'>
                                                    <div className="card blue-grey darken-1">
                                                        <div className="row  mb-10">
                                                            <div className="col s12">

                                                                <p className="white-text">Today's<span className="badge white-text" data-badge-caption="">{
                                                                    typeof this.state.list.active != "undefined"
                                                                        ?
                                                                        this.state.list.active
                                                                        :
                                                                        0
                                                                }</span></p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col s6'>
                                                    <div className="card blue-grey darken-1">
                                                        <div className="row  mb-10">
                                                            <div className="col s12">
                                                                <p className="white-text">Tomorrow's<span className="badge white-text" data-badge-caption="">
                                                                    {
                                                                        typeof this.state.list.future_booking != "undefined"
                                                                            ?
                                                                            this.state.list.future_booking
                                                                            :
                                                                            0
                                                                    }
                                                                </span></p>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>

                                            </div>
                                            <div className="row mb-10 ">

                                                <div className="col s4">

                                                    <button className="btn commonBtn">
                                                        <Link to="/today-booking">
                                                            {/* <div className="ribbonTxt-1">
                                                                {
                                                                    typeof this.state.list.active != "undefined"
                                                                        ?
                                                                        this.state.list.active
                                                                        :
                                                                        0
                                                                }
                                                            </div> */}
                                                            <img className="responsive-img w-50" src="images/today-appointment.png" alt="Today's Booking" /><br />Today's Parchee
                                                        </Link>
                                                    </button>

                                                </div>

                                                <div className="col s4">
                                                    <button className="btn commonBtn">
                                                        <Link to="/add-booking">
                                                            <img className="responsive-img w-50" src="images/medical-appointment.png" alt="Add Appointment" /><br />Add Today Parchee
                                                        </Link>
                                                    </button>

                                                </div>
                                                <div className="col s4">
                                                    <button className="btn commonBtn">
                                                        <Link to="/pending-booking">
                                                            <img className="responsive-img w-50" src="images/pending-appointment.png" alt="Pending Appointment" /><br />Tomorrow's Parchee
                                                        </Link>
                                                    </button>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mb-10 ">
                                            {/* <div id="myChart" style={{ "height":"500px"}}>
                                            </div> */}
                                        </div>

                                    </div>



                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                {/* <DoctorsMenu /> */}
                <DoctorsMenu />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(DoctorsDashboard);
