import React, { Component } from 'react';
import PWAPrompt from 'react-ios-pwa-prompt';
import axios from 'axios';
import Navigation from './components/Navigation';
import {
  Link, BrowserRouter, Router
} from "react-router-dom";
import { withSnackbar } from "notistack";
import { API_URL, _isInstalled, _showOverlay, _hideOverlay, _getLoginStatus, _isLocalhost, _languageSelection, _getLocalLanguage, _getLocationUsingNavigator } from './components/includes/Constants';
import { _checkAppVersion } from './components/api/Common';
import Loader from './components/includes/Loader';
import withNavigateHook from './components/includes/withNavigateHook';
//import { requestForToken } from './Firebase';
import { fapp, _googlePushToken } from './FirebasePush';
import NoConnectivity from './components/includes/NoConnectivity';
import UnderMaintenance from './components/includes/UnderMaintenance';
import { lang_en } from './components/includes/language/en';
import { lang_hi } from './components/includes/language/hi';


const language= _getLocalLanguage();
let $ = window.$;
class App extends Component {
  /**
   * 
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      newVersionAvailable: false,
      waitingWorker: {},
      connection: navigator.onLine ? "online" : "offline",
      loader: "hide",
      app_process: 'live',
      text_language:language.lang == 'en'?lang_en:lang_hi

    };

  }



  /**
   * 
   */
  componentDidMount = async () => {
     //_getLocationUsingNavigator();
    //alert(language.lang_select);
    if (this.state.connection == 'online') {
      await this.setState({
        loader: "show"
      });
      await this._checkVersion();
      $('#modal1').modal({
        dismissible: false
      });
      if(language.lang_select == false){
        $('#modal-2').modal({
          dismissible: false
        });
        $('#modal_language_trigger')[0].click();
      }
     
    }
 
  }

  /**
   * 
   */
  _checkVersion = async () => {
    let get_version = window.localStorage.getItem("version");
    let version = (typeof get_version != 'undefined' && get_version !== '' && get_version != null) ? get_version : 0;
    let res = await _checkAppVersion(version);
    if (res.data.response === 'success') {
      await this.setState({
        loader: "hide",
        app_process: res.data.result.app_process
      });

      let new_version = res.data.result.version;

      if (_isLocalhost) {
        new_version = res.data.result.test_version;
      }
      if (new_version == get_version) {
        //window.localStorage.setItem("version", new_version)
      } else {
        window.localStorage.setItem("version", new_version);
        if (_isInstalled()) {
          setTimeout(() => {
            $('#modal_test')[0].click();
          }, 100);
        } else {
          window.location.reload();
        }

      }
    } else {
      await this.setState({
        loader: "hide"
      });
    }

  }

  /**
   * 
   * @param {*} e 
   */
  _reloadApp = (e) => {
    e.preventDefault();
    window.location.reload();
  }

  /**
   * 
   * @param {*} e 
   */
  _continueApp = async(e) => {
    e.preventDefault();
    let lang=_getLocalLanguage();
    if(language.lang_select == false){
      await _languageSelection('hi');
    }
    $('#modal-2').modal('close');
    window.location.reload();

  }

  /**
   * 
   * @param {*} e 
   * @param {*} select 
   * @param {*} unselect 
   */
  _selectOption =async(e,select,unselect)=>{
    e.preventDefault();
    $('.'+select).addClass('selected-option');
    $('.'+unselect).removeClass('selected-option');
    await _languageSelection(select);
  }


  render() {
    if (this.state.connection == 'online') {

      if (this.state.app_process == 'live') {
        return (
          <React.Fragment>
            <Loader visibility={this.state.loader} />
            <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false} />
            <Navigation />
            <a className="waves-effect waves-light btn modal-trigger" id="modal_test" style={{ display: "none" }} href="#modal1">Modal</a>
            <div id="modal1" className="modal">
              <div className="modal-content">
                <h4>{this.state.text_language[0].new_version_text}</h4>
                <p>{this.state.text_language[0].new_version_summary_text}</p>

                <a href="/home" onClick={(e) => { this._reloadApp(e) }} className="btn btn-primary continuebtn ">{this.state.text_language[0].update_text}</a>

              </div>

            </div>
            <a className="waves-effect waves-light btn modal-trigger" id="modal_language_trigger" style={{ display: "none" }} href="#modal-2">Modal</a>
            <div id="modal-2" className="modal">
              <div className="modal-content">
                <h1 className='center-align'>{this.state.text_language[0].choose_language_text}</h1>
                <div className='row center-align'>
                  <div className='col s6'>
                    <div className="selected-option en" onClick={(e)=>{this._selectOption(e,'en','hi')}}>
                      <img className="responsive-img h-100" src="images/flags/united-kingdom.png" alt="English" />
                      <p>{this.state.text_language[0].english_text}</p>
                    </div>
                  </div>
                  <div>
                    <div className='col s6'>
                      <div className="hi" onClick={(e)=>{this._selectOption(e,'hi','en')}}>
                        <img className="responsive-img h-100" src="images/flags/india.png" alt="Hindi" />
                        <p>{this.state.text_language[0].hindi_text}</p>
                      </div>

                    </div>
                  </div>

                </div>
                <div className='row center-align pt-20'>
                  <a href="/" onClick={(e) => { this._continueApp(e) }} className="btn btn-primary continuebtn ">{this.state.text_language[0].continue_text}</a>

                </div>

              </div>

            </div>
          </React.Fragment>
        );
      } else {
        if (_isLocalhost == true) {
          return (
            <React.Fragment>
              <Loader visibility={this.state.loader} />
              <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false} />
              <Navigation />
              <a className="waves-effect waves-light btn modal-trigger" id="modal_test" style={{ display: "none" }} href="#modal1">Modal</a>
              <div id="modal1" className="modal">
                <div className="modal-content">
                  <h4>{this.state.text_language[0].new_version_text}</h4>
                  <p>{this.state.text_language[0].new_version_summary_text}</p>

                  <a href="/home" onClick={(e) => { this._reloadApp(e) }} className="btn btn-primary continuebtn ">{this.state.text_language[0].update_text}</a>

                </div>

              </div>
              <a className="waves-effect waves-light btn modal-trigger" id="modal_language_trigger" style={{ display: "none" }} href="#modal-2">Modal</a>
              <div id="modal-2" className="modal">
                <div className="modal-content">
                  <h1 className='center-align'>{this.state.text_language[0].choose_language_text}</h1>
                  <div className='row center-align'>
                    <div className='col s6'>
                      <div className="selected-option en" onClick={(e)=>{this._selectOption(e,'en','hi')}}>
                        <img className="responsive-img h-100" src="images/flags/united-kingdom.png" alt="Cardiology" />
                        <p>{this.state.text_language[0].english_text}</p>
                      </div>
                    </div>
                    <div>
                      <div className='col s6'>
                        <div className="hi" onClick={(e)=>{this._selectOption(e,'hi','en')}}>
                          <img className="responsive-img h-100" src="images/flags/india.png" alt="Cardiology" />
                          <p>{this.state.text_language[0].hindi_text}</p>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className='row center-align pt-20'>
                    <a href="/" onClick={(e) => { this._continueApp(e) }} className="btn btn-primary continuebtn ">{this.state.text_language[0].update_text}</a>

                  </div>
                </div>

              </div>
            </React.Fragment>
          );
        } else {
          return (
            <UnderMaintenance />
          )
        }
      }
    } else {
      return (
        <NoConnectivity />
      )
    }

  }
}

export default withSnackbar(App);
