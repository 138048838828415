import React, { Component } from 'react'
import { modal_time, _getLocalLanguage } from './Constants';
import { lang_en } from './language/en';
import { lang_hi } from './language/hi';
import {
    Link
} from "react-router-dom";
import withNavigateHook from './withNavigateHook';
const language = _getLocalLanguage();
let $ = window.$;

class BookingConfirmModal extends Component {

    constructor(props) {
        super(props);
        // state declaration
        this.state = {
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            message: this.props.message,
            type: this.props.type,
            visibility: this.props.visibility,
            form_data: this.props.form_data,
            booking_type: typeof this.props.booking_type != "undefined" && this.props.booking_type != null ? this.props.booking_type : 'parchee'
        }

    }


    /**
     * 
     */
    _checkVisibility = () => {

        if (this.props.visibility == 'show') {
            //window.startConfetti();
            $('#modal-4').modal({
                dismissible: false
            });

            $('#modal_booking_trigger')[0].click();
            setTimeout(() => {
                //$('#modal-4').modal('close');
            }, modal_time);
        }
    }

    /**
     * 
     * @param {*} e 
     * @returns 
     */
    _continueApp = (e) => {
        e.preventDefault();
        if (this.props.type == 'success') {
            $('#modal-4').modal('close');
            let date=new Date();
            let today_date= date.getFullYear()+ '-' + (date.getMonth() + 1) + '-' +date.getDate();
            if(today_date == this.props.send_date){
                return (
                    this.props.navigation("/patient-live")
                )
            }else{
                return (
                    this.props.navigation("/patient-history?booking_date=" + this.props.send_date
                    )
                )
            }
           
        } else {
            $('#modal-4').modal('close');
        }

    }

    render() {
        return (
            <div>
                {
                    this._checkVisibility()
                }
                <a className="waves-effect waves-light btn modal-trigger" id="modal_booking_trigger" style={{ display: "none" }} href="#modal-4">Modal</a>
                {
                    this.props.type == "success"
                        ?
                        <div id="modal-4" className="modal br-radius custom-modal">
                            <div className="modal-content">
                                <div className='teal lighten-2'>
                                    <h1 className='center-align modal-header'>{this.state.text_language[0].booking_confirmation_text}</h1>
                                    <h6 className='center-align pb-10 white-text'>{this.state.text_language[0].booking_added_text}</h6>
                                </div>
                                {
                                    $.isEmptyObject(this.props.form_data) != true ?
                                        <div className='row center-align'>
                                            <div className='col s6'>
                                                <p><b>{this.state.text_language[0].patient_text} {this.state.text_language[0].name_text}:</b> {this.props.form_data.get('name')}</p>
                                            </div>
                                            <div className='col s6'>
                                                <p><b>{this.state.text_language[0].patient_text} {this.state.text_language[0].phone_text}:</b> {this.props.form_data.get('phone_number')}</p>
                                            </div>
                                            <div className='col s6'>
                                                <p>
                                                    {
                                                        this.state.booking_type == 'parchee'
                                                            ?
                                                            <React.Fragment>
                                                                <b>{this.state.text_language[0].problem_text}:</b> {this.props.form_data.get('problem')}
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <b>{this.state.text_language[0].test_name_text}:</b> {this.props.form_data.get('test_name')}
                                                            </React.Fragment>
                                                    }

                                                </p>
                                            </div>
                                            <div className='col s6'>
                                                <p><b>{this.state.text_language[0].age_text}:</b> {this.props.form_data.get('age')}</p>
                                            </div>
                                            <div className='col s6'>
                                                <p><b>{this.state.text_language[0].doctors_text} {this.state.text_language[0].name_text}:</b> {this.props.form_data.get('doctor_name')}</p>
                                            </div>
                                            <div className='col s6'>
                                                <p><b>{this.state.text_language[0].booking_text} {this.state.text_language[0].date_text}:</b> {this.props.form_data.get('booking_date')}</p>
                                            </div>
                                            <div className='col s6'>
                                                <p><b>{this.state.text_language[0].doctors_text} {this.state.text_language[0].fees_text}:</b> {this.props.form_data.get('booking_amount')}</p>
                                            </div>
                                            <div className='col s6'>
                                                <p><b>{this.state.text_language[0].app_text} {this.state.text_language[0].charges_text}:</b> {this.props.form_data.get('app_charges')}</p>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }


                                <div className="row">
                                    <div className="col s12">
                                        <div className="card blue-grey lighten-1">
                                            <div className='row'>
                                                <div className="col s12 white-text center-align">
                                                    <h6 className="pt-10">{this.state.text_language[0].for_any_query_text}</h6>

                                                </div>
                                                <div className="col s12">
                                                    <div className="card-content white-text no-padding">
                                                        <div className='row'>
                                                            <div className='col m4 s4 right-align'>
                                                                <p><img className="responsive-img h-40" src="images/telephone-call.png" alt="Welcome" /></p>
                                                            </div>
                                                            <div className='col m8 s8'>
                                                                <p className='mt-10'><a className='active' target="_blank" href="tel:+91 79819 11392">+91 79819 11392</a></p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col m4 s4 right-align'>
                                                                <p><img className="responsive-img h-40" src="images/whatsapp.png" alt="Welcome" /></p>
                                                            </div>
                                                            <div className='col m8 s8'>
                                                                <p className='mt-10'><a className='active' target="_blank" href="https://wa.me/qr/I2UILYPVUH63B1/">{this.state.text_language[0].contact_us_whatsapp_text}</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>

                                <div className='row center-align pt-20'>
                                    {/* <Link to={"/patient-history?booking_date=" + this.props.send_date} state={{"page":this.props.page}} className="btn btn-primary continuebtn ">
                                        {this.state.text_language[0].continue_text}
                                    </Link> */}
                                    {/* <a href={"/patient-history?booking_date=" + this.props.send_date} className="btn btn-primary continuebtn ">{this.state.text_language[0].continue_text}</a> */}


                                    <a href="/" onClick={(e) => { this._continueApp(e) }} className="btn btn-primary continuebtn ">{this.state.text_language[0].continue_text}</a>

                                </div>
                            </div>

                        </div>
                        :

                        <div id="modal-4" className="modal br-radius custom-modal">
                            <div className="modal-content">
                                <div className='teal lighten-2'>
                                    <h1 className='center-align modal-header'>{this.state.text_language[0].booking_confirmation_text}</h1>
                                </div>

                                <div className='row center-align'>
                                    <div className='col s12'>
                                        <p><b>{this.state.text_language[0].booking_error_text}</b></p>
                                        <p><b>{this.state.text_language[0].please_try_again_later_text}</b></p>
                                    </div>

                                </div>



                                <div className="row">
                                    <div className="col s12">
                                        <div className="card blue-grey lighten-1">
                                            <div className='row'>
                                                <div className="col s12 white-text center-align">
                                                    <h6 className="pt-10">{this.state.text_language[0].for_any_query_text}</h6>

                                                </div>
                                                <div className="col s12">
                                                    <div className="card-content white-text">
                                                        <div className='row'>
                                                            <div className='col m4 s4 right-align'>
                                                                <p><img className="responsive-img h-40" src="images/telephone-call.png" alt="Welcome" /></p>
                                                            </div>
                                                            <div className='col m8 s8'>
                                                                <p className='mt-10'><a className='active' target="_blank" href="tel:+91 79819 11392">+91 79819 11392</a></p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col m4 s4 right-align'>
                                                                <p><img className="responsive-img h-40" src="images/whatsapp.png" alt="Welcome" /></p>
                                                            </div>
                                                            <div className='col m8 s8'>
                                                                <p className='mt-10'><a className='active' target="_blank" href="https://wa.me/qr/I2UILYPVUH63B1/">Contact us on Whatsapp</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>

                                <div className='row center-align pt-20'>
                                    <a href="/" onClick={(e) => { this._continueApp(e) }} className="btn btn-primary continuebtn ">{this.state.text_language[0].continue_text}</a>

                                </div>
                            </div>

                        </div>
                }



            </div>
        )
    }
}

export default withNavigateHook(BookingConfirmModal);
