import React, { Component, Fragment } from 'react';

import {
    Link
} from "react-router-dom";
import PatientMenu from './includes/PatientMenu';
import PatientHeader from './includes/PatientHeader';
import PatientPageHeader from './includes/PatientPageHeader';
import { _logOut, _getLoginStatus, _showOverlay, _hideOverlay, FILE_URL, _getLocalLanguage, _setLocationCookie, _getLocationCookie } from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
import { _getAgentAnalytics } from './api/Patient';
import Toast from './includes/Toast';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import { _getCities } from './api/Common';
import { Chart } from "react-google-charts";
const language = _getLocalLanguage();
//let google = window.google;

class TotalBookings extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            analytics_data: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            selected_location: _getLocationCookie()

        }
      

    }


    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        let res = await _getLoginStatus();
        // console.log(res.status);
        if (res['status'] == true) {
            await this.setState({
                user: res.data,
            });
            if (res['data']['user_type'] == 'Patient') {
                await this._getAgentAnalytics(res.data);
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    /**
     * 
     */
    _getAgentAnalytics = async (user) => {

        if (typeof user.id != "undefined" && user.id != null) {
            let from = ''; let to = '';
            await this.setState({
                loader: "show"
            })
            let res1 = await _getAgentAnalytics(user.id, from, to);
            //console.log(res1);
            if (res1.data.response == "success") {

                this.setState({
                    analytics_data: res1.data.result.data
                });

                
                // google.charts.load('current', { 'packages': ['corechart'] });
                // setTimeout(() => {
                //     google.charts.setOnLoadCallback(this._drawChart(res1.data.result.data));
                // }, 100);
                
                await this.setState({
                    loader: "hide"
                })
            } else {
                this.setState({
                    list: [],
                    message: res1.data.error,
                    type: 'error',
                    visibility: 'show'
                });
                await this.setState({
                    loader: "hide"
                })
            }
        }
        return;
    }

    /**
     * 
     */
    _drawChart = (chart_data) => {
        //console.log(chart_data);
        var data = [
            ['Booking', 'Number of types of booking'],
            ['Pending', chart_data.pending],
            ['Cancelled', chart_data.cancelled],
            ['Accepted', chart_data.accepted]
        ];

        // Optional; add a title and set the width and height of the chart
        var options = { 'title': this.state.text_language[0].booking_details_text, is3D: true };
 
        return (
            <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width={"100%"}
             // height={"400px"}
            />
          )
    }

    /**
     * 
     * @returns 
     */
    _getBookingHtml = () => {
        return (
            <React.Fragment>
                <div className='p-20'>
                    <p> <b> {this.state.text_language[0].total_bookings_text}</b>: {this.state.analytics_data.total} </p>
                    <p> <b> {this.state.text_language[0].pending_booking_text}</b>: {this.state.analytics_data.pending} </p>
                    <p> <b> {this.state.text_language[0].cancelled_booking_text}</b>: {this.state.analytics_data.cancelled} </p>
                    <p> <b> {this.state.text_language[0].accepted_booking_text}</b>: {this.state.analytics_data.accepted} </p>

                </div>
            </React.Fragment>
        )
    }



    /**
     * 
     * @param {*} e 
     */
    _handleFilter = (e) => {
        // console.log(e.target.value);
        //console.log(this.state.list)
        let searchStr = e.target.value;
        let arr = this.state.default_list;
        let new_array = [];
        new_array = arr.filter(x => (('Dr ').toLowerCase() + x.first_name.toLowerCase() + ' ' + x.last_name.toLowerCase()).includes(searchStr.toLowerCase()));
        this.setState({
            list: new_array
        });

    }



    render() {
        return (
            <React.Fragment>
                {/* <PatientHeader /> */}
                <Loader visibility={this.state.loader} />
                <PatientPageHeader />
                <main role="main">

                    <section id="dashboard-page" className="oparcheecommonCode">

                        <div className="dashboardContent">

                            <div className="container">

                                <div className="row">
                                    <div className="col s12">
                                        <div className="card darken-3">
                                            <div className='row '>
                                                <div className="col s12 center-align">
                                                    <h1>{this.state.text_language[0].total_bookings_text}: {this.state.analytics_data.total_booking}</h1>

                                                </div>
                                                <div className="col s12 center-align">
                                                    <div id="piechart">
                                                     {this._drawChart(this.state.analytics_data)}
                                                    </div>
                                                </div>
                                                <div className="col s12">
                                                    {this._getBookingHtml()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <PatientMenu />
                {
                    this.state.visibility == 'show'
                        ?
                        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                        :
                        ''
                }
                {/* <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} /> */}
            </React.Fragment>
        );
    }
}

export default withNavigateHook(TotalBookings);

