import React, { Component } from 'react'

export default class UnderMaintenance extends Component {
    render() {
        return (
            <React.Fragment>

                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg center-align commonBox">

                                            <div className="row mb-40">

                                                <div className="col s12">
                                                    <h4>App under maintenence.</h4>
                                                    {/* <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" /> */}
                                                </div>
                                                <div className="col s2"></div>
                                                <div className="col s8">
                                                    <img className="responsive-img" src="images/under-maintenance.png" alt="Under Maintenance" />
                                                </div>
                                                <div className="col s2"> </div>
                                                <div className="col s12">
                                                    <h4>We will be back after few hours.</h4>
                                                </div>

                                            </div>


                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>


                </main>
            </React.Fragment >
        )
    }
}
