/* eslint-disable */
import React, { Component } from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Routes,
    Link,
    withRouter,
    Router
} from "react-router-dom";
import Home from './Home';
import DoctorLogin from './DoctorLogin';
import DoctorForgotPassword from './DoctorForgotPassword';
import DoctorLoginOtp from './DoctorLoginOtp';
import DoctorsAccount from './DoctorsAccount';
import PatientLogin from './PatientLogin';
import PatientForgotPassword from './PatientForgotPassord';
import PatientLoginOtp from './PatientLoginOtp';
import PatientRegistration from './PatientRegistration';
import DoctorsDashboard from './DoctorsDashboard';
import DoctorsHistory from './DoctorsHistory';
import DoctorsEquipment from './DoctorsEquipment';
import DoctorsEditProfile from './DoctorsEditProfile';
import DoctorsVerifyOtp from './DoctorsVerifyOtp';
import PatientVerifyOtp from './PatientVerifyOtp';
import PatientDashboard from './PatientDashboard';
import PatientLive from './PatientLive';
import PatientHistory from './PatientHistory';
import PatientAccount from './PatientAccount';
import PatientDoctorProfile from './PatientDoctorProfile';
import PatientDetails from './PatientDetails';
import PatientBookAppointment from './PatientBookAppointment';
import PatientSelection from './PatientSelection';
import GeneratePassword from './GeneratePassword';
import BookingPending from './BookingPending';
import AddBooking from './AddBooking';
import ShowPatientDetails from './ShowPatientDetails';
import PatientEditProfile from './PatientEditProfile';
import TodayBooking from './TodayBooking';
import Emergency from './Emergency';
import MedicalLab from './MedicalLab';
import MedicalDelivery from './MedicalDelivery';
import ReSchedule from './ReSchedule';
import DoctorChangePassword from './DoctorChangePassword';
import PatientChangePassword from './PatientChangePassword';
import DoctorsAccountSetting from './DoctorsAccountSetting';
import DoctorsClinicSetting from './DoctorsClinicSetting';
import DoctorHospitalTiming from './DoctorHospitalTiming';
import AmbulanceServices from './AmbulanceServices';
import DoctorList from './DoctorList';
import HospitalAccount from './HospitalAccount';
import HospitalEditProfile from './HospitalEditProfile';
import HospitalSetting from './HospitalSetting';
import HospitalChangePassword from './HospitalChangePassword';
import HospitalHistory from './HospitalHistory';
import HospitalEmergency from './HospitalEmergency';
import OtherDoctorBooking from './OtherDoctorBooking';
import UnregisteredDoctorBooking from './UnregisteredDoctorBooking';
import SpecialistDoctorList from './SpecialistDoctorList';
import MakeMedicalDelivery from './MakeMedicalDelivery';
import PreviousMedicalDelivery from './PreviousMedicalDelivery';
import TotalBookings from './TotalBookings';
import AboutUs from './AboutUs';
import RefundPolicy from './RefundPolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsConditions from './TermsConditions';
import ContactUs from './ContactUs';
import GovermentHospitals from './GovermentHospitals';
import GovHospitalDeopartment from './GovHospitalDeopartment';
import GovHospitalBooking from './GovHospitalBooking';
import ContactUsDoctor from './ContactUsDoctor';

export default class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount = () => {

    }
    /**
     * Setting the Routing for the Application
     * @returns 
     */
    render() {
        return (
            <React.Fragment>
                <BrowserRouter basename="/">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/doctors-login" element={<DoctorLogin />} />
                        <Route path="/patient-login" element={<PatientLogin />} />
                        <Route path="/doctor-login-otp" element={<DoctorLoginOtp />} />
                        <Route path="/patient-login-otp" element={<PatientLoginOtp />} />
                        <Route path="/doctor-forgot-password" element={<DoctorForgotPassword />} />
                        <Route path="/patient-forgot-password" element={<PatientForgotPassword />} />
                        <Route path="/patient-registration" element={<PatientRegistration />} />
                        <Route path="/doctors-account" element={<DoctorsAccount />} />
                        <Route path="/doctors-dashboard" element={<DoctorsDashboard />} />
                        <Route path="/doctors-equipment" element={<DoctorsEquipment />} />
                        <Route path="/doctors-account" element={<DoctorsAccount />} />
                        <Route path="/doctors-history" element={<DoctorsHistory />} />
                        <Route path="/doctors-edit-profile" element={<DoctorsEditProfile />} />
                        <Route path="/doctors-verify-otp" element={<DoctorsVerifyOtp />} />
                        <Route path="/patient-verify-otp" element={<PatientVerifyOtp />} />
                        <Route path="/patient-dashboard" element={<PatientDashboard />} />
                        <Route path="/patient-account" element={<PatientAccount />} />
                        <Route path="/patient-history" element={<PatientHistory />} />
                        <Route path="/patient-live" element={<PatientLive />} />
                        <Route path="/patient-doctor-profile" element={<PatientDoctorProfile />} />
                        <Route path="/patient-details" element={<PatientDetails />} />
                        <Route path="/patient-book-appointment" element={<PatientBookAppointment />} />
                        <Route path="/patient-selection" element={<PatientSelection />} />
                        <Route path="/generate-password" element={<GeneratePassword />} />
                        <Route path="/pending-booking" element={<BookingPending />} />
                        <Route path="/add-booking" element={<AddBooking />} />
                        <Route path="/show-patient-details" element={<ShowPatientDetails />} />
                        <Route path="/patient-edit-profile" element={<PatientEditProfile />} />
                        <Route path="/today-booking" element={<TodayBooking />} />
                        <Route path="/patient-emergency" element={<Emergency />} />
                        <Route path="/medical-lab" element={<MedicalLab />} />
                        <Route path="/medical-delivery" element={<MedicalDelivery />} />
                        <Route path="/re-schedule" element={<ReSchedule />} />
                        <Route path="/doctor-change-password" element={<DoctorChangePassword />} />
                        <Route path="/patient-change-password" element={<PatientChangePassword />} />
                        <Route path="/doctors-account-setting" element={<DoctorsAccountSetting />} />
                        <Route path="/doctors-clinic-setting" element={<DoctorsClinicSetting />} />
                        <Route path="/doctors-hospital-timing" element={<DoctorHospitalTiming />} />
                        <Route path="/ambulance-service" element={<AmbulanceServices />} />
                        <Route path="/doctor-list" element={<DoctorList />} />
                        <Route path="/hospital-account" element={<HospitalAccount />} />
                        <Route path="/hospital-edit-profile" element={<HospitalEditProfile />} />
                        <Route path="/hospital-setting" element={<HospitalSetting />} />
                        <Route path="/hospital-change-password" element={<HospitalChangePassword />} />
                        <Route path="/hospital-history" element={<HospitalHistory />} />
                        <Route path="/hospital-emergency" element={<HospitalEmergency />} />
                        <Route path="/other-doctor-booking" element={<OtherDoctorBooking />} />
                        <Route path="/unregistered-doctor-booking" element={<UnregisteredDoctorBooking />} />
                        <Route path="/specialist-doctor-list" element={<SpecialistDoctorList />} />
                        <Route path="/make-medical-delivery" element={<MakeMedicalDelivery />} />
                        <Route path="/previous-medical-delivery" element={<PreviousMedicalDelivery />} />
                        <Route path="/total-bookings" element={<TotalBookings />} />
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="/refund-policy" element={<RefundPolicy />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/terms-and-condition" element={<TermsConditions />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/government-hospitals" element={<GovermentHospitals />} />
                        <Route path="/gov-hospital-department" element={<GovHospitalDeopartment />} />
                        <Route path="/gov-hospital-booking" element={<GovHospitalBooking />} />
                        <Route path="/contact-us-doc" element={<ContactUsDoctor />} />
                    </Routes>
                </BrowserRouter>
                {/* <BrowserRouter basename="/">
                    <Route exact path="/" element={<Home/>} />
                    <Route exact path="/home" element={<Home/>} />
             </BrowserRouter> */}
            </React.Fragment>
        )
    }
}
