import React, { Component } from 'react';

import {
  Link
} from "react-router-dom";
import PatientMenu from './includes/PatientMenu';
import PatientHeader from './includes/PatientHeader';
import withNavigateHook from './includes/withNavigateHook';
import { _getPatientHistory } from './api/Patient';
import Toast from './includes/Toast';
import { _getLoginStatus, _checkNull, _validateNumber, time_out, _showOverlay, _hideOverlay, _clearAllInterval, _clearInterval, _getLocalLanguage } from './includes/Constants';
import PatientPageHeader from './includes/PatientPageHeader';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
const language = _getLocalLanguage();
const startDate = new Date();
let $ = window.$;
class PatientHistory extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      list: [],
      message: '',
      type: '',
      visibility: 'hide',
      loader: "hide",
      text_language: language.lang == 'en' ? lang_en : lang_hi
    }


    // console.log(this.props.location)
  }


  /**
  *
  * @returns
  */
  componentDidMount = async () => {
    // if (typeof this.props.location.state != "undefined" && this.props.location.state != null && Object.keys(this.props.location.state).length >0 ) {
    //   console.log(this.props.location)
    //   if (this.props.location.state.page == "refresh") {
    //     this.props.location.state = null;
    //     //window.location.reload();
    //   }
    // }
    let res = await _getLoginStatus();
    let app = this;
    let url = window.location.search;
    let searchParams = new URLSearchParams(url);
    if (res['status']) {
      await this.setState({
        user: res.data
      });
      let get_booking_date = searchParams.get('booking_date')
      if (typeof get_booking_date != 'undefined' && get_booking_date != null) {
        await $("#datepicker").datepicker({
          format: 'yyyy-mm-dd',
          onSelect: function (e) {
            app._handleChange(e);
          },
          autoClose: true
        }).datepicker("setDate", new Date(get_booking_date));
        $('#datepicker').val(get_booking_date);

      } else {
        await $("#datepicker").datepicker({
          format: 'yyyy-mm-dd',
          onSelect: function (e) {
            app._handleChange(e);
          },
          autoClose: true
        }).datepicker("setDate", startDate);
        $('#datepicker').val(startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate());

      }

      if (res['data']['user_type'] == 'Patient') {
        await this._getPatientHistory(res);
      } else {
        this.props.navigation('/home');
      }

    } else {
      this.props.navigation('/home');
    }

  }

  /**
    *
    */
  _getPatientHistory = async (res) => {

    if (typeof res.data.id != "undefined" && res.data.id != null) {
      //_showOverlay();
      await this.setState({
        loader: "show",
        message: '',
        type: 'success',
        visibility: 'hide',
      })
      let date = $("#datepicker").val();
      let res1 = await _getPatientHistory(res.data.id, date);
      if (res1.data.response == "success") {

        this.setState({
          list: res1.data.result,
          loader: "hide",
        });
        // _hideOverlay();
      } else {
        this.setState({
          list: [],
          message: res1.data.error,
          type: 'error',
          visibility: 'show',
          loader: "hide"
        });
        // _hideOverlay();
      }
    }
    return;
  }



  /**
   *
   */
  _getBookingList = () => {

    if (Object.keys(this.state.list).length > 0) {
      return (
        Object.values(this.state.list).map((value, index) => {

          return (

            <div key={"div-" + index} className="card-panel grey lighten-5 z-depth-1">
              <div className="row valign-wrapper">

                {/* <div className="col s3">
                         <img src="images/img3.png" alt="" className="circle responsive-img img-50" />
                    </div> */}

                <div className="col s12">
                  {
                    typeof value.type != "undefined" && value.type != null && value.type == 'Lab'

                      ?
                      <span className="black-text">

                        <p><b>{this.state.text_language[0].slot_text}:</b>&nbsp;{typeof value.slot != "undefined" && value.slot != '00:00' ? value.slot : 'Pending'}</p>
                        <p><b>{this.state.text_language[0].lab_text} {this.state.text_language[0].name_text}:</b>&nbsp;{value.lab_name}</p>
                        <p><b>{this.state.text_language[0].test_name_text} :</b>&nbsp;{value.test_name}</p>
                        <p><b>{this.state.text_language[0].patient_text} {this.state.text_language[0].name_text}:</b>&nbsp;{value.patient_history.patient_details.name}</p>
                        <p><b>{this.state.text_language[0].age_text}:</b>&nbsp;{value.patient_history.patient_details.age}&nbsp;years</p>
                        <p><b>{this.state.text_language[0].booking_text} {this.state.text_language[0].on_text}:</b>&nbsp;{value.booking_date}</p>
                        <p><b>{this.state.text_language[0].appointment_text} {this.state.text_language[0].date_text}:</b>&nbsp;{value.appointment_date}</p>
                        <p><b>{this.state.text_language[0].status_text}:</b>&nbsp;{value.status}</p>
                        <p><b>{this.state.text_language[0].booking_text} {this.state.text_language[0].number_text}:</b>&nbsp;{typeof value.booking_number != "undefined" && value.booking_number != 0 ? value.booking_number : 'Not Assingned at the moment.'}</p>
                      </span>
                      :
                      <span className="black-text">

                        <p><b>{this.state.text_language[0].slot_text}:</b>&nbsp;{typeof value.slot != "undefined" && value.slot != '00:00' ? value.slot : 'Pending'}</p>
                        <p><b>{this.state.text_language[0].doctor_text} {this.state.text_language[0].name_text}:</b>&nbsp;{value.doctor.first_name + " " + value.doctor.last_name}</p>
                        <p><b>{this.state.text_language[0].patient_text} {this.state.text_language[0].name_text}:</b>&nbsp;{value.patient_history.patient_details.name}</p>
                        <p><b>{this.state.text_language[0].age_text}:</b>&nbsp;{value.patient_history.patient_details.age}&nbsp;years</p>
                        <p><b>{this.state.text_language[0].booking_text} {this.state.text_language[0].on_text}:</b>&nbsp;{value.booking_date}</p>
                        <p><b>{this.state.text_language[0].appointment_text} {this.state.text_language[0].date_text}:</b>&nbsp;{value.appointment_date}</p>
                        <p><b>{this.state.text_language[0].status_text}:</b>&nbsp;{value.status}</p>
                        <p><b>{this.state.text_language[0].booking_text} {this.state.text_language[0].number_text}:</b>&nbsp;{typeof value.booking_number != "undefined" && value.booking_number != 0 ? value.booking_number : 'Not Assingned at the moment.'}</p>
                      </span>
                  }


                </div>


              </div>

            </div>
          )



        })
      )
    } else {
      return (

        <div className="col s12 center-align mt-20">
          <img src="images/not-found.gif" alt="" className="circle responsive-img" />

        </div>



      )
    }

  }

  /**
  *
  */
  _handleIconClick = () => {
    //alert('ggggg')
    $("#datepicker").datepicker("open");
  }

  /**
   *
   */
  _handleDateAdd = async () => {

    var date = new Date($("#datepicker").val());
    var newdate = new Date(date);
    newdate.setDate(newdate.getDate() + 1); // minus the date
    var nd = new Date(newdate);
    //alert(nd);
    $("#datepicker").datepicker("setDate", nd);
    let res = [];
    res.data = this.state.user;
    $('#datepicker').val(nd.getFullYear() + '-' + (nd.getMonth() + 1) + '-' + nd.getDate());

    //await this._getPatientHistory(res);
  }

  /**
   *
   */
  _handleDateSubtract = async () => {

    // alert($("#datepicker").val());
    var date = new Date($("#datepicker").val());
    var newdate = new Date(date);
    newdate.setDate(newdate.getDate() - 1); // minus the date
    var nd = new Date(newdate);
    //alert(nd);
    $("#datepicker").datepicker("setDate", nd);
    let res = [];
    res.data = this.state.user;
    $('#datepicker').val(nd.getFullYear() + '-' + (nd.getMonth() + 1) + '-' + nd.getDate());


    //await this._getPatientHistory(res);
  }

  /**
   *
   */
  _handleChange = async (date) => {
    //console.log(date)
    $('#datepicker').val(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
    await _clearAllInterval();
    let res = [];
    res.data = this.state.user;
    await this._getPatientHistory(res);
  }

  /**
   *
   * @returns
   */
  _showDate = () => {
    let get_date = new Date($('#datepicker').val());
    let show_date = get_date.getDate() + '-' + (get_date.getMonth() + 1) + '-' + get_date.getFullYear();

    return (
      <h6 id="show_date" className="center-align">{this.state.text_language[0].date_text}: {show_date}</h6>
    )
  }


  /**
   *
   * @returns
   */
  _showDate = () => {
    let get_date = new Date($('#datepicker').val());
    let show_date = get_date.getDate() + '-' + (get_date.getMonth() + 1) + '-' + get_date.getFullYear();

    return (
      <h6 id="show_date" className="center-align">{this.state.text_language[0].date_text}: {show_date}</h6>
    )
  }

  /**
   *
   * @returns
   */
  render() {

    return (
      <React.Fragment>
        {/* <PatientHeader /> */}
        <Loader visibility={this.state.loader} />
        <PatientPageHeader />
        <main role="main">

          <section id="dashboard-page" className="oparcheecommonCode">

            <div className="dashboardContent">

              <div className="container">

                <div className="row">
                  <div className="col s12 mt-10">
                    <div className="strip">

                      <div className="row pt-20">

                        <div className="col s3 left-align">

                          <i className="material-icons pointer-p" onClick={() => this._handleDateSubtract()}>chevron_left</i>

                        </div>

                        <div className="col s6 center-align">

                          <div className="bookingTxt"><h3>
                            <i className="material-icons pointer-p" onClick={() => { this._handleIconClick() }}> calendar_today</i>
                            <span>{this.state.text_language[0].booking_text} {this.state.text_language[0].history_text}</span></h3>
                          </div>
                          <input onChange={() => { this._handleChange() }} id="datepicker" style={{ "visibility": "hidden", "height": "0px" }} />
                        </div>
                        <div className="col s3 right-align">

                          <i className="material-icons pointer-p" onClick={() => this._handleDateAdd()}>chevron_right</i>

                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="col s12">
                    {this._showDate()}
                    {this._getBookingList()}
                  </div>

                </div>

              </div>

            </div>

          </section>

        </main>
        <PatientMenu />
        {/* {
          this.state.visibility == 'show'
            ?
            <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            :
            ''
        } */}
        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
      </React.Fragment>
    );
  }
}

export default withNavigateHook(PatientHistory);
