import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import { _checkNull, _getLocalLanguage, _validateEmail } from './includes/Constants';
import { _forgotPassword } from './api/Common';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import Copyright from './includes/Copyright';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
const language= _getLocalLanguage();
class PatientForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            error_message: {
                email: ''
            },
            text_language:language.lang == 'en'?lang_en:lang_hi

        }
    }


    _handleRecoverPassword = async (e) => {
        e.preventDefault();
        await this.setState({
            loader: "show"
        });
        const data = new FormData(e.target);
        let email = data.get('email');
        let res = await _checkNull(email);
        if (res != '') {
            this.setState({
                error_message: {
                    email: res
                }
            });

        } else {
            if (_validateEmail(email)) {
                let res2 = await _forgotPassword(email, 'Patient');

                if (res2.data.response == 'success') {
                    this.setState({
                        error_message: {
                            email: ''
                        },
                        message: res2.data.message,
                        type: 'success',
                        visibility: 'show',
                        loader: "hide"
                    });
                } else {
                    this.setState({
                        error_message: {
                            email: ''
                        },
                        message: res2.data.error,
                        type: 'error',
                        visibility: 'show',
                         loader: "hide"
                    });

                }

            } else {
                this.setState({
                    error_message: {
                        email: "Please provide a valid email address.",
                        loader: "hide"
                    }
                });
            }
        }
    }



    render() {

        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <main role="main">

                    <section id="login-page" className="oparcheecommonCode">

                        <div className="loginContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">

                                        <div className="white-bg center-align commonBox">
                                            <div className="row">
                                                <div className="col 6">
                                                    <Link to="/" title="Back"> <i className="material-icons back-icon">arrow_back</i></Link>
                                                </div>
                                            </div>

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" />

                                                    <h1>{this.state.text_language[0].password_text} {this.state.text_language[0].recover_text}</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                <div className="col s12">

                                                    <form onSubmit={(e) => this._handleRecoverPassword(e)} method="post" >

                                                        <div className="input-field">

                                                            <input type="text" id="email" name="email" className="validate" />

                                                            <label htmlFor="email">{this.state.text_language[0].email_text}</label>
                                                            <div className="error">{this.state.error_message.email}</div>

                                                        </div>

                                                        <button type="submit" className="btn btn-primary continuebtn">{this.state.text_language[0].recover_text}</button>
                                                    </form>

                                                </div>

                                            </div>

                                            <Copyright />

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientForgotPassword);
