import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import { _logOut, _clearAllInterval,FILE_URL } from './Constants';
import withNavigateHook from './withNavigateHook';

let $ = window.$;
 class HsopitalSideMenu extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

    }

    /**
     * 
     */
    componentDidMount = async () => {
        await $('.sidenav').sidenav();
    }

    /**
    * 
    * @param {*} param 
    */
  _handleLogout = async (e) => {
    e.preventDefault();
    let res = await _logOut('Doctor');
    this.props.navigation(res);
  }


    render() {

        return (
            <React.Fragment>

                <ul id="slide-out" className="sidenav">
                    <li className="menu-header-bg"><div className="user-view">
                        {/* <div className="background">
                            <img src="images/big-logo.png" />
                        </div> */}
                        <Link to="#user" className="">
                            {/* <img className="responsive-img menu-img" src="images/doctor.png" /> */}
                            <img className="responsive-img  menu-img" src={typeof this.props.user.image != "undefined" && this.props.user.image != "" && this.props.user.image != null ? FILE_URL + this.props.user.image : "images/img3.png"} alt="" />
                        </Link>
                        <Link to="/hospital-account"><span className="white-text name">{this.props.user.name}
                        <i className="material-icons right">keyboard_arrow_right </i>
                        </span>
                         </Link>

                    </div></li>
                    <li><Link to="/hospital-account"><i className="material-icons">person</i> ACCOUNT </Link></li>
                    <li><Link to="#!"><i className="material-icons">chat</i> FAQ's </Link></li>
                    <li><Link to="/contact-us-doc"><i className="material-icons">email</i> CONTACT US </Link></li>
                    {/* <li><Link to="#!"><i className="material-icons">person</i> Account </Link></li> */}
                    <li><div className="divider"></div></li>
                    <li><p className='center-align'>App Version: V-{window.localStorage.getItem("version")}</p></li>
                    {/* <li><a className="sidenav-close" href="#!">Clicking this will close Sidenav</a></li> */}
                </ul>

            </React.Fragment>

        )
    }
}

export default withNavigateHook(HsopitalSideMenu)
