import React, { Component } from 'react'
import { _getLoginStatus } from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import PatientPageHeader from './includes/PatientPageHeader';
let $ = window.$;
class AmbulanceServices extends Component {
    /**
       * 
       * @param {*} props 
       */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            data: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide"
        }
        // console.log(this.props.location);
    }

    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        $('.tabs').tabs();
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Patient') {

            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    render() {
        return (
            <React.Fragment>
                <PatientPageHeader />
                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg center-align commonBox" style={{ "marginTop": "15px" }}>

                                            <div className="row">

                                                <div className="col s12">

                                                    <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchi" />

                                                </div>

                                            </div>


                                            <div className="row">
                                                <div className="col s12 center-align mt-20">
                                                    <img src="images/not-found.gif" alt="" className="circle responsive-img" />
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
            </React.Fragment>
        );
    }
}

export default withNavigateHook(AmbulanceServices)
