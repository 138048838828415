import React, { Component } from 'react';
import {
     
    Link
    
} from "react-router-dom";
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import { _showOverlay, _hideOverlay, _validateLogin ,_rememberMe,_donotRememberMe,_browserLogin, time_out, login_r, _getToken} from './includes/Constants';
import {_loginUser, _updateNotificationToken} from './api/Common';
import Loader from './includes/Loader';

class PatientLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remember_me: false,
            phone_number: '',
            password: '',
            message: '',
            type: '',
            visibility: 'hide',
            error_message: {
                phone_number: '',
                password: '',
                login_error:''
            },
            loader:"hide"
        }
    }


    componentDidMount =async()=>{
        let res= await _browserLogin("Patient");
        if(typeof res != "undefined" && res !=''){
            this.props.navigation(res);
         }
    }
    
    /**
     * 
     * @param {*} e 
     */
     async _handleLogin(e) {
        e.preventDefault();
        const data = new FormData(e.target);

        let remember_me = data.get('rememberme');

       
            let res = await _validateLogin(data);
            //  console.log(res);
            if (res.status) {
                let phone_number = data.get('phone_number');
                let password = data.get('password');
                if (remember_me == 'on') {
                    let rem = await _rememberMe(phone_number, password);
                    this._loginUser(phone_number, password);
                }else{
                    let rem = await  _donotRememberMe();
                    this._loginUser(phone_number, password);
                }
            } else {
                this.setState({
                    error_message: {
                        phone_number: res.error.error_phone_number,
                        password: res.error.error_pass,
                        login_error:''
                    }
                });
            }
       

    }
    
    /**
     * 
     * @param {*} phone_number 
     * @param {*} password 
     */
    async _loginUser(phone_number,password){
             await this.setState({
                    loader:"show",
                    message:'',
                    type: 'success',
                    visibility: 'hide'
             })
            // _showOverlay();
             let res = await _loginUser(phone_number, password,'patient');
             //console.log(res);
             if(res.data.response == 'success'){
                 let data =res.data.result;
                 let token= await _getToken();
                 if(typeof token !='undefined' && token !=null && token['status'] == true){
                   await _updateNotificationToken(token['token'],data.id,'Patient','login')
                 }
                 this.setState({
                    error_message: {
                        phone_number: '',
                        password: '',
                    },
                    message:res.data.message,
                    type:'success',
                    visibility:'show'
                });
                if(window.localStorage){
                    await window.localStorage.setItem('user',JSON.stringify(data));
                   // await window.localStorage.setItem('user_token',JSON.stringify(res.data.token));
                    // window.location.href='/asset-pwa/engineer/dashboard';
                  }
                setTimeout(() => {
                    return (
                       this.props.navigation("/patient-selection")
                    )
                  }, login_r);
                
             }else{
                this.setState({
                    error_message: {
                        phone_number: '',
                        password: '',
                    },
                    message:res.data.error,
                    type:'error',
                    visibility:'show'
                });
             }
             await this.setState({
                loader:"hide"
           })
             //_hideOverlay();
            // user_data=res.data.result;
            // console.log(res);
    }


    render() {

        return (
        
            <React.Fragment>
                <Loader visibility={this.state.loader}/>
                <main role="main">
                    <section id="login-page" className="oparcheecommonCode">
                        <div className="loginContent">
                            <div className="container">
                                
                                <div className="row">
                                    <div className="col s12 l4 offset-l4">
                                        <div className="white-bg commonBox">
                                                <div className="row">
                                                    <div className="col 6">
                                                    <Link to="/"  title="Back"> <i className="material-icons back-icon">arrow_back</i></Link>
                                                    </div>
                                                </div>
                                            <div className="row">
                                                <div className="col s12 center-align">
                                                <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" />
                                                    <h1>Patient's Portal</h1>
                                                    {/* <button className="googlebtn" type="button">Log in with Google</button>
                                                    <div className="separator-line"><span>Or</span></div> */}
                                                </div>
                                                <form onSubmit={(e) => this._handleLogin(e)}  method="post" >
                                                    <div className="col s12">
                                                        <div className="input-field">
                                                            <input type="text" id="phone_number" name="phone_number" className="validate" />
                                                            <label htmlFor="phone_number">Mobile Number</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>
                                                        </div>
                                                        <div className="input-field">
                                                            <input type="password" id="password" name="password"  className="validate" />
                                                            <label htmlFor="password">Password</label>
                                                            <div className="error">{this.state.error_message.password}</div>
                                                        </div>
                                                        <div className="row forgotTxt">
                                                        <div className="col s6">
                                                            <Link to="/patient-forgot-password">Forgot Password?</Link>
                                                            {/* <a href="http://oparchee.desicoding.com/forgot-password.html" className="">Forgot Password?</a> */}
                                                        </div>
                                                        <div className="col s6 right-align">
                                                        <Link to="/patient-login-otp">Login with OTP</Link>
                                                            {/* <a href="http://oparchee.desicoding.com/login-with-otp.html" className="">Login with OTP</a> */}
                                                        </div>
                                                    </div>
                                                        <button type="submit" className="btn btn-primary continuebtn"> Login</button>
                                                        {/* <Link className="btn btn-primary continuebtn" to="/patient-account">Login</Link> */}
                                                        {/* <a href="http://oparchee.desicoding.com/account.html" className="btn btn-primary continuebtn">Login</a> */}
                                                        <p className="blackTxt center-align">Don't have an account ? &nbsp;&nbsp;<Link style={{color:"red"}} to="/patient-registration">Register Here</Link></p>
                                                        {/* <p className="blackTxt center-align">Don't have an account ? &nbsp;&nbsp;<a style={{color:"red"}} href="http://oparchee.desicoding.com/registration.html">Register</a></p> */}
    
                                                    </div>
                                                </form>
                                               
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientLogin);
