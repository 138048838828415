import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";

class DoctorLoginOtp extends Component {
    render() {

        return (
            <React.Fragment>
                <main role="main">
                    <section id="login-page" className="oparcheecommonCode">
                        <div className="loginContent">
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 l4 offset-l4">
                                        <div className="white-bg commonBox">
                                            <div className="row">
                                                <div className="col 6">
                                                    <Link to="/" title="Back"> <i className="material-icons back-icon">arrow_back</i></Link>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s12 center-align">
                                                    <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" />
                                                    <h1>Enter your mobile number to continue</h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s12 center-align">
                                                    <form>
                                                        <div className="input-field">
                                                            <input placeholder="" type="number" id="mobile_number" className="validate" />
                                                            <label htmlFor="mobile_number">Mobile Number</label>
                                                        </div>
                                                        <Link className="btn btn-primary continuebtn" to="/doctors-verify-otp">Continue</Link>
                                                        {/* <a href="http://oparchee.desicoding.com/account.html" className="btn btn-primary continuebtn">Continue</a> */}
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </React.Fragment>
        );
    }
}

export default DoctorLoginOtp;
