import React, { Component } from 'react'
import { _checkNull, _getLoginStatus, _getUserDoctor, _validateNumber } from './includes/Constants';
import DoctorsHeader from './includes/DoctorsHeader';
import DoctorsMenu from './includes/DoctorsMenu';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook'
import { states } from './includes/States';
import { _editDoctorClinicDetails, _editDoctorHospitalTiming } from './api/Doctor';
let $ = window.$;

class DoctorHospitalTiming extends Component {
    /**
      * 
      * @param {*} props 
      */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'hide',
            user_doctor:{},
            error_message: {
                timing_1: '',
                timing_2: '',
                slots_morning: '',
                slots_afternoon: '',
                slots_evening: '',
                morning_timing_1: '',
                morning_timing_2: '',
                afternoon_timing_1: '',
                afternoon_timing_2: '',
                evening_timing_1: '',
                evening_timing_2: '',
                slot_capacity_morning: '',
                slot_capacity_afternoon: '',
                slot_capacity_evening: ''
            },
            loader: "hide",
        }
    }
    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {

        let res = await _getLoginStatus();
        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Doctor') {
                let res1= await _getUserDoctor();
                if (res1['status']) {
                    await this.setState({
                      user_doctor: res1.data
                    });
                  }
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
        this._initTimepicker();
    }

    /**
     * 
     */
    _initTimepicker = () => {
        // setTimeout(() => {
        //     alert('100');
            $('.timepicker').timepicker({
                autoClose: true,
            });
        //}, 5000);

    }

    /**
     * 
     * @param {*} e 
     */
    _checkSlotChange = (e) => {
        let class_name = 'slot_select'.replace('select', (e.target.defaultValue).toLowerCase())

        if (e.target.checked == true) {
            $('.' + class_name).removeClass('d-none');
        } else {
            $('.' + class_name).addClass('d-none');
        }

    }

    /**
   * 
   * @param {*} e 
   */
    async _handleManualBooking(e) {
        e.preventDefault();
        //_showOverlay();
        await this.setState({
            loader: "show"
        })
        const data = new FormData(e.target);
        let timing_1 = data.get('timing_1');
        let timing_2 = data.get('timing_2');
        let slots_morning = data.get('slots_morning');
        let slots_afternoon = data.get('slots_afternoon');
        let slots_evening = data.get('slots_evening');
        let morning_timing_1 = data.get('morning_timing_1');
        let morning_timing_2 = data.get('morning_timing_2');
        let afternoon_timing_1 = data.get('afternoon_timing_1');
        let afternoon_timing_2 = data.get('afternoon_timing_2');
        let evening_timing_1 = data.get('evening_timing_1');
        let evening_timing_2 = data.get('evening_timing_2');
        let slot_capacity_morning = data.get('slot_capacity_morning');
        let slot_capacity_afternoon = data.get('slot_capacity_afternoon');
        let slot_capacity_evening = data.get('slot_capacity_evening');

        let res = await this._validateBooking(data);
        if (res['status']) {

            this.setState({
                error_message: res.error
               
            });

            let reg = await _editDoctorHospitalTiming(timing_1, timing_2, slots_morning, slots_afternoon, slots_evening, morning_timing_1, morning_timing_2,
                afternoon_timing_1, afternoon_timing_2, evening_timing_1, evening_timing_2, slot_capacity_morning,
                slot_capacity_afternoon, slot_capacity_evening, this.state.user_doctor.id);
            if (reg.data.response == 'success') {

                if (window.localStorage) {
                    await window.localStorage.setItem('user_doctor', JSON.stringify(reg.data.result));
                }
                this.setState({
                    message: reg.data.message,
                    type: 'success',
                    visibility: 'show',
                    loader: "hide"
                });

            } else {
                this.setState({
                    message: reg.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });
            }
        } else {
            this.setState({
                error_message: res.error,
                loader: "hide"
            });
            //_hideOverlay();
        }

    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    _validateBooking = (data) => {
        let timing_1 = data.get('timing_1');
        let timing_2 = data.get('timing_2');
        let slots_morning = data.get('slots_morning');
        let slots_afternoon = data.get('slots_afternoon');
        let slots_evening = data.get('slots_evening');
        let morning_timing_1 = data.get('morning_timing_1');
        let morning_timing_2 = data.get('morning_timing_2');
        let afternoon_timing_1 = data.get('afternoon_timing_1');
        let afternoon_timing_2 = data.get('afternoon_timing_2');
        let evening_timing_1 = data.get('evening_timing_1');
        let evening_timing_2 = data.get('evening_timing_2');
        let slot_capacity_morning = data.get('slot_capacity_morning');
        let slot_capacity_afternoon = data.get('slot_capacity_afternoon');
        let slot_capacity_evening = data.get('slot_capacity_evening');
        console.log(slots_morning);
        let response = [];
        response['status'] = false;
        response['error'] = [];

        if (typeof timing_1 != "undefined" && timing_1 != undefined && timing_1.trim() != '' &&
            typeof timing_2 != "undefined" && timing_2 != undefined && timing_2.trim() != ''
        ) {
            if (typeof slots_morning != "undefined" && slots_morning != undefined && slots_morning.trim() != '') {
                if (typeof morning_timing_1 != "undefined" && morning_timing_1 != undefined && morning_timing_1.trim() != '' &&
                    typeof morning_timing_2 != "undefined" && morning_timing_2 != undefined && morning_timing_2.trim() != '' &&
                    typeof slot_capacity_morning != "undefined" && slot_capacity_morning != undefined && slot_capacity_morning.trim() != '') {
                    if (!_validateNumber(slot_capacity_morning)) {
                        response['error']['slot_capacity_morning'] = "Please provide a valid number of patient in numbers.";
                    } else {
                        if (slot_capacity_morning != 0) {
                            response['status'] = true;
                        } else {
                            response['error']['slot_capacity_morning'] = "Please provide a valid number of patient";
                        }
                        //response['status'] = true;
                    }
                } else {
                    response['status'] = false;
                    response['error']['morning_timing_1'] = _checkNull(morning_timing_1);
                    response['error']['morning_timing_2'] = _checkNull(morning_timing_2);
                    response['error']['slot_capacity_morning'] = _checkNull(slot_capacity_morning);
                }

            } else {
                //response['error']['slots_morning'] = _checkNull(slots_morning);
                //response['status'] = false;
            }
            if (typeof slots_afternoon != "undefined" && slots_afternoon != undefined && slots_afternoon.trim() != '') {
                if (typeof afternoon_timing_1 != "undefined" && afternoon_timing_1 != undefined && afternoon_timing_1.trim() != '' &&
                    typeof afternoon_timing_2 != "undefined" && afternoon_timing_2 != undefined && afternoon_timing_2.trim() != '' &&
                    typeof slot_capacity_afternoon != "undefined" && slot_capacity_afternoon != undefined && slot_capacity_afternoon.trim() != '') {
                        if (!_validateNumber(slot_capacity_afternoon)) {
                            response['error']['slot_capacity_afternoon'] = "Please provide a valid number of patient in numbers.";
                        } else {
                            if (slot_capacity_afternoon != 0) {
                                response['status'] = true;
                            } else {
                                response['error']['slot_capacity_afternoon'] = "Please provide a valid number of patient";
                            }
                            //response['status'] = true;
                        }
                } else {
                    response['status'] = false;
                    response['error']['afternoon_timing_1'] = _checkNull(afternoon_timing_1);
                    response['error']['afternoon_timing_2'] = _checkNull(afternoon_timing_2);
                    response['error']['slot_capacity_afternoon'] = _checkNull(slot_capacity_afternoon);
                }

            } else {
                // response['error']['slots_afternoon'] = _checkNull(slots_afternoon);

            }
            if (typeof slots_evening != "undefined" && slots_evening != undefined && slots_evening.trim() != '') {
                if (typeof evening_timing_1 != "undefined" && evening_timing_1 != undefined && evening_timing_1.trim() != '' &&
                    typeof evening_timing_2 != "undefined" && evening_timing_2 != undefined && evening_timing_2.trim() != '' &&
                    typeof slot_capacity_evening != "undefined" && slot_capacity_evening != undefined && slot_capacity_evening.trim() != '') {
                        if (!_validateNumber(slot_capacity_evening)) {
                            response['error']['slot_capacity_evening'] = "Please provide a valid number of patient in numbers.";
                        } else {
                            if (slot_capacity_evening != 0) {
                                response['status'] = true;
                            } else {
                                response['error']['slot_capacity_evening'] = "Please provide a valid number of patient";
                            }
                            //response['status'] = true;
                        }
                } else {
                    response['status'] = false;
                    response['error']['evening_timing_1'] = _checkNull(evening_timing_1);
                    response['error']['evening_timing_2'] = _checkNull(evening_timing_2);
                    response['error']['slot_capacity_evening'] = _checkNull(slot_capacity_evening);
                }

            } else {
                // response['error']['slots_evening'] = _checkNull(slots_evening);
                //response['status'] = false;
            }


        } else {
            // response['error']['timing_1'] = _checkNull(timing_1);
            // response['error']['timing_2'] = _checkNull(timing_2);
            // response['error']['slots_morning'] = _checkNull(slots_morning);
            // response['error']['slots_afternoon'] = _checkNull(slots_afternoon);
            // response['error']['slots_evening'] = _checkNull(slots_evening);
            // response['error']['morning_timing_1'] = _checkNull(morning_timing_1);
            // response['error']['morning_timing_2'] = _checkNull(morning_timing_2);
            // response['error']['afternoon_timing_1'] = _checkNull(afternoon_timing_1);
            // response['error']['afternoon_timing_2'] = _checkNull(afternoon_timing_2);
            // response['error']['evening_timing_1'] = _checkNull(evening_timing_1);
            // response['error']['evening_timing_2'] = _checkNull(evening_timing_2);
            // response['error']['slot_capacity_morning'] = _checkNull(slot_capacity_morning);
            // response['error']['slot_capacity_morning'] = _checkNull(slot_capacity_morning);
            // response['error']['slot_capacity_morning'] = _checkNull(slot_capacity_morning);


        }

        return response;
    }


    render() {
        return (
            <React.Fragment>
                {/* {console.log(this.state.user_doctor.doctor_detail)} */}
                <Loader visibility={this.state.loader} />
                <DoctorsHeader />

                <main role="main">

                    <section id="profile-editing-option-page" className="oparcheecommonCode">

                        <div className="profile-editing-optionContent">

                            <div className="container">

                                <div className="row">
                                    {
                                        this.state.user_doctor != {} && typeof this.state.user_doctor.doctor_detail != "undefined"
                                            ?
                                            <div className="col s12 l4 offset-l4">

                                                <div className="white-bg commonBoxInner">

                                                    <div className="row">

                                                        <div className="col s12 center-align">

                                                            {/* <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" /> */}

                                                            <h1>Doctor Timings</h1>

                                                        </div>

                                                    </div>

                                                    <div className="row">


                                                        <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data">

                                                            <div className="col s12">
                                                                <div className="row">
                                                                    <div className="col s12">
                                                                        <p>General Doctor Timings</p>
                                                                    </div>
                                                                    <div className="input-field col s6">

                                                                        <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.timing.split('-')[0]} name="timing_1" id="timing_1" className="timepicker validate" />
                                                                        <label className="active" htmlFor="timing_1">Opening Time</label>
                                                                        <div className="error">{this.state.error_message.clinic_name}</div>
                                                                    </div>
                                                                    <div className="input-field col s6">

                                                                        <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.timing.split('-')[1]} name="timing_2" id="timing_2" className="timepicker validate" />
                                                                        <label className="active" htmlFor="timing_2">Closing Time</label>
                                                                        <div className="error">{this.state.error_message.clinic_name}</div>
                                                                    </div>

                                                                    <div className="col s12">
                                                                        <p>OPD Slots</p>
                                                                    </div>

                                                                    <div className="input-field col s12 mb-40">
                                                                        <label>
                                                                            <input type="checkbox" name="slots_morning" id="slots_morning" onChange={(e) => { this._checkSlotChange(e) }} defaultChecked={this.state.user_doctor.doctor_detail.slots.includes("Morning") ? "checked" : ""} defaultValue={"Morning"} className="filled-in slot_select" />
                                                                            <span>Morning</span>
                                                                        </label>
                                                                        <div className="error">{this.state.error_message.slots_morning}</div>
                                                                    </div>
                                                                    <div className={this.state.user_doctor.doctor_detail.slots.includes("Morning") ? "slot_morning" : "d-none slot_morning"}>
                                                                        <div className="input-field col s12">
                                                                            <input name="slot_capacity_morning" id="slot_capacity_morning" type="text" defaultValue={typeof this.state.user_doctor.doctor_detail.slot_capacity['Morning'] != "undefined" ? this.state.user_doctor.doctor_detail.slot_capacity['Morning'] : 0} className="validate" />
                                                                            <label className="active">Slot Capacity</label>
                                                                            <div className="error">{this.state.error_message.slot_capacity_morning}</div>
                                                                        </div>
                                                                        <div className="input-field col s6">

                                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.slots.includes("Morning")?this.state.user_doctor.doctor_detail.slot_timing['Morning'].split('-')[0]:""} name="morning_timing_1" id="morning_timing_1" className="timepicker validate" />
                                                                            <label className="active" htmlFor="morning_timing_1">Opening Time</label>
                                                                            <div className="error">{this.state.error_message.morning_timing_1}</div>
                                                                        </div>
                                                                        <div className="input-field col s6">

                                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.slots.includes("Morning")?this.state.user_doctor.doctor_detail.slot_timing['Morning'].split('-')[1]:""} name="morning_timing_2" id="morning_timing_2" className="timepicker validate" />
                                                                            <label className="active" htmlFor="morning_timing_2">Closing Time</label>
                                                                            <div className="error">{this.state.error_message.morning_timing_2}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="input-field col s12 mb-40">
                                                                        <label>
                                                                            <input type="checkbox" name="slots_afternoon" id="slots_afternoon" onChange={(e) => { this._checkSlotChange(e) }} defaultChecked={this.state.user_doctor.doctor_detail.slots.includes("Afternoon") ? "checked" : ""} defaultValue={"Afternoon"} className="filled-in slot_select" />
                                                                            <span>Afternoon</span>
                                                                        </label>
                                                                        <div className="error">{this.state.error_message.clinic_name}</div>
                                                                    </div>
                                                                    <div className={this.state.user_doctor.doctor_detail.slots.includes("Afternoon") ? "slot_afternoon" : "d-none slot_afternoon"}>
                                                                        <div className="input-field col s12">
                                                                            <input type="text" name="slot_capacity_afternoon" id="slot_capacity_afternoon" defaultValue={typeof this.state.user_doctor.doctor_detail.slot_capacity['Afternoon'] != "undefined" ? this.state.user_doctor.doctor_detail.slot_capacity['Afternoon'] : 0} className="validate" />
                                                                            <label className="active">Slot Capacity</label>
                                                                            <div className="error">{this.state.error_message.slot_capacity_afternoon}</div>
                                                                        </div>
                                                                        <div className="input-field col s6">

                                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.slots.includes("Afternoon")?this.state.user_doctor.doctor_detail.slot_timing['Afternoon'].split('-')[0]:""} name="afternoon_timing_1" id="afternoon_timing_1" className="timepicker validate" />
                                                                            <label className="active" htmlFor="afternoon_timing_1">Opening Time</label>
                                                                            <div className="error">{this.state.error_message.afternoon_timing_1}</div>
                                                                        </div>
                                                                        <div className="input-field col s6">

                                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.slots.includes("Afternoon")?this.state.user_doctor.doctor_detail.slot_timing['Afternoon'].split('-')[1]:""} name="afternoon_timing_2" id="afternoon_timing_2" className="timepicker validate" />
                                                                            <label className="active" htmlFor="afternoon_timing_2">Closing Time</label>
                                                                            <div className="error">{this.state.error_message.afternoon_timing_2}</div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="input-field col s12 mb-40">
                                                                        <label>
                                                                            <input type="checkbox" name="slots_evening" id="slots_evening" onChange={(e) => { this._checkSlotChange(e) }} className="filled-in slot_select" defaultChecked={this.state.user_doctor.doctor_detail.slots.includes("Evening") ? "checked" : ""} defaultValue={"Evening"} />
                                                                            <span>Evening</span>
                                                                        </label>
                                                                        <div className="error">{this.state.error_message.clinic_name}</div>
                                                                    </div>
                                                                    <div className={this.state.user_doctor.doctor_detail.slots.includes("Evening") ? "slot_evening" : "d-none slot_evening"}>
                                                                        <div className="input-field col s12">
                                                                            <input type="text" name="slot_capacity_evening" id="slot_capacity_evening" defaultValue={typeof this.state.user_doctor.doctor_detail.slot_capacity['Evening'] != "undefined" ? this.state.user_doctor.doctor_detail.slot_capacity['Evening'] : 0} className="validate" />
                                                                            <label className="active">Slot Capacity</label>
                                                                            <div className="error">{this.state.error_message.slot_capacity_evening}</div>
                                                                        </div>
                                                                        <div className="input-field col s6">

                                                                            <input placeholder="" type="text"  defaultValue={this.state.user_doctor.doctor_detail.slots.includes("Evening")?this.state.user_doctor.doctor_detail.slot_timing['Evening'].split('-')[0]:""} name="evening_timing_1" id="evening_timing_1" className="timepicker validate" />
                                                                            <label className="active" htmlFor="evening_timing_1">Opening Time</label>
                                                                            <div className="error">{this.state.error_message.evening_timing_1}</div>
                                                                        </div>
                                                                        <div className="input-field col s6">

                                                                            <input placeholder="" type="text" defaultValue={this.state.user_doctor.doctor_detail.slots.includes("Evening")?this.state.user_doctor.doctor_detail.slot_timing['Evening'].split('-')[1]:""} name="evening_timing_2" id="evening_timing_2" className="timepicker validate" />
                                                                            <label className="active" htmlFor="evening_timing_2">Closing Time</label>
                                                                            <div className="error">{this.state.error_message.evening_timing_2}</div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col s12">
                                                                        <button type="submit" className="btn btn-primary continuebtn">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                    </div>

                                                </div>

                                            </div>
                                            :
                                            <div className="col s12 center-align">
                                                <img src="images/not-found.gif" alt="" className="circle responsive-img" />
                                            </div>
                                    }


                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                
                <DoctorsMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        )
    }
}

export default withNavigateHook(DoctorHospitalTiming)
