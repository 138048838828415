/* eslint-disable */
import React, { Component } from 'react';
// import { _initUser ,_getBusRoutes } from './api/Api';
import { _showOverlay, _hideOverlay, _showConInfo, _getLoginStatus, _validateLogin, _rememberMe, _donotRememberMe, _browserLogin, time_out, login_r, _getToken, _userType, _getUserType, _getLocalLanguage } from './includes/Constants';
import {
    Link,
} from "react-router-dom";
import withNavigateHook from './includes/withNavigateHook';
import { _loginUser, _updateNotificationToken } from './api/Common';
import Toast from './includes/Toast';
import Loader from './includes/Loader';
import Copyright from './includes/Copyright';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import WelcomeModal from './includes/WelcomeModal';
const language= _getLocalLanguage();
let $ = window.$;
let deferredPrompt;

class Home extends Component {

    constructor(props) {

        super(props);
        // state declaration
        this.state = {
            data: {},
            fingerprint: '',
            connection: navigator.onLine ? "online" : "offline",
            setInstallable: this._isInstalled() == true ? false : true,
            remember_me: false,
            phone_number: '',
            password: '',
            message: '',
            type: '',
            visibility: 'hide',
            error_message: {
                phone_number: '',
                password: '',
                login_error: '',
                p_phone_number: '',
                p_password: '',
                p_login_error: ''
            },
            loader: "hide",
            default_user: _getUserType(),
            text_language:language.lang == 'en'?lang_en:lang_hi
        }

    }


    /**
     * Function to check if the app is installed / works on the PWA version....
     * @returns 
     */
    _isInstalled() {
        // For iOS
        if (window.navigator.standalone) return true

        // For Android
        if (window.matchMedia('(display-mode: standalone)').matches) return true

        // If neither is true, it's not installed
        return false
    }


    /**
     * 
     */
    componentDidMount = async () => {
        //alert(this.state.visibility);
        /*****************************************************/
        //function to define and declare listeners
        await this._addListeners();
        /*****************************************************/
        $('.tabs').tabs();
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Patient') {
                this.props.navigation('/patient-selection');
            }
            if (res['data']['user_type'] == 'Doctor') {
                this.props.navigation('/doctor-list');
            }
        }

    }





    /**
     * add listener for the interent connectivity and install prompt
     */
    _addListeners = () => {
        // let deferredPrompt;
        //console.log(deferredPrompt);
        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // Update UI notify the user they can install the PWA
            this.setState({
                setInstallable: true
            })

            // setInstallable(true);
        });

        window.addEventListener('appinstalled', () => {
            // Log install to analytics
            console.log('INSTALL: Success');
        });
    }

    /**
    *  Function to install the app
    * @param {*} e 
    */
    _handleInstallClick = (e) => {

        // Hide the app provided install promotion
        this.setState({
            setInstallable: false
        })
        //setInstallable(false);
        // Show the install prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
                deferredPrompt = null;
            } else {
                console.log('User dismissed the install prompt');
            }
        });

    }





    /**
     *  Function to install the app
     * @param {*} e 
     */


    /**
     *  Function to redirect to specific url
     * @param {*} url 
     */
    _redirect = (url) => {
        //alert('dd')
        this.props.history.push({ pathname: url, state: { "page": url } });
    }

    /**
       * function to show the infow window for the internet connectivity
       */
    _showConInfo = () => {
        _showConInfo();
    }
    /********************************************************************************************************/
    /**
    * 
    * @param {*} e 
    */
    async _handleLogin(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        let remember_me = data.get('rememberme');
        let res = await _validateLogin(data);
        //  console.log(res);
        if (res.status) {
            await this.setState({
                loader: "show"
            });
            let phone_number = data.get('phone_number');
            let password = data.get('password');
            if (remember_me == 'on') {
                let rem = await _rememberMe(phone_number, password);
                this._loginUser(phone_number, password);
            } else {
                let rem = await _donotRememberMe();
                this._loginUser(phone_number, password);
            }
        } else {
            this.setState({
                error_message: {
                    phone_number: res.error.error_phone_number,
                    password: res.error.error_pass,
                    login_error: ''
                }
            });
        }


    }

    /**
     * 
     * @param {*} phone_number 
     * @param {*} password 
     */
    async _loginUser(phone_number, password) {

        //_showOverlay();
        await _userType('doctor');
        let res = await _loginUser(phone_number, password, 'doctor');
        //console.log(res);
        if (res.data.response == 'success') {
            let data = res.data.result;
            this.setState({
                error_message: {
                    phone_number: '',
                    password: '',
                },
                message: res.data.message,
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });
           
            let token = await _getToken();
            if (typeof token != 'undefined' && token != null && token['status'] == true) {
                await _updateNotificationToken(token['token'], data.id, 'Doctor', 'login')
            }

            if (window.localStorage) {
                document.cookie = "user="+JSON.stringify(data)+"; expires=Tue, 19 Jan 2038 04:14:07 GMT;path=/;SameSite=Lax";
                
            }
            setTimeout(() => {
                
                return (
                    this.props.navigation("/doctor-list")
                )
               

            }, login_r);

        } else {
            this.setState({
                error_message: {
                    phone_number: '',
                    password: '',
                },
                message: res.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            setTimeout(() => {
                this.setState({
                    error_message: {
                        p_phone_number: '',
                        p_password: '',
                    },
                    message: '',
                    type: '',
                    visibility: 'hide',
                });
            }, login_r);
        }
       
        // user_data=res.data.result;
        // console.log(res);
    }
    /********************************************************************************************************/
    /**
     * 
     * @param {*} e 
     */
    async _handlePatientLogin(e) {
        e.preventDefault();
        const data = new FormData(e.target);

        let remember_me = data.get('rememberme');


        let res = await _validateLogin(data);
        //  console.log(res);
        if (res.status) {
            let phone_number = data.get('phone_number');
            let password = data.get('password');
            if (remember_me == 'on') {
                let rem = await _rememberMe(phone_number, password);
                this._loginPatientUser(phone_number, password);
            } else {
                let rem = await _donotRememberMe();
                this._loginPatientUser(phone_number, password);
            }
        } else {
            this.setState({
                error_message: {
                    p_phone_number: res.error.error_phone_number,
                    p_password: res.error.error_pass,
                    p_login_error: ''
                }
            });
        }


    }

    /**
     * 
     * @param {*} phone_number 
     * @param {*} password 
     */
    async _loginPatientUser(phone_number, password) {
        await _userType('patient');
        await this.setState({
            loader: "show"
        })
        // _showOverlay();
        let res = await _loginUser(phone_number, password, 'patient');
        //console.log(res);
        if (res.data.response == 'success') {
            let data = res.data.result;
           
            this.setState({
                error_message: {
                    p_phone_number: '',
                    p_password: '',
                },
                message: res.data.message,
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });
            let token = await _getToken();
            if (typeof token != 'undefined' && token != null && token['status'] == true) {
                await _updateNotificationToken(token['token'], data.id, 'Patient', 'login')
            }
            if (window.localStorage) {
                 document.cookie = "user="+JSON.stringify(data)+"; expires=Tue, 19 Jan 2038 04:14:07 GMT;path=/;SameSite=Lax";
             
            }
            setTimeout(() => {
                return (
                    this.props.navigation("/patient-selection")
                )
            }, login_r);

        } else {
            this.setState({
                error_message: {
                    p_phone_number: '',
                    p_password: '',
                },
                message: res.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            setTimeout(() => {
                this.setState({
                    error_message: {
                        p_phone_number: '',
                        p_password: '',
                    },
                    message: '',
                    type: '',
                    visibility: 'hide',
                });
            }, login_r);
        }
     
    }






    /**
     * 
     * @returns 
     */
    render() {
         return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <main role="main">

                    <section id="" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">

                                        <div className="white-bg center-align commonBox">
                                            <div className='home-div'>
                                                <div className="row mb-40">

                                                    <div className="col s12">

                                                        <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" />

                                                        {/* <h1>Welcome Back</h1> */}

                                                    </div>

                                                </div>

                                                {/* <div className="row bd-divider dp-div">

                                                    <div className="col s6">
                                                        <Link className="btn commonBtn" to="/doctors-login">
                                                            <img className="responsive-img w-100" src="images/doctor.png" alt="Doctors" /><br />Doctors
                                                        </Link>
                                                     </div>
 
                                                    <div className="col s6 bd-left">
                                                        <Link className="btn commonBtn" to="/patient-login">
                                                            <img className="responsive-img w-100" src="images/patient.png" alt="Patient" /><br />Patient
                                                        </Link>
                                                      </div>  
                                                    </div> */}

                                                <div className="row s-rem">
                                                    <div className="col s12">
                                                        <ul className="tabs">
                                                            <li className="tab col s6 height-auto">
                                                                <a className={this.state.default_user == 'doctor'?"active":""} href="#test1">
                                                                    <img className="responsive-img w-30" src="images/doctor.png" alt="Doctors" />
                                                                </a>
                                                            </li>
                                                            <li className="tab col s6 height-auto">
                                                                <a className={this.state.default_user == 'patient'?"active":""} href="#test2">
                                                                    <img className="responsive-img w-30" src="images/patient.png" alt="Patient" />
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div id="test1" className="col s12">
                                                        <div className="row">
                                                            <div className="col s12 center-align">
                                                                <h1>{this.state.text_language[0].doctor_portal_text}</h1>
                                                                {/* <button className="googlebtn" type="button">Log in with Google</button>
                                                                <div className="separator-line"><span>Or</span></div> */}
                                                            </div>
                                                            <form onSubmit={(e) => this._handleLogin(e)} method="post" autoComplete='off'>
                                                                <div className="col s12">
                                                                    <div className="input-field">
                                                                        <input type="text" id="phone_number" name="phone_number" className="validate" />
                                                                        <label htmlFor="phone_number">{this.state.text_language[0].mobile_text} {this.state.text_language[0].number_text}</label>
                                                                        <div className="error">{this.state.error_message.phone_number}</div>
                                                                    </div>
                                                                    <div className="input-field">
                                                                        <input type="password" id="password" name="password" className="validate" />
                                                                        <label htmlFor="password">{this.state.text_language[0].password_text}</label>
                                                                        <div className="error">{this.state.error_message.password}</div>
                                                                    </div>
                                                                    <div className="row forgotTxt">
                                                                        <div className="col s6 left-align">
                                                                            <Link to="/doctor-forgot-password">{this.state.text_language[0].forgot_password_text}</Link>
                                                                            {/* <a href="http://oparchee.desicoding.com/forgot-password.html" className="">Forgot Password?</a> */}
                                                                        </div>
                                                                        <div className="col s6 right-align">
                                                                            {/* <Link to="/doctor-login-otp">Login with OTP</Link> */}
                                                                            {/* <a href="http://oparchee.desicoding.com/login-with-otp.html" className="">Login with OTP</a> */}
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary continuebtn"> {this.state.text_language[0].login_text}</button>
                                                                    {/* <Link to="/doctors-dashboard"  className="btn btn-primary continuebtn">Login</Link> */}
                                                                    {/* <a href="http://oparchee.desicoding.com/account.html" className="btn btn-primary continuebtn">Login</a> */}

                                                                    {/* <p className="blackTxt center-align">Don't have an account ? &nbsp;&nbsp;<a style={{color:"red"}} href="http://oparchee.desicoding.com/registration.html">Register</a></p> */}

                                                                </div>
                                                            </form>

                                                        </div>
                                                    </div>
                                                    <div id="test2" className="col s12">
                                                        <div className="row">
                                                            <div className="col s12 center-align">
                                                                <h1>{this.state.text_language[0].patient_portal_text}</h1>
                                                                {/* <button className="googlebtn" type="button">Log in with Google</button>
                                                                <div className="separator-line"><span>Or</span></div> */}
                                                            </div>
                                                            <form onSubmit={(e) => this._handlePatientLogin(e)} method="post" >
                                                                <div className="col s12">
                                                                    <div className="input-field">
                                                                        <input type="text" id="p_phone_number" name="phone_number" className="validate" />
                                                                        <label htmlFor="p_phone_number">{this.state.text_language[0].mobile_text} {this.state.text_language[0].number_text}</label>
                                                                        <div className="error">{this.state.error_message.p_phone_number}</div>
                                                                    </div>
                                                                    <div className="input-field">
                                                                        <input type="password" id="p_password" name="password" className="validate" />
                                                                        <label htmlFor="p_password">{this.state.text_language[0].password_text}</label>
                                                                        <div className="error">{this.state.error_message.p_password}</div>
                                                                    </div>
                                                                    <div className="row forgotTxt">
                                                                        <div className="col s6 left-align">
                                                                            <Link to="/patient-forgot-password">{this.state.text_language[0].forgot_password_text}</Link>
                                                                            {/* <a href="http://oparchee.desicoding.com/forgot-password.html" className="">Forgot Password?</a> */}
                                                                        </div>
                                                                        <div className="col s6 right-align">
                                                                            {/* <Link to="/patient-login-otp">Login with OTP</Link> */}
                                                                            {/* <a href="http://oparchee.desicoding.com/login-with-otp.html" className="">Login with OTP</a> */}
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary continuebtn"> {this.state.text_language[0].login_text}</button>
                                                                    {/* <Link className="btn btn-primary continuebtn" to="/patient-account">Login</Link> */}
                                                                    {/* <a href="http://oparchee.desicoding.com/account.html" className="btn btn-primary continuebtn">Login</a> */}
                                                                    <p className="blackTxt center-align">{this.state.text_language[0].forgot_password_text} &nbsp;&nbsp;<Link style={{ color: "red" }} to="/patient-registration">{this.state.text_language[0].register_text} {this.state.text_language[0].here_text}</Link></p>
                                                                    {/* <p className="blackTxt center-align">Don't have an account ? &nbsp;&nbsp;<a style={{color:"red"}} href="http://oparchee.desicoding.com/registration.html">Register</a></p> */}

                                                                </div>
                                                            </form>


                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {
                                                this.state.setInstallable == true ?
                                                    <div className="row">
                                                        <div className="col s12">
                                                            <div className="card blue-grey lighten-1">
                                                                <div className='row'>
                                                                    <div className="col s12 white-text">
                                                                         <h1>{this.state.text_language[0].seamless_experience_text}</h1>
                                                                         <h6>{this.state.text_language[0].download_our_app_text}</h6>
                                                                    </div>
                                                                    <div className="col s12">
                                                                        <div className="card-content white-text">
                                                                            <div onClick={() => { this._handleInstallClick() }} className="install-download" title="Install App">
                                                                                <a className="btn pulse black-text white" >
                                                                                {this.state.text_language[0].download_text}

                                                                                </a>
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>


                                                    :
                                                    ''
                                            }
                                            <br/>

                                            <Copyright />
                                        </div>

                                    </div>

                                </div>




                            </div>

                        </div>



                    </section>
                    
                    <WelcomeModal  message={this.state.message} type={this.state.type} visibility={this.state.visibility}/>
              </main>
            </React.Fragment>
        )


    }

}

export default withNavigateHook(Home);
