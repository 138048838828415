import React, { Component } from 'react'
import {
    Link,
} from "react-router-dom";
import { _getLocalLanguage, _getLoginStatus } from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import PatientPageHeader from './includes/PatientPageHeader';
import PatientMenu from './includes/PatientMenu';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
const language = _getLocalLanguage();

let $ = window.$;
 class Emergency extends Component {
   /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            data: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi,
        }
        // console.log(this.props.location);
    }

    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        $('.tabs').tabs();
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Patient') {
                 
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    render() {
        return (
            <React.Fragment>
                <PatientPageHeader />
                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">

                                        <div className="white-bg center-align commonBoxInner">
                                            <div className="row">
                                                <div className="col s12 center-align ">
                                                    <h1>{this.state.text_language[0].emergency_text}</h1>
                                                </div>

                                                <div className="col s12 center-align ">
                                                    <img className="responsive-img" src="images/medical-emergency-banner.png" alt="Medical Emergency" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col s6'>
                                                    <div className="card ">
                                                        <div className="row  mb-10">
                                                            <div className="col s12">
                                                                <Link to="/patient-emergency">
                                                                    <div>
                                                                        <h6>
                                                                            {this.state.text_language[0].non_trauma_emergency_text+' '+this.state.text_language[0].booking_text}
                                                                        </h6>
                                                                        <img className="responsive-img w-50" src="images/non-trauma-emergency.png" alt="Non Trauma Emergency" />
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col s6'>
                                                    <div className="card ">
                                                        <div className="row  mb-10">
                                                            <div className="col s12">
                                                                <Link to="/patient-emergency">
                                                                    <div>
                                                                        <h6>
                                                                            {this.state.text_language[0].trauma_emergency_text+' '+this.state.text_language[0].booking_text}
                                                                        </h6>
                                                                        <img className="responsive-img w-50" src="images/trauma-emergency.png" alt="Trauma Emergency" />
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="card blue-grey darken-1 ">
                                                        <div className='row p-20 white-text'>
                                                            <div className="col s12">
                                                                <img className="responsive-img w-100" src="images/call-center-service.png" alt="Call Center" />
                                                            </div>
                                                            <div className="col s12">
                                                                <p>
                                                                    {this.state.text_language[0].emergency_order_confirmation_call_text}
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <PatientMenu />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(Emergency)
