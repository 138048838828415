import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import PatientMenu from './includes/PatientMenu';
import PatientHeader from './includes/PatientHeader';
import PatientPageHeader from './includes/PatientPageHeader';
import { _getLoginStatus, refresh_time, _clearInterval, _getLocalLanguage } from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
import { _getLivePatientBookingStatus } from './api/Patient';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
const language = _getLocalLanguage();
let $ = window.$;
class PatientLive extends Component {
    /**
 * 
 * @param {*} props 
 */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi
        }
    }


    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Patient') {
                await this._getLivePatientBookingStatus();
                setTimeout(async () => {
                    await this._getAutoRefresh();
                }, 20000);
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }


    /**
     * 
     */
    _getLivePatientBookingStatus = async () => {

        if (typeof this.state.user.id != "undefined") {
            //_showOverlay();
            this.setState({
                loader: "show"
            })
            let res1 = await _getLivePatientBookingStatus(this.state.user.id);
            // console.log(res1);
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result,
                    loader: "hide"
                });

            } else {
                this.setState({
                    list: [],
                    message: res1.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });

            }
        }
        return;
    }

    /**
  * 
  * @returns 
  */
    _getAutoRefresh = async () => {

        this.set_interval = setInterval(async () => {
            //console.log(this.set_interval);
            await _clearInterval(this.set_interval);
            let res1 = await _getLivePatientBookingStatus(this.state.user.id);
            //console.log(res1)
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result,
                    message: '',
                    type: 'success',
                    visibility: 'hide'
                });
                //console.log(this.state.list)
                //_hideOverlay();
            } else {
                this.setState({
                    list: res1.data.result,
                    message: '',
                    type: 'error',
                    visibility: 'hide'
                });
            }
        }, refresh_time);

        return;
    }

    /**
     * 
     * @returns 
     */
    _getListHtml = () => {
        //console.log(this.state.list)
        if (typeof this.state.list != "undefiend" && typeof Object.keys(this.state.list).length != "undefined" && Object.keys(this.state.list).length > 0) {
            if (typeof this.state.list.live_data != "undefiend" && typeof Object.keys(this.state.list.live_data).length != "undefined" && Object.keys(this.state.list.live_data).length > 0) {

                return (
                    <div className="card white-bg lighten-5 z-depth-1">
                        {/* <div className="white-bg"> */}
                        <h6 >{this.state.text_language[0].date_text} : {this.state.list.booking_date}</h6>

                        {
                            Object.values(this.state.list.live_data).map((value, index) => {

                                return (
                                    <React.Fragment>
                                    <p>{this.state.text_language[0].appointment_text} : Dr. {Object.keys(this.state.list.live_data)[index]}</p>
                                    <p>{this.state.text_language[0].slot_text} : {Object.values(this.state.list.slot)[index]}</p>
                                    <p>{this.state.text_language[0].token_num_text} : {Object.values(this.state.list.booking_number)[index]}</p>
                                    <ul className="consultingtList">
                                        
                                        {
                                            Object.values(value).map((value1, index1) => {
                                                return (
                                                    <li key={"list-" + index1}><i className="material-icons">check_box</i> 
                                                        {
                                                                    value1.token == "Pending"
                                                                        ? ' '+this.state.text_language[0].token_num_text +' ' + value1.booking_number+' ' + this.state.text_language[0].has_not_started_text
                                                                        :
                                                                        (value1.token == "Live"
                                                                            ?' '+ this.state.text_language[0].token_num_text +' ' + value1.booking_number +' ' + this.state.text_language[0].is_consult_text
                                                                            :' '+ this.state.text_language[0].token_num_text+' ' + value1.booking_number+' ' + this.state.text_language[0].has_done_consult_text
                                                                        )
        
                                                    }

                                                    </li>
                                                )
                                            })

                                        }
                                    </ul>
                                    </React.Fragment>
                                )
                            })
                        }


                        {/* </div> */}
                    </div>
                )
            } else {
                return (
                    <div className="col s12 center-align mt-20">
                        <img src="images/not-found.gif" alt="" className="circle responsive-img" />
                    </div>
                )
            }
        } else {
            return (
                <div className="col s12 center-align mt-20">
                    <img src="images/not-found.gif" alt="" className="circle responsive-img" />
                </div>
            )

        }
    }


    render() {
        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <PatientPageHeader />
                <main role="main">

                    <section id="dashboard-page" className="oparcheecommonCode">

                        <div className="dashboardContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">

                                        {/* <img className="responsive-img" src="images/banner2.jpg" alt="" />

                                        <a href="" className="upgradebtn"><span>Upgrade to Oparchee Plus</span> <i className="material-icons">arrow_forward</i></a> */}

                                        <div className="locationName center-align">

                                            {/* <img className="responsive-img" src="images/stethoscope.png" alt="" />  */}
                                            {/* <strong>Doctors in Mumbai</strong> */}

                                        </div>

                                    </div>

                                    <div className="col s12">
                                        {this._getListHtml()}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <PatientMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientLive);
