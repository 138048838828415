import React, { Component } from 'react'
import axios from 'axios';
import { API_URL } from '../includes/Constants';
 
 
const headers = {
    'Content-Type':'application/json'
    // 'Authorization': 'Bearer '+token, 
    
};

/**
 * 
 * @param {*} patient_id 
 * @returns 
 */
export const _getAllDoctor =  async(patient_id,city) =>{
    const url = `${API_URL}get-all-doctors.json`;
    let formData = {patient_id:patient_id,city:city};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 * @param {*} patient_id 
 * @param {*} city 
 * @returns 
 */
export const _getGovernmentHospitals =  async(patient_id,city) =>{
    const url = `${API_URL}get-government-hospitals.json`;
    let formData = {patient_id:patient_id,city:city};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}



/**
 * 
 * @param {*} patient_id 
 * @param {*} doctor_id 
 * @returns 
 */
export const _getSingleDoctor =  async(patient_id,doctor_id) =>{
    const url = `${API_URL}get-single-doctor.json`;
    let formData = {patient_id:patient_id,doctor_id:doctor_id};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}


/**
 * 
 * @param {*} doctor_id 
 * @returns 
 */
export const _getDoctorDetails =  async(doctor_id) =>{
    const url = `${API_URL}get-doctor-details.json`;
    let formData = {doctor_id:doctor_id};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 * @param {*} name 
 * @param {*} age 
 * @param {*} problem 
 * @param {*} address 
 * @param {*} type 
 * @param {*} doctor_id 
 * @param {*} slot 
 * @param {*} priscription_image 
 * @param {*} patient_id 
 * @returns 
 */
export const _addBooking =  async(name,age,problem,address,type,doctor_id,slot,priscription_image,patient_id,phone_number) =>{
    const url = `${API_URL}add-booking.json`;
    let formData = {name : name,age:age,problem:problem,address:address,type:type,doctor_id:doctor_id,slot:slot,priscription_image:priscription_image,patient_id:patient_id,phone_number:phone_number};
    return axios({
        url: url,
        method : 'POST',
        headers: {
            'Content-Type':'multipart/form-data'
        },
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

export const _addGovBooking =  async(name,age,problem,address,type,doctor_id,slot,priscription_image,patient_id,phone_number,booking_date,booking_amount) =>{
    const url = `${API_URL}add-gov-booking.json`;
    let formData = {name : name,age:age,problem:problem,address:address,type:type,doctor_id:doctor_id,slot:slot,priscription_image:priscription_image,patient_id:patient_id,
        phone_number:phone_number,booking_date:booking_date,booking_amount:booking_amount};
    return axios({
        url: url,
        method : 'POST',
        headers: {
            'Content-Type':'multipart/form-data'
        },
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 * @param {*} booking_date 
 * @param {*} booking_id 
 * @param {*} slot 
 * @returns 
 */
export const _slotConfirmation =  async(booking_date,booking_id,slot,type) =>{
    const url = `${API_URL}slot-confirmation.json`;
    let formData = {booking_date:booking_date,booking_id:booking_id,slot:slot,type:type};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}


/**
 * 
 * @param {*} name 
 * @param {*} age 
 * @param {*} problem 
 * @param {*} address 
 * @param {*} priscription_image 
 * @param {*} patient_id 
 * @param {*} phone_number 
 * @param {*} booking_date 
 * @returns 
 */
export const _addOtherBooking =  async(name,age,problem,address,priscription_image,patient_id,phone_number,booking_date,doctor_name,booking_amount,patient_address,city) =>{
    const url = `${API_URL}add-other-booking.json`;
    let formData = {name : name,age:age,problem:problem,address:address,priscription_image:priscription_image,patient_id:patient_id,phone_number:phone_number,booking_date:booking_date,doctor_name:doctor_name,booking_amount:booking_amount,patient_address:patient_address,city:city};
    return axios({
        url: url,
        method : 'POST',
        headers: {
            'Content-Type':'multipart/form-data'
        },
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}


export const _addLabTestBooking =  async(name,age,test_name,priscription_image,patient_id,phone_number,booking_date,booking_amount,patient_address,city) =>{
    const url = `${API_URL}add-lab-test-booking.json`;
    let formData = {name : name,age:age,test_name:test_name,priscription_image:priscription_image,patient_id:patient_id,phone_number:phone_number,booking_date:booking_date,booking_amount:booking_amount,patient_address:patient_address,city:city};
    return axios({
        url: url,
        method : 'POST',
        headers: {
            'Content-Type':'multipart/form-data'
        },
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

export const _addMedicalDelivery =  async(name, address, priscription_image,patient_id, phone_number, delivery_date, city, delivery_charges, delivery_type) =>{
    const url = `${API_URL}add-medical-delivery.json`;
    let formData = {name:name,address:address,patient_id:patient_id,phone_number:phone_number,delivery_date:delivery_date,delivery_charges:delivery_charges,delivery_type:delivery_type,city:city,priscription_image:priscription_image};
    return axios({
        url: url,
        method : 'POST',
        headers: {
            'Content-Type':'multipart/form-data'
        },
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 * @param {*} patient_id 
 * @returns 
 */
export const _getMedicalDeliveryHistory =  async(patient_id) =>{
    const url = `${API_URL}get-medical-delivery-history.json`;
    let formData = {patient_id:patient_id};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 * @param {*} patient_id 
 * @returns 
 */
export const _getAgentAnalytics =  async(patient_id,from,to) =>{
    
    const url = `${API_URL}get-agent-analytics.json`;
    let formData = {agent_id:patient_id,from:from,to:to};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}



/**
 * 
 * @param {*} doctor_id 
 * @returns 
 */
export const _getDoctorSchedule =  async(doctor_id,date,slot) =>{
    const url = `${API_URL}get-doctor-schedule.json`;
    let formData = {doctor_id:doctor_id,date:date,slot:slot};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}



/**
 * 
 * @param {*} patient_id 
 * @returns 
 */
export const _getPatientHistory =  async(patient_id,date) =>{
    const url = `${API_URL}get-patient-history.json`;
    let formData = {patient_id:patient_id,date:date};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 * @param {*} patient_id 
 * @returns 
 */
export const _getLivePatientBookingStatus =  async(patient_id) =>{
    const url = `${API_URL}get-live-patient-booking-status.json`;
    let formData = {patient_id:patient_id};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

export const _editPatientProfile=  async(full_name, phone_number, email,age,address,patient_id) =>{
    const url = `${API_URL}edit-patient-profile.json`;
    let formData = {full_name : full_name,phone_number:phone_number,email:email,age:age,address:address,patient_id:patient_id};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}

/**
 * 
 * @param {*} city 
 * @returns 
 */
export const _getUnregisterDoctorList = async (city) => {
    const url = `${API_URL}get-unregister-doctor-list-array.json`;
    let formData = { city: city };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
       data: formData
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

/**
 * 
 * @param {*} city 
 * @returns 
 */
export const _getDepartmentDoctors = async (patient_id,hospital_id,department_id) => {
    const url = `${API_URL}get-department-doctors.json`;
    let formData = {patient_id:patient_id, hospital_id: hospital_id,department_id:department_id };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
       data: formData
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

/**
 * 
 * @param {*} doctor_id 
 * @returns 
 */
export const _getUpdatedCharges =  async(id,type) =>{
    const url = `${API_URL}get-updated-charges.json`;
    let formData = {id:id,type:type};
    return axios({
        url: url,
        method : 'POST',
        headers: headers,
        crossDomain:true,
        data:formData,
    }).then(function(response){
        return response;
    }).catch(function(error){
        return error.response;
    });
       
}





  
