import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import { _updateNotificationToken } from '../api/Common';
import { _logOut, _clearAllInterval, _getLoginStatus, _getLocalLanguage, _languageSelection } from './Constants';
import PatientsSideMenu from './PatientsSideMenu';
import withNavigateHook from './withNavigateHook';
import { lang_en } from './language/en';
import { lang_hi } from './language/hi';
let $ = window.$;
const language = _getLocalLanguage();
class PatientPageHeader extends Component {

    /**
    * 
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        this.action = window.location.pathname.split("/").pop();
        this.state = {
            user: {},
            language: language.lang,
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            language_list: [{ 'en': "English", 'hi': 'Hindi' }],
        }

    }

    /**
     * 
     */
    componentDidMount = async () => {
        $('.dropdown-trigger').dropdown();
        await this.setState({
            language_list:[{'en':this.state.text_language[0].english_text, 'hi':this.state.text_language[0].hindi_text}],
        })

        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
        }
        await _clearAllInterval();
    }

    /**
     * handle language change of the app
     */
    _handleLanguageChange = async (e, language) => {
        e.preventDefault();
        await _languageSelection(language);
        window.location.reload();
        this.setState({
            language: language
        });


    }


    /**
     * 
     * @param {*} param 
     */
    _handleLogout = async (e) => {
        e.preventDefault();
        await _updateNotificationToken('', this.state.user.id, 'Patient', 'logout')
        let res = await _logOut('Patient');
        this.props.navigation(res);
    }

    /**
     * 
     * @param {*} e 
     */
    _goBack = (e) => {
        e.preventDefault();
        this.props.navigation(-1)
    }

    /**
     * 
     */
    _closeSideNav = () => {
        $('.sidenav').sidenav('close')
    }

    render() {
        // alert(this.state.language)
        // alert(this.state.language_list[0][this.state.language])
        return (

            <React.Fragment>
                {
                    this._closeSideNav()
                }
                <header>

                    <div className="oparchee-bg1">

                        <div className="container">

                            <div className="row">
                                <div className="col s6">

                                    <div className="loginTxt left-align">

                                        {
                                            this.action != "patient-selection" && this.action != 'patient-account' && this.action != 'patient-live' &&
                                                this.action != 'patient-history' ?
                                                <React.Fragment>
                                                    <Link to="/" onClick={(e) => { this._goBack(e) }}> <i className="material-icons">arrow_back</i>  </Link>
                                                    {/* {this.action.replaceAll('-', ' ').replaceAll('patient', '').toUpperCase()} */}
                                                </React.Fragment>
                                                :
                                                <a href="#!" data-target="slide-out" className="button-collapse sidenav-trigger show-on-large"><i className="material-icons">menu</i></a>
                                        }

                                    </div>

                                </div>

                                <div className="col s6">
                                    <div className='row'>
                                        {/* <div className='col s4'>
                                        </div> */}
                                        <div className={this.action == 'patient-dashboard' ? 'col s12' : 'col s4'}>

                                            {/* <div className="skipTxt right-align">
                                                <Link to="/patient-selection" ><i className="material-icons" title="Home">home</i></Link>
                                             </div> */}
                                        </div>
                                        <div className={this.action == 'patient-dashboard' ? 'd-none' : 'col s8'}>
                                            <div className="selectLocationDiv right-align">
                                                <a className='dropdown-trigger' href='#' data-target='dropdown1'> <strong>{this.state.language_list[0][this.state.language]}</strong> <i className="material-icons">arrow_drop_down</i></a>
                                                <ul id='dropdown1' className='dropdown-content'>
                                                    <li className={this.state.language == 'en' ? "active" : ""}><Link to="/" onClick={(e) => { this._handleLanguageChange(e, 'en') }}>{this.state.text_language[0].english_text}</Link></li>
                                                    <li className={this.state.language == 'hi' ? "active" : ""}><Link to="/" onClick={(e) => { this._handleLanguageChange(e, 'hi') }}>{this.state.text_language[0].hindi_text}</Link></li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </header>
                <PatientsSideMenu user={this.state.user} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientPageHeader);
