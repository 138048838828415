import React, { Component } from 'react'
import {
    Link,
} from "react-router-dom";
import PatientPageHeader from './includes/PatientPageHeader';
import PatientMenu from './includes/PatientMenu';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import { _getLocalLanguage, _getLocationCookie } from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
const language = _getLocalLanguage();

class GovHospitalDeopartment extends Component {
    /**
   * 
   * @param {*} props 
   */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            data: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            selected_location: _getLocationCookie(),
            hospital_list: {}
        }
     }
    /**
     * 
     * @returns 
     */
    render() {

        return (
            <React.Fragment>
                <PatientPageHeader />
                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">

                                        <div className="white-bg center-align commonBoxInner">
                                            <div className="row">
                                                <div className="col s12 center-align ">
                                                    <h1>{this.props.location.state.name}</h1>

                                                    <h6>{this.state.text_language[0].medical_text} {this.state.text_language[0].department_text}</h6>
                                                </div>

                                                <div className="col s12 center-align ">
                                                    {/* <img className="responsive-img" src="images/medical-emergency-banner.png" alt="Medical Emergency" /> */}
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn" to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':38}} >
                                                       
                                                            <img className="responsive-img h-50" src="images/eye.png" alt="Labs" /><br />{this.state.text_language[0].ophthalmology_text}
                                                         
                                                    </Link>

                                                </div>

                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn" to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':18}}>
                                                        {/* <button > */}
                                                            <img className="responsive-img h-50" src="images/cardiology.png" alt={this.state.text_language[0].cardiology_text} /><br />{this.state.text_language[0].cardiology_text}
                                                        {/* </button> */}
                                                    </Link>

                                                </div>

                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn"  to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':12}}>
                                                        {/* <button> */}
                                                            <img className="responsive-img h-50" src="images/physician.png" alt={this.state.text_language[0].general_physician_text} /><br />{this.state.text_language[0].general_physician_text}
                                                        {/* </button> */}
                                                    </Link>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn" to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':22}}>
                                                        {/* <button > */}
                                                            <img className="responsive-img h-50" src="images/surgeon.png" alt={this.state.text_language[0].surgeon_text} /><br />{this.state.text_language[0].surgeon_text}
                                                        {/* </button> */}
                                                    </Link>

                                                </div>
                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn" to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':36}}>
                                                        {/* <button> */}
                                                            <img className="responsive-img h-50" src="images/chest.png" alt={this.state.text_language[0].chest_text} /><br />{this.state.text_language[0].chest_text}
                                                        {/* </button> */}
                                                    </Link>

                                                </div>
                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn" to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':6}} >
                                                        {/* <button > */}
                                                            <img className="responsive-img h-50" src="images/psychiatrist.png" alt={this.state.text_language[0].psychiatrist_text} /><br />{this.state.text_language[0].psychiatrist_text}
                                                        {/* </button> */}
                                                    </Link>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn" to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':37}}>
                                                        {/* <button > */}
                                                            <img className="responsive-img h-50" src="images/dermatology.png" alt={this.state.text_language[0].dermatology_text} /><br />{this.state.text_language[0].dermatology_text}
                                                        {/* </button> */}
                                                    </Link>

                                                </div>
                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn" to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':28}}>
                                                        {/* <button > */}
                                                            <img className="responsive-img h-50" src="images/maternity.png" alt={this.state.text_language[0].medicine_text} /><br />{this.state.text_language[0].medicine_text}
                                                        {/* </button> */}
                                                    </Link>

                                                </div>
                                                <div className="col s4 hoverable">

                                                    <Link className="btn commonBtn" to="/gov-hospital-booking" state={{hospital_id:this.props.location.state.id,'department_id':19}} >
                                                        {/* <button > */}
                                                            <img className="responsive-img h-50" src="images/ent.png" alt={this.state.text_language[0].ent_text} /><br />{this.state.text_language[0].ent_text}
                                                        {/* </button> */}
                                                    </Link>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div >

                        </div >

                    </section >

                </main >
            </React.Fragment >
        );
    }
}

export default withNavigateHook(GovHospitalDeopartment);
