import React, { Component } from 'react'
import {
    Link
} from "react-router-dom";
import withNavigateHook from './includes/withNavigateHook';
import { _logOut, _getLoginStatus, _showOverlay, _hideOverlay, FILE_URL, _removeLocalDoctor } from './includes/Constants';
import { _getHospitalDoctorList } from './api/Doctor';
import Toast from './includes/Toast';
import Loader from './includes/Loader';
import DoctorsHeader from './includes/DoctorsHeader';
import DoctorsMenu from './includes/DoctorsMenu';
import HospitalMenu from './includes/HospitalMenu';
import HospitalHeader from './includes/HospitalHeader';
import Welcome from './includes/Welcome';
class DoctorList extends Component {

    /**
 * 
 * @param {*} props 
 */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            medical_degree: [],
            medical_department: [],
            default_list: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide"
        }

    }


    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        let res = await _getLoginStatus();
        await _removeLocalDoctor();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Doctor') {
                await this._getDoctors(res.data);
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
        window.onscroll = function () { myFunction() };

        var searchIcon = document.getElementById("stickySearch");
        var sticky = searchIcon.offsetTop;

        function myFunction() {
            if (window.pageYOffset >= sticky) {
                searchIcon.classList.add("sticky")
            } else {
                searchIcon.classList.remove("sticky");
            }
        }
    }

    /**
     * 
     */
    _getDoctors = async (user) => {

        if (typeof user.id != "undefined" && user.id != null) {
            //_showOverlay();
            await this.setState({
                loader: "show"
            })
            let res1 = await _getHospitalDoctorList(user.id);
            if (res1.data.response == "success") {
                this.setState({
                    list: res1.data.result['doctors'],
                    medical_degree: res1.data.result['medical_degree'],
                    medical_department: res1.data.result['medical_department'],
                    default_list: res1.data.result['doctors']
                });
                await this.setState({
                    loader: "hide"
                })
            } else {
                this.setState({
                    list: [],
                    default_list: [],
                    message: res1.data.error,
                    type: 'error',
                    visibility: 'show'
                });
                await this.setState({
                    loader: "hide"
                })
            }
        }
        return;
    }

    /**
     * 
     */
    _handleClick = async (e, data) => {
        await window.localStorage.setItem('user_doctor', JSON.stringify(data));
        return (
            this.props.navigation("/doctors-dashboard?doctor_id=" + data.id)
        )
    }

    /**
     * 
     * @returns 
     */
    _getListHtml = () => {
        
        if (Object.keys(this.state.list).length > 0) {
            return (
                <div className="card-panel grey lighten-5 pointer" >
                    {
                        Object.values(this.state.list).map((value, index) => {
                            return (

                                <div key={"key-" + index} className={(Object.keys(this.state.list).length) - 1 == index ? "row valign-wrapper doctor-list-p" : "row valign-wrapper doctor-list-p bd-divider"} onClick={(e) => { this._handleClick(e, value) }}>
                                    {/* <div className="col s3"></div> */}
                                    <div className="col s4 center-align">
                                        {
                                            value.image != null && value.image != ""
                                                ?
                                                <img src={FILE_URL + '/' + value.image} alt="" className="circle responsive-img h-75" />
                                                :
                                                <img src="images/img3.png" alt="" className="circle responsive-img h-75" />
                                        }
                                    </div>
                                    {/* <div className="col s3"></div> */}
                                    {/* </div> */}
                                    {/* <div className="row valign-wrapper"> */}

                                    <div className="col s8 left-align">

                                        <span className="black-text">

                                            <h6>Dr {value.first_name + " " + value.last_name}</h6>

                                            <p className="grayTxt">
                                                {typeof value.doctor_detail.specialization_text != null && value.doctor_detail.specialization_text != '' ? <React.Fragment >{value.doctor_detail.specialization_text} &nbsp;</React.Fragment> : ''}
                                            </p>
                                        </span>

                                    </div>
                                    {/* <div className='bd-divider'></div> */}
                                </div>


                            )
                        })
                    }

                </div>
            )
        }
    }

    /**
     * 
     * @param {*} e 
     */
    _handleFilter = (e) => {
        // console.log(e.target.value);
        //console.log(this.state.list)
        let searchStr = e.target.value;
        let arr = this.state.default_list;
        let new_array = [];
        new_array = arr.filter(x => (('Dr ').toLowerCase() + x.first_name.toLowerCase() + ' ' + x.last_name.toLowerCase()).includes(searchStr.toLowerCase()));
        this.setState({
            list: new_array
        });

    }

    render() {

        return (
            <React.Fragment>
                {/* <PatientHeader /> */}
                <Loader visibility={this.state.loader} />
                {/* <Welcome user={this.state.user} welcome_visibility={} /> */}
                {/* <DoctorsHeader /> */}
                <HospitalHeader />
                <main role="main">

                    <section id="dashboard-page" className="oparcheecommonCode">

                        <div className="dashboardContent">

                            <div className="container">
                                <div className="row center-align">
                                    <div className="col s12">
                                        <div className="card orange darken-3">
                                            <div className='row'>
                                                <div className="col s12">
                                                    <img className="responsive-img h-100" src="images/medical-team.png" alt="Medical Team" />

                                                </div>
                                                {/* <div className="col s12">
                                                    <div className="white-text">
                                                      <h1>Emergency and Medical Delivery services will be available soon. &#128515;</h1>

                                                    </div>
                                                </div> */}

                                            </div>


                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col s12">
                                        <div className="card blue-grey darken-1">
                                            <div className="row">
                                                <div className="col s12 ">
                                                    <h2 className="white-text center-align">Welcome {this.state.user.name}</h2>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col s12">
                                        <div className="locationName center-align z-depth-5" id="stickySearch">
                                            <form className="navbar-form" role="search" autoComplete="off">
                                                <div className="input-group" id="searchDiv">
                                                    <input type="text" className="form-control" onKeyUp={(e) => { this._handleFilter(e) }} placeholder="Search..." name="filter-doc" id="filter-doc" />
                                                    <div className="input-group-btn">
                                                        <button className="btn btn-default searchBtn" type="btn">
                                                            <i className="material-icons">search</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                    <div className="col s12">

                                        {this._getListHtml()}



                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <HospitalMenu />
                {/* {
                    this.state.visibility == 'show'
                        ?
                        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                        :
                        ''
                } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        )
    }
}


export default withNavigateHook(DoctorList);
