import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import withNavigateHook from './withNavigateHook';
class HospitalMenu extends Component {

     /**
     * 
     * @param {*} props 
     */
      constructor(props) {
        super(props);
        this.action=window.location.pathname.split("/").pop();
        //console.log(this.action);
    }
    render() {

        return (
            <React.Fragment>
                <footer className="footerSection" id="footer-doctor-dashboard">

                    <div className="container">

                        <div className="row">

                            <div className="col s12">

                                <ul>

                                    <li className={(this.action == 'doctors-dashboard' || this.action == 'pending-booking' || this.action == 'add-booking' || this.action == 'show-patient-details' || this.action == "today-booking" || this.action == "doctor-list")?"active":""}>
                                        <Link to="/doctor-list" title="Home">
                                            <i className="material-icons">calendar_today</i><br />O-Parchee
                                        </Link>
                                    </li>

                                    <li className={this.action == 'hospital-history'?"active":""}>
                                        <Link to="/hospital-history" title="History">
                                            <i className="material-icons">hourglass_full</i><br />History
                                        </Link>
                                    </li>

                                    <li className={this.action == 'hospital-emergency'?"active":""}>
                                        <Link to="/hospital-emergency" title="Emergency">
                                        
                                            <i className="material-icons">add_alert</i><br />Emergency
                                        </Link>
                                    </li>

                                    <li className={this.action == 'hospital-account'|| this.action=='hospital-edit-profile'  || this.action=='hospital-setting'?"active":""}>
                                        <Link to="/hospital-account" title="Account">
                                            <i className="material-icons">person</i><br />Account
                                        </Link>
                                    </li>

                                </ul>

                            </div>

                        </div>

                    </div>

                </footer>
            </React.Fragment>
        );
    }
}

export default withNavigateHook(HospitalMenu);
