import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import PatientMenu from './includes/PatientMenu';
import PatientPageHeader from './includes/PatientPageHeader';
import { _logOut, _getLoginStatus, _showOverlay, _hideOverlay, _validateEmail, _checkNull, _validateNumber,time_out, _getLocalLanguage} from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
import { _editPatientProfile, _getAllDoctor } from './api/Patient';
import Toast from './includes/Toast';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
const language = _getLocalLanguage();

class PatientEditProfile extends Component {
    /**
      * 
      * @param {*} props 
      */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'hide',
            error_message: {
                full_name: '',
                age: '',
                address: '',
                email: '',
                phone_number: ''
            },
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi
        }
    }


    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Patient') {
                //console.log(this.state.user)
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    /**
   * 
   * @param {*} e 
   */
    async _handleEditProfile(e) {
        e.preventDefault();
        await this.setState({
            loader:"show"
        })
        const data = new FormData(e.target);

        let full_name = data.get('full_name');
        let age = data.get('age');
        let email = data.get('email');
        let address = data.get('address');
        let phone_number = data.get('phone_number');
        let res = await this._validateForm(data);
        if (res['status']) {

            this.setState({
                error_message: res.error
               
            });

            let reg = await _editPatientProfile(full_name, phone_number, email,age,address,this.state.user.id);
            if (reg.data.response == 'success') {
                this.setState({
                    message: reg.data.message,
                    type: 'success',
                    visibility: 'show',
                    loader:"hide"
                });
                if (window.localStorage) {
                    await window.localStorage.setItem('user', JSON.stringify(reg.data.result));
                  }
                  setTimeout(() => {
                    this.props.navigation('/patient-account');
                  }, time_out);
                  
                 
            } else {
                this.setState({
                    message: reg.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader:"hide"
                });
               
            }

        } else {
            this.setState({
                error_message: res.error,
                loader:"hide"
            });
           
        }

    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    _validateForm = (data) => {
        let name = data.get('full_name');
        let age = data.get('age');
        let email = data.get('email');
        let address = data.get('address');
        let phone_number = data.get('phone_number');
        let response = [];
        response['status'] = false;
        response['error'] = [];

        if (typeof name != "undefined" && name != undefined && name.trim() != '' &&
            typeof age != "undefined" && age != undefined && age.trim() != '' &&
            typeof email != "undefined" && email != undefined && email.trim() != '' &&
            typeof address != "undefined" && address != null && address.trim() != '' && 
            typeof phone_number != "undefined" && phone_number != null && phone_number.trim() != '') {
                response['error']['age'] = _checkNull(age);
            if (_validateEmail(email)) {
                if (!_validateNumber(age)) {
                    response['error']['age'] = "Please provide a valid age in numbers.";
                } else {
                     if(age <= 0){
                        response['error']['age'] = "Age cannot be 0 or smaller.";
                     }else{
                        response['status'] = true;
                     }
                    
                }
                if (!_validateNumber(phone_number)) {
                    response['error']['phone_number'] = "Please provide a valid phone number in numbers.";
                } else {
                    if(phone_number.toString().length == 10){
                        if(response['error']['age'].trim() == '' || response['error']['age'] == null){
                            response['status'] = true;
                        }
                    }else{
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    }
                   
                
                }

            } else {
                response['error']['email'] = "Please provide a valid Email Address.";
            }

        } else {

            response['error']['name'] = _checkNull(name);
            response['error']['age'] = _checkNull(age);
            response['error']['email'] = _checkNull(email);
            response['error']['address'] = _checkNull(address);
            response['error']['phone_number'] = _checkNull(phone_number);
            if (response['error']['age'].trim() == '' || response['error']['age'] == null) {

                if (_validateNumber(age)) {
                    if(age <= 0){
                        response['error']['age'] = "Age cannot be 0 or smaller.";
                     }
                } else {
                    response['error']['age'] = "Please provide a valid age in numbers.";

                }
            }
            if (response['error']['phone_number'].trim() == '' || response['error']['phone_number'] == null) {

                if (_validateNumber(phone_number)) {
                    if(phone_number.toString().length == 10){
                        //response['status'] = true;
                    }else{
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    }
                } else {
                    response['error']['phone_number'] = "Please provide a valid phone number in numbers.";

                }
            }

            if (response['error']['email'].trim() == '' || response['error']['email'] == null) {

                if (_validateEmail(email)) {
                    //response['status'] = true;
                } else {
                    response['error']['email'] = "Please provide a valid Email Address.";

                }
            }


        }

        return response;
    }

    render() {
        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <PatientPageHeader />
                <main role="main">

                    <section id="profile-editing-option-page" className="oparcheecommonCode">

                        <div className="profile-editing-optionContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg commonBoxInner">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    {/* <img className="responsive-img logoImg" src="images/small-logo.png" alt="Oparchee" /> */}

                                                    <h1>{this.state.text_language[0].edit_text} {this.state.text_language[0].profile_text}</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                <form method="post" onSubmit={(e) => this._handleEditProfile(e)} >

                                                    <div className="col s12">

                                                        <div className="input-field">

                                                            <input type="text" id="full_name" defaultValue={this.state.user.full_name} name="full_name" className="validate" />
                                                            <label htmlFor="full_name" className="active">{this.state.text_language[0].full_name_text}</label>
                                                            <div className="error">{this.state.error_message.full_name}</div>

                                                        </div>
                                                        <div className="input-field">

                                                            <input type="text" id="phone_number" defaultValue={this.state.user.phone_number} name="phone_number" className="validate" />
                                                            <label htmlFor="phone_number" className="active">{this.state.text_language[0].phone_text} {this.state.text_language[0].number_text}</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>

                                                        </div>

                                                        <div className="input-field">

                                                            <input type="email" id="email" defaultValue={this.state.user.email} name="email" className="validate" />
                                                            <label htmlFor="email" className="active">{this.state.text_language[0].email_text}</label>
                                                            <div className="error">{this.state.error_message.email}</div>
                                                        </div>
                                                        <div className="input-field">

                                                            <input type="text" id="age" defaultValue={this.state.user.age} name="age" className="validate" />
                                                            <label htmlFor="age" className="active">{this.state.text_language[0].age_text}</label>
                                                            <div className="error" >{this.state.error_message.age}</div>

                                                        </div>


                                                        <div className="input-field">

                                                            <input type="text" id="address" name="address" defaultValue={this.state.user.address} className="validate" />
                                                            <label htmlFor="address" className="active">{this.state.text_language[0].address_text}</label>
                                                            <div className="error">{this.state.error_message.address}</div>
                                                        </div>

                                                        {/* <div className="input-field">

                                                            <input  type="password" id="password" className="validate" />

                                                            <label htmlFor="password">Password</label>

                                                        </div> */}



                                                        <button type="submit" className="btn btn-primary continuebtn">{this.state.text_language[0].submit_text}</button>

                                                    </div>

                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <PatientMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientEditProfile);
