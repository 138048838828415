import React, { Component } from 'react'

class Welcome extends Component {

     /**
     * 
     * @param {*} props 
     */
      constructor(props) {
        super(props);
       
       
    }

    render() {
        return (
            <div  className={this.props.welcome_visibility == "hide"?"loading hide-Welcome":"loading"}>
                <div className="container">
                    <div className="row">
                        <div className="col s12">
                            <div className="loadingBox">
                                <div className="row">
                                    {/* <div className="col s12"><h5>Heading</h5></div> */}
                                    {/* <div className="col s3">
                                        <div className="preWelcome-wrapper big active">
                                            <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div>
                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col s12 center-align mt-20">
                                        <span >Welcome back {this.state.props.user.id}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Welcome
