import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import { _getLoginStatus } from './includes/Constants';
import DoctorsHeader from './includes/DoctorsHeader';
import DoctorsMenu from './includes/DoctorsMenu';
import HospitalHeader from './includes/HospitalHeader';
import HospitalMenu from './includes/HospitalMenu';
class DoctorsEquipment extends Component {
      /**
     * 
     * @param {*} props 
     */
       constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide"
        }
     }

    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {

        let res = await _getLoginStatus();
        if (res['status']) {
            await this.setState({
                user: res.data
            });
           
            if (res['data']['user_type'] == 'Doctor') {
               
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

    }

    render() {

        return (
            <React.Fragment>
                <HospitalHeader />
                <main role="main">

                    <section id="equipment-page" className="oparcheecommonCode">

                        <div className="equipmentContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">

                                        <div className="equipmentTypeList commonBoxInner">

                                            <ul>

                                                <li>

                                                    <label>

                                                        <input type="checkbox" className="filled-in" defaultChecked="" />

                                                        <span>Equipment 1</span>

                                                    </label>

                                                </li>

                                                <li>

                                                    <label>

                                                        <input type="checkbox" className="filled-in" defaultChecked="" />

                                                        <span>Equipment 2</span>

                                                    </label>

                                                </li>

                                                <li>

                                                    <label>

                                                        <input type="checkbox" className="filled-in" defaultChecked="" />

                                                        <span>Equipment 3</span>

                                                    </label>

                                                </li>

                                                <li>

                                                    <label>

                                                        <input type="checkbox" className="filled-in" defaultChecked="" />

                                                        <span>Equipment 4</span>

                                                    </label>

                                                </li>

                                            </ul>

                                        </div>

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col s12">

                                        <a href="" className="applybtn">Apply</a>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <HospitalMenu />
            </React.Fragment>
        );
    }
}

export default DoctorsEquipment;
