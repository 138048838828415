import React, { Component } from 'react';

import {
  Link
} from "react-router-dom";
import PatientMenu from './includes/PatientMenu';
import PatientHeader from './includes/PatientHeader';
import PatientPageHeader from './includes/PatientPageHeader';
import { _logOut, _getLoginStatus, _showOverlay, _hideOverlay, FILE_URL, login_r, _getLocalLanguage } from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
import { _getAllDoctor } from './api/Patient';
import Toast from './includes/Toast';
import { _updateNotificationToken } from './api/Common';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import LogOutModal from './includes/LogOutModal';
const language = _getLocalLanguage();
class PatientAccount extends Component {
  /**
    * 
    * @param {*} props 
    */
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      list: [],
      message: '',
      type: '',
      visibility: 'hide',
      loader: "hide",
      text_language: language.lang == 'en' ? lang_en : lang_hi
    }
  }


  /**
  * 
  * @returns 
  */
  componentDidMount = async () => {
    let res = await _getLoginStatus();

    if (res['status']) {
      await this.setState({
        user: res.data
      });
      if (res['data']['user_type'] == 'Patient') {
        //await this._getDoctors();
      } else {
        this.props.navigation('/home');
      }

    } else {
      this.props.navigation('/home');
    }

  }

  /**
    * 
    * @param {*} param 
    */
  _handleLogout = async (e) => {
    e.preventDefault();
    await this.setState({
      //message: "Logged out successfully",
      //type: 'error',
      visibility: 'show',
      message: "Logged out successfully",
      type: 'success',
      visibility: 'show',
      loader: "hide"
    });
    await _updateNotificationToken('', this.state.user.id, 'Patient', 'logout')
    let res = await _logOut('Patient');
    setTimeout(() => {
      this.props.navigation(res);
    }, login_r);

  }

  /**
   * 
   * @returns 
   */
  render() {
    return (
      <React.Fragment>
        {/* <PatientHeader /> */}
        <PatientPageHeader />

        <Loader visibility={this.state.loader} />
        <main role="main">
          <section id="account-page" className="oparcheecommonCode">
            <div className="accountContent">
              <section className="profileBox">
                <div className="container">

                  <div className="row">
                    <div className="col s12 negative-margin-bottom">

                      <div className="card">

                        <img className="responsive-img doctorImg" src="images/img3.png" alt="" />

                        <div className="doctorName center-align">{this.state.user.full_name}</div>
                        <div className="row">
                        </div>

                      </div>

                    </div>
                    <div className="col s12 negative-margin-bottom">
                    </div>
                    <div className="col s12 negative-margin-bottom">

                      <ul className="moreList z-depth-2">
                        <li> <h1>{this.state.text_language[0].total_bookings_text}</h1>   </li>
                        <li>
                          <Link to="/total-bookings">
                            <i className="material-icons">archive</i> <strong>{this.state.text_language[0].booking_text}</strong> <span><i className="material-icons">keyboard_arrow_right
                            </i></span>
                          </Link>
                        </li>
                        <li> <h1>{this.state.text_language[0].account_settings_text}</h1>   </li>
                        <li>
                          <Link to="/patient-edit-profile">
                            <i className="material-icons">account_circle</i> <strong>{this.state.text_language[0].edit_text} {this.state.text_language[0].profile_text}</strong> <span><i className="material-icons">keyboard_arrow_right
                            </i></span>
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/patient-edit-profile">
                            <i className="material-icons">settings</i> <strong>Account Settings</strong> <span><i className="material-icons">keyboard_arrow_right
                            </i></span>
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/patient-change-password">
                            <i className="material-icons">lock</i> <strong>{this.state.text_language[0].change_text} {this.state.text_language[0].password_text}</strong> <span><i className="material-icons">keyboard_arrow_right
                            </i></span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/patient-account">
                            <i className="material-icons">help</i> <strong>{this.state.text_language[0]['help_&_support']}</strong> <span>
                              <i className="material-icons">keyboard_arrow_right
                              </i></span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 negative-margin-bottom">
                      <button type="btn" className="btn btn-primary continuebtn log-btn" onClick={(e) => { this._handleLogout(e) }}>{this.state.text_language[0].logout_text}</button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="row pt-20">
              <div className='col s12'>
                <p className='center-align'>{this.state.text_language[0].app_text} {this.state.text_language[0].version_text}: {this.state.text_language[0].v_text}-{window.localStorage.getItem("version")}</p>
              </div>

            </div>
          </section>
        </main>
        {/* {
          this.state.visibility == 'show'
            ?
            <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            :
            ''
        } */}
        {/* <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} /> */}
        <LogOutModal  message={this.state.message} type={this.state.type} visibility={this.state.visibility}/>
            
        <PatientMenu />
      </React.Fragment>
    );
  }
}

export default withNavigateHook(PatientAccount);
