import React, { Component } from 'react';

import {
   Link 
} from "react-router-dom";

class DoctorsVerifyOtp extends Component {
  render() {
    
    return (
        <React.Fragment>
            <main role="main">
                <section id="otp-verification-page" className="oparcheecommonCode">
                    <div className="otp-verificationContent">
                        <div className="container">
                            <div className="row">
                                <div className="col s12 l4 offset-l4">
                                    <div className="white-bg commonBox">
                                        <div className="row">
                                            <div className="col s12 center-align">
                                                <img className="responsive-img logoImg" src="images/logo.png" alt="Oparchee" />
                                                <h1>Enter OTP</h1>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12">
                                                <p>We have sent you an OTP via SMS on</p>
                                                <div className="numberTxt"><a href="">1234567890</a> <a href=""><i className="material-icons edit-icon">edit</i></a></div>
                                            </div>
                                            <div className="col s12">
                                                <form>
                                                    <div className="input-field">
                                                        <input placeholder="Enter OTP" type="text" className="validate" />
                                                        <div className="row">
                                                            <div className="col s7">
                                                                <div className="grayTxt receiveTxt">Didn't receive OTP? <span className="redTxt">Get via call</span></div>
                                                            </div>
                                                            <div className="col s5 right-align">
                                                                <span className="redTxt resendTxt">Resend OTP</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link to="/doctors-dashboard" className="btn btn-primary">Verify Account & Login</Link>
                                                    {/* <button type="submit" className="btn btn-primary">Verify Account</button> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
  }
}

export default DoctorsVerifyOtp;
