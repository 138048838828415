import React, { Component } from 'react'
import axios from 'axios';
import { API_URL } from '../includes/Constants';
const headers = {
    "Content-Type": "application/json"
    // ,
    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    // "Access-Control-Allow-Origin": "*"
};

/**
 * 
 * @param {*} email 
 * @param {*} password 
 */
export const _getPendingBooking = async (doctor_id) => {
    const url = `${API_URL}get-pending-booking.json`;
    let formData = { doctor_id: doctor_id };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}
/**
 * 
 * @param {*} doctor_id 
 * @returns 
 */
export const _getActiveBooking = async (doctor_id) => {
    const url = `${API_URL}get-active-booking.json`;
    let formData = { doctor_id: doctor_id };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}


/**
 * 
 * @param {*} booking_id 
 * @param {*} status 
 * @returns 
 */
export const _confirmBooking = async (booking_id, status) => {
    const url = `${API_URL}booking-confirmation.json`;
    let formData = { booking_id: booking_id, status: status };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

/**
 * 
 * @param {*} booking_id 
 * @param {*} status 
 * @returns 
 */
export const _updateLiveBooking = async (booking_id, token) => {
    const url = `${API_URL}update-live-booking.json`;
    let formData = { booking_id: booking_id, token: token };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

/**
 * 
 * @param {*} name 
 * @param {*} age 
 * @param {*} problem 
 * @param {*} address 
 * @param {*} type 
 * @param {*} doctor_id 
 * @param {*} slot 
 * @returns 
 */
export const _addBooking = async (name, age, problem, address, type, doctor_id, slot, phone_number,parchee_type,booking_date,patient_id) => {
    const url = `${API_URL}add-booking.json`;
    let formData = { name: name, age: age, problem: problem, address: address, type: type, doctor_id: doctor_id, slot: slot, phone_number: phone_number,parchee_type:parchee_type,booking_date:booking_date,patient_id:patient_id};
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
}

/**
 * 
 * @param {*} booking_date 
 * @param {*} booking_id 
 * @returns 
 */
export const _updateBooking = async (booking_date,booking_id) => {
    const url = `${API_URL}update-booking.json`;
    let formData = {booking_date:booking_date,booking_id:booking_id};
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
}


/**
 * 
 * @param {*} patient_id 
 * @param {*} priscription_image 
 * @param {*} booking_id 
 * @returns 
 */
export const _addPriscriptionImage = async (patient_id,priscription_image,booking_id) => {
    const url = `${API_URL}add-priscription-image.json`;
    let formData = {patient_id:patient_id,priscription_image:priscription_image,booking_id};
    return axios({
        url: url,
        method: 'POST',
        headers: {
            'Content-Type':'multipart/form-data'
        },
        crossDomain:true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}



/**
 * 
 * @param {*} first_name 
 * @param {*} last_name 
 * @param {*} phone_number 
 * @param {*} email 
 * @param {*} image 
 * @param {*} doctor_id 
 * @returns 
 */
export const _editDoctorProfile = async (first_name, last_name, phone_number, email, image, doctor_id, type,college,passout_year,specialization_text) => {
    const url = `${API_URL}edit-doctor-profile.json`;
    let formData = { first_name: first_name, last_name: last_name, phone_number: phone_number, email: email, image: image, doctor_id: doctor_id, type: type,college:college,passout_year:passout_year,specialization_text:specialization_text };
    return axios({
        url: url,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

export const _editDoctorClinicDetails = async (clinic_name, city, state, pincode, address, additional_phone, specialization_text, doctor_id,type) => {
    const url = `${API_URL}edit-doctor-clinic-details.json`;
    let formData = { clinic_name: clinic_name, city: city, state: state, pincode: pincode, address: address, additional_phone: additional_phone, specialization_text: specialization_text, doctor_id: doctor_id,type:type };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

/**
 * 
 * @param {*} timing_1 
 * @param {*} timing_2 
 * @param {*} slots_morning 
 * @param {*} slots_afternoon 
 * @param {*} slots_evening 
 * @param {*} morning_timing_1 
 * @param {*} morning_timing_2 
 * @param {*} afternoon_timing_1 
 * @param {*} afternoon_timing_2 
 * @param {*} evening_timing_1 
 * @param {*} evening_timing_2 
 * @param {*} slot_capacity_morning 
 * @param {*} slot_capacity_afternoon 
 * @param {*} slot_capacity_evening 
 * @param {*} doctor_id 
 * @returns 
 */
export const _editDoctorHospitalTiming = async (timing_1, timing_2, slots_morning, slots_afternoon, slots_evening, morning_timing_1, morning_timing_2,
    afternoon_timing_1, afternoon_timing_2, evening_timing_1, evening_timing_2, slot_capacity_morning,
    slot_capacity_afternoon, slot_capacity_evening, doctor_id) => {
    const url = `${API_URL}edit-doctor-hospital-timings.json`;
    let formData = {
        timing_1: timing_1, timing_2: timing_2, slots_morning: slots_morning, slots_afternoon: slots_afternoon, slots_evening: slots_evening, morning_timing_1: morning_timing_1, morning_timing_2: morning_timing_2,
        afternoon_timing_1: afternoon_timing_1, afternoon_timing_2: afternoon_timing_2, evening_timing_1: evening_timing_1, evening_timing_2: evening_timing_2, slot_capacity_morning: slot_capacity_morning,
        slot_capacity_afternoon: slot_capacity_afternoon, slot_capacity_evening: slot_capacity_evening, doctor_id: doctor_id
    };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
}

/**
 * 
 * @param {*} doctor_id 
 * @param {*} date 
 * @returns 
 */
export const _getAllBooking = async (doctor_id, date, type) => {
    const url = `${API_URL}get-all-booking.json`;
    let formData = { doctor_id: doctor_id, date: date, type: type };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

/**
 * 
 * @param {*} hospital_id 
 * @returns 
 */
export const _getHospitalDoctorList = async (hospital_id) => {
    const url = `${API_URL}get-hospital-doctor-list.json`;
    let formData = { hospital_id: hospital_id };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

/**
 * 
 * @param {*} hospital_id 
 * @returns 
 */
export const _getHospitalDoctorListArray = async (hospital_id) => {
    const url = `${API_URL}get-hospital-doctor-list-array.json`;
    let formData = { hospital_id: hospital_id };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}

/**
 * 
 * @param {*} hospital_id 
 * @returns 
 */
export const _getDoctorSlots = async (doctors_id) => {
    const url = `${API_URL}get-doctor-slots.json`;
    let formData = { doctors_id: doctors_id };
    return axios({
        url: url,
        method: 'POST',
        headers: headers,
        crossDomain: true,
        data: formData,
    }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

}


