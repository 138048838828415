import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import PatientHeader from './includes/PatientHeader';
import PatientMenu from './includes/PatientMenu';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
import { _addBooking } from './api/Patient';
import { _getLoginStatus, _checkNull, _validateNumber, time_out, _showOverlay, _hideOverlay, _getLocalLanguage, _checkSpecialChar } from './includes/Constants';
import PatientPageHeader from './includes/PatientPageHeader';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
const language = _getLocalLanguage();
let $ = window.$;
class PatientDetails extends Component {

    /**
    * 
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            message: '',
            type: '',
            visibility: 'hide',
            error_message: {
                name: '',
                age: '',
                problem: '',
                address: '',
                phone_number: ''
            },
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi
        }
    }
    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {
        $('select').formSelect();
        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Patient') {

            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
    }

    /**
     * 
     * @param {*} e 
     */
    async _handleManualBooking(e) {
        e.preventDefault();
        //_showOverlay();
        await this.setState({
            loader: "show"
        })
        const data = new FormData(e.target);

        let name = data.get('name');
        let age = data.get('age');
        let problem = data.get('problem');
        let address = data.get('address');
        let slot = '';
        let priscription_image = data.get('priscription_image');
       // let priscription_image = data.get('priscription_image');
        let phone_number = data.get('phone_number');
        let res = await this._validateBooking(data);
        if (res['status']) {

            this.setState({
                error_message: res.error
            });

            let reg = await _addBooking(name, age, problem, address, 'App', this.props.location.state.doctor_id, "Pending", priscription_image, this.state.user.id, phone_number);
            if (reg.data.response == 'success') {
                this.setState({

                    message: reg.data.message,
                    type: 'success',
                    visibility: 'show',
                    loader: "hide"
                });
                setTimeout(() => {
                    // alert(time_out);
                    return (
                        this.props.navigation("/patient-book-appointment?booking_id=" + reg.data.result.booking_id + "&doctor_id=" + this.props.location.state.doctor_id
                        )
                    )
                }, time_out);
                //_hideOverlay();
            } else {
                this.setState({
                    message: reg.data.error,
                    type: 'error',
                    visibility: 'show',
                    loader: "hide"
                });
                // _hideOverlay();
            }

        } else {
            this.setState({
                error_message: res.error,
                loader: "hide"
            });
            //_hideOverlay();
        }

    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    _validateBooking = (data) => {
        let name = data.get('name');
        let age = data.get('age');
        let problem = data.get('problem');
        let address = data.get('address');
        let phone_number = data.get('phone_number');
        let response = [];
        response['status'] = false;
        response['error'] = [];

        if (typeof name != "undefined" && name != undefined && name.trim() != '' &&
            typeof age != "undefined" && age != undefined && age.trim() != '' &&
            typeof problem != "undefined" && problem != undefined && problem.trim() != '' &&
            typeof address != "undefined" && address != null && address.trim() != '' &&
            typeof phone_number != "undefined" && phone_number != null && phone_number.trim() != ''
        ) {
           
            response['error']['name'] = _checkSpecialChar(name);
            response['error']['problem'] = _checkSpecialChar(problem);
            response['error']['address'] = _checkSpecialChar(address);
            if (response['error']['name'].trim() == '' && response['error']['problem'].trim() == '' && response['error']['address'].trim() == '') {
                if (!_validateNumber(age)) {
                    response['error']['age'] = "Please provide a valid age in numbers.";
                    if (!_validateNumber(phone_number)) {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    } else {
                        if (phone_number.toString().length == 10) {
                            // response['status'] = true;
                        } else {
                            response['error']['phone_number'] = "Please provide a valid phone number.";
                        }

                    }
                } else {
                    if (!_validateNumber(phone_number)) {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    } else {
                        if (phone_number.toString().length == 10) {
                            response['status'] = true;
                        } else {
                            response['error']['phone_number'] = "Please provide a valid phone number.";
                        }

                    }
                    //response['status'] = true;
                }
            }

        } else {

            response['error']['name'] = _checkNull(name);
            response['error']['age'] = _checkNull(age);
            response['error']['problem'] = _checkNull(problem);
            response['error']['address'] = _checkNull(address);
            response['error']['phone_number'] = _checkNull(phone_number);
            if (response['error']['age'].trim() == '' || response['error']['age'] == null) {

                if (_validateNumber(age)) {
                    //response['status'] = true;
                } else {
                    response['error']['age'] = "Please provide a valid age in numbers.";

                }
            }
            if (response['error']['phone_number'].trim() == '' || response['error']['phone_number'] == null) {

                if (_validateNumber(phone_number)) {
                    if (phone_number.toString().length == 10) {
                        //response['status'] = true;
                    } else {
                        response['error']['phone_number'] = "Please provide a valid phone number.";
                    }
                } else {
                    response['error']['phone_number'] = "Please provide a valid phone number";

                }
            }

        }

        return response;
    }

    /**
     * 
     * @param {*} e 
     */
    _handleChange = (e) => {
        if (e.target.value == 'Other') {
            $('#name').val('');
        }
        if (e.target.value == 'Self') {
            $('#name').val(this.state.user.full_name);
        }
    }


    render() {

        return (
            <React.Fragment>
                <Loader visibility={this.state.loader} />
                <PatientPageHeader />
                {/* <PatientHeader /> */}
                <main role="main">

                    <section id="patient_details-page" className="oparcheecommonCode">

                        <div className="patient_detailsContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg commonBoxInner">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    {/* <img className="responsive-img logoImg" src="images/logo.png" alt="Oparchee" /> */}

                                                    <h1>{this.state.text_language[0].patient_text} {this.state.text_language[0].details_text}</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data">

                                                    <div className="col s12">
                                                        <div className="input-field">
                                                            <select name="patient_type" onChange={(e) => { this._handleChange(e) }}>
                                                                <option defaultValue="Self">{this.state.text_language[0].self_text}</option>
                                                                <option value="Other">{this.state.text_language[0].other_text}</option>
                                                            </select>
                                                            <label>{this.state.text_language[0].appointment_text} {this.state.text_language[0].whom_text} {this.state.text_language[0].for_text}</label>
                                                        </div>

                                                        <div className="input-field">

                                                            <input type="text" placeholder={this.state.user.full_name} id="name" defaultValue={this.state.user.full_name} name="name" className="validate" />

                                                            <label htmlFor="name" className="active">{this.state.text_language[0].name_text}</label>
                                                            <div className="error">{this.state.error_message.name}</div>

                                                        </div>
                                                        <div className="input-field">

                                                            <input type="text" placeholder={this.state.user.phone_number} defaultValue={this.state.user.phone_number} id="phone_number" name="phone_number" className="validate" />

                                                            <label htmlFor="phone_number" className="active">{this.state.text_language[0].phone_text} {this.state.text_language[0].number_text}</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>

                                                        </div>

                                                        <div className="input-field">

                                                            <input name="age" type="text" id="age" className="validate" />

                                                            <label htmlFor="age">{this.state.text_language[0].age_text}</label>
                                                            <div className="error">{this.state.error_message.age}</div>
                                                        </div>

                                                        <div className="input-field">

                                                            <input name="problem" type="text" id="problem" className="validate" />

                                                            <label htmlFor="problem">{this.state.text_language[0].problem_text}</label>
                                                            <div className="error">{this.state.error_message.problem}</div>
                                                        </div>
                                                        <div className="input-field">

                                                            <textarea id="textarea1" name="address" className="materialize-textarea"></textarea>

                                                            <label htmlFor="textarea1">{this.state.text_language[0].address_text}</label>
                                                            <div className="error">{this.state.error_message.address}</div>
                                                        </div>
                                                        <h6 className="grayTxt d-none">{this.state.text_language[0].prescription_text} {this.state.text_language[0].image_text}</h6>

                                                        <div className="input-field d-none" >

                                                            <div className="file-field input-field">

                                                                <div className="btn">

                                                                    <span><i className="material-icons">image</i></span>

                                                                    <input type="file" name="priscription_image" accept="image/*" />

                                                                </div>

                                                                <div className="file-path-wrapper">

                                                                    <input className="file-path validate" type="text" placeholder={this.state.text_language[0].upload_multiple_text} />

                                                                </div>

                                                            </div>

                                                        </div>
                                                        {/* <Link to="/patient-book-appointment" className="btn btn-primary continuebtn">Submit</Link> */}
                                                        <button type="submit" className="btn btn-primary continuebtn">{this.state.text_language[0].submit_text}</button>

                                                    </div>

                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <PatientMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientDetails);
