import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import PatientMenu from './includes/PatientMenu';
import PatientHeader from './includes/PatientHeader';
import PatientPageHeader from './includes/PatientPageHeader';
import { _logOut, _getLoginStatus, _showOverlay, _hideOverlay, FILE_URL } from './includes/Constants';
import withNavigateHook from './includes/withNavigateHook';
import { _getAllDoctor, _getSingleDoctor } from './api/Patient';
import Toast from './includes/Toast';
import Loader from './includes/Loader';

class SpecialistDoctorList extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            medical_degree: [],
            medical_department: [],
            default_list: [],
            message: '',
            type: '',
            visibility: 'hide',
            loader: "hide"
        }

    }


    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Patient') {
                await this._getDoctors();
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }

        window.onscroll = function () { myFunction() };

        var searchIcon = document.getElementById("stickySearch");
        var sticky = searchIcon.offsetTop;

        function myFunction() {
            if (window.pageYOffset >= sticky) {
                searchIcon.classList.add("sticky")
            } else {
                searchIcon.classList.remove("sticky");
            }
        }

    }

    /**
     * 
     */
    _getDoctors = async () => {

        if (typeof this.state.user.id != "undefined" && this.state.user.id != null) {
            //_showOverlay();
            await this.setState({
                loader: "show"
            })
            let res1 = await _getSingleDoctor(this.state.user.id,this.props.location.state.doctor_id);
            if (res1.data.response == "success") {

                this.setState({
                    list: res1.data.result['doctors'],
                    medical_degree: res1.data.result['medical_degree'],
                    medical_department: res1.data.result['medical_department'],
                    default_list: res1.data.result['doctors']
                });
                await this.setState({
                    loader: "hide"
                })
            } else {
                this.setState({
                    list: [],
                    default_list: [],
                    message: res1.data.error,
                    type: 'error',
                    visibility: 'show'
                });
                await this.setState({
                    loader: "hide"
                })
            }
        }
        return;
    }

    _getListHtml = () => {

        if (Object.keys(this.state.list).length > 0) {
            return (
                <div className="card-panel grey lighten-5 z-depth-1">
                    {
                        Object.values(this.state.list).map((value, index) => {
                            return (


                                <div className={(Object.keys(this.state.list).length) - 1 == index ? "row center-align doctor-list-p" : "row center-align doctor-list-p bd-divider"} key={"key-" + index}>
                                    
                                    <div className="col s12">
                                        {
                                            value.image != null && value.image != ""
                                                ?
                                                <img src={FILE_URL + value.image} alt="" className="square responsive-img img-90" />
                                                :
                                                <img src="images/img3.png" alt="" className="square responsive-img img-90" />
                                        }


                                    </div>

                                    <div className="col s12">

                                        <span className="black-text">

                                            <h6>Dr {value.first_name + " " + value.last_name}</h6>

                                            <p className="grayTxt">
                                                {typeof value.doctor_detail.type_degree != null && value.doctor_detail.type_degree != '' ? <React.Fragment >{this.state.medical_degree[value.doctor_detail.type_degree] + ','}</React.Fragment> : ''}{this.state.medical_department[value.doctor_detail.type]}
                                            </p>
                                            <p className="grayTxt">{value.clinic_name}</p>

                                            <div className="row addressTxt">
                                                {/* <div className="col s4">
                                                             </div> */}
                                                <div className="col s12">
                                                    <p className="appointmentBtn">
                                                        {
                                                             value.type == "unregistered"
                                                                ?
                                                                <Link to="/unregistered-doctor-booking" state={{'doctor_name':value.first_name,"doctor_id": value.id,"address":value.address,"registration_fees":value.registration_fees,'fees':value.fees,'doctor_sitting':value.doctor_sitting}}><i className="material-icons">calendar_today</i> Book an Appointment</Link>
                                                                :
                                                                <Link to="/patient-doctor-profile" state={{ "address":value.doctor_detail.address,"doctor_id": value.id, 'degree': this.state.medical_degree[value.doctor_detail.type_degree], 'medical_department': this.state.medical_department[value.doctor_detail.type] }}><i className="material-icons">calendar_today</i> Book an Appointment</Link>
                                                        }
                                                    </p>
                                                    {/* <p><a href=""><i className="material-icons">subtitles</i> Go Cashless</a></p> */}
                                                </div>

                                            </div>

                                        </span>

                                    </div>

                                </div>


                            )
                        })

                    }

                </div>
            )
        } else {
            return (


                <div className="row mt-20">
                    <div className="col s12 center-align">
                        <p>No doctor could be found.</p>

                        <Link className="waves-effect waves-light btn-small" to="/other-doctor-booking">
                            Book unregistered doctors here
                        </Link>

                    </div>
                </div>

            )
        }
    }

    /**
     * 
     * @param {*} e 
     */
    _handleFilter = (e) => {
        // console.log(e.target.value);
        //console.log(this.state.list)
        let searchStr = e.target.value;
        let arr = this.state.default_list;
        let new_array = [];
        new_array = arr.filter(x => (('Dr ').toLowerCase() + x.first_name.toLowerCase() + ' ' + x.last_name.toLowerCase()).includes(searchStr.toLowerCase()));
        this.setState({
            list: new_array
        });

    }

    render() {
        return (
            <React.Fragment>
                {/* <PatientHeader /> */}
                <Loader visibility={this.state.loader} />
                <PatientPageHeader />
                <main role="main">

                    <section id="dashboard-page" className="oparcheecommonCode">

                        <div className="dashboardContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12">
                                        <div className="row">

                                        </div>
                                        <div className="locationName center-align" id="stickySearch">
                                            <form className="navbar-form" role="search">
                                                <div className="input-group" id="searchDiv">
                                                    <input type="text" className="form-control" onKeyUp={(e) => { this._handleFilter(e) }} placeholder="Search..." name="filter-doc" id="filter-doc" />
                                                    <div className="input-group-btn">
                                                        <button className="btn btn-default searchBtn" type="btn">
                                                            <i className="material-icons">search</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>

                                    </div>

                                    {/* <div className="col s12">
                                       No doctor could be found?
                                    </div> */}
                                    <div className="col s12">
                                        {this._getListHtml()}
                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <PatientMenu />
                {
                    this.state.visibility == 'show'
                        ?
                        <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
                        :
                        ''
                }
                {/* <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} /> */}
            </React.Fragment>
        );
    }
}

export default withNavigateHook(SpecialistDoctorList);

