import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";

import withNavigateHook from './includes/withNavigateHook';
import { _getDoctorSchedule, _slotConfirmation } from './api/Patient';
import Toast from './includes/Toast';
import { _getLoginStatus, _checkNull, _validateNumber, time_out, _showOverlay, _hideOverlay,_clearAllInterval } from './includes/Constants';
import DoctorsHeader from './includes/DoctorsHeader';
import DoctorsMenu from './includes/DoctorsMenu';
const date = new Date();
let $ = window.$;

class ReSchedule extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            next_data: [],
            user: {},
            message: '',
            type: '',
            visibility: 'hide',
            booking_id: 0,
            doctor_id: 0,
            loader: "hide",
            booking_date:"",
            slot:""
        }
        //alert(this.state.doctor_id)

    }

    /**
     * 
     */
    componentDidMount = async () => {
        let app = this;
        let url = window.location.search;
        let searchParams = new URLSearchParams(url);
        $('.tabs').tabs();
        $("#datepicker").datepicker({
            format: 'yyyy-mm-dd',
            onSelect: function () {
                app._handleChange();
            },
            autoClose:true
        });
        // .datepicker("setDate", new Date(searchParams.get('booking_date').replaceAll('-','/')));
     
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data,
                booking_id: searchParams.get('booking_id'),
                doctor_id: searchParams.get('doctor_id'),
                booking_date:searchParams.get('booking_date'),
                slot:searchParams.get('slot')
            });

            if (res['data']['user_type'] == 'Doctor') {
                setTimeout(() => {
                    this._getDoctorSchedule();
                }, 300);

            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
    }



    /**
     * 
     */
    _getDoctorSchedule = async () => {

        await this.setState({
            loader: "show"
        })
        let res1 = await _getDoctorSchedule(this.state.doctor_id,this.state.booking_date,this.state.slot);
        if (res1.data.response == "success") {
            this.setState({
                data: res1.data.result,
                loader: "hide"
            });
            //_hideOverlay();
        } else {
            this.setState({
                data: [],
                message: res1.data.error,
                type: 'error',
                visibility: 'show',
                laoder: "hide"
            });
            // _hideOverlay();
        }

        return;
    }

    _getTabHtml = (booking_date) => {

        if (Object.keys(this.state.data).length > 0) {
            return (
                Object.keys(this.state.data).map((value, index) => {

                    return (
                        <div key={"key" + index} className="white-bg z-depth-1">

                            <React.Fragment>
                                <h6>{value}</h6>
                                <ul className="timeSlotList">
                                    <li>
                                        <div className="timeTxt">
                                            <strong>
                                                {
                                                    this.state.data[value].status == 'open'
                                                        ?
                                                        this.state.data[value].timing
                                                        :
                                                        <del>{this.state.data[value].timing}</del>
                                                }
                                            </strong><br />

                                            {
                                                this.state.data[value].status == 'open'
                                                    ?
                                                    <div className="progress yellow">
                                                        <div className="determinate" style={{ width: "50%" }}></div>
                                                    </div>
                                                    :
                                                    <div className="progress gray">
                                                        <div className="determinate" style={{ width: "100%" }}></div>
                                                    </div>
                                            }


                                            {
                                                this.state.data[value].status == 'open'
                                                    ?
                                                    <small>Filling fast.</small>
                                                    :
                                                    <small>Sorry slots are full.</small>
                                            }

                                        </div>
                                        <div className="joinBtn">
                                            {
                                                this.state.data[value].status == 'open'
                                                    ?
                                                    <button onClick={() => { this._handleJoin(booking_date, this.state.booking_id, value) }} className="btn purple-btn">Join</button>
                                                    :
                                                    <button className="btn gray-btn">Closed</button>
                                            }

                                        </div>
                                    </li>

                                </ul>

                            </React.Fragment>



                        </div>
                    )
                })
            )
        }
    }

    /**
     * 
     * @param {*} booking_date 
     * @returns 
     */
    _getNewTabHtml = () => {
        let booking_date=$('#datepicker').val();
        if (Object.keys(this.state.next_data).length > 0) {
            return (
                Object.keys(this.state.next_data).map((value, index) => {

                    return (
                        <div key={"key" + index} className="white-bg z-depth-1">

                            <React.Fragment>
                                <h6>{value}</h6>
                                <ul className="timeSlotList">
                                    <li>
                                        <div className="timeTxt">
                                            <strong>
                                                {
                                                    this.state.next_data[value].status == 'open'
                                                        ?
                                                        this.state.next_data[value].timing
                                                        :
                                                        <del>{this.state.next_data[value].timing}</del>
                                                }
                                            </strong><br />

                                            {
                                                this.state.next_data[value].status == 'open'
                                                    ?
                                                    <div className="progress yellow">
                                                        <div className="determinate" style={{ width: "50%" }}></div>
                                                    </div>
                                                    :
                                                    <div className="progress gray">
                                                        <div className="determinate" style={{ width: "100%" }}></div>
                                                    </div>
                                            }


                                            {
                                                this.state.next_data[value].status == 'open'
                                                    ?
                                                    <small>Filling fast.</small>
                                                    :
                                                    <small>Sorry slots are full.</small>
                                            }

                                        </div>
                                        <div className="joinBtn">
                                            {
                                                this.state.next_data[value].status == 'open'
                                                    ?
                                                    <button onClick={() => { this._handleJoin(booking_date, this.state.booking_id, value) }} className="btn purple-btn">Join</button>
                                                    :
                                                    <button className="btn gray-btn">Closed</button>
                                            }

                                        </div>
                                    </li>

                                </ul>

                            </React.Fragment>



                        </div>
                    )
                })
            )
        }
    }

     /**
     * 
     */
      _handleChange = async () => {
        $('#selected-date').text($('#datepicker').val());
        await _clearAllInterval();
       
        await this._getNextDoctorSchedule();
    }

    _getNextDoctorSchedule =async()=>{
        await this.setState({
            loader: "show"
        })
        let date=$('#datepicker').val();
        let res1 = await _getDoctorSchedule(this.state.doctor_id,date,'');
        
        if (res1.data.response == "success") {
            this.setState({
                next_data: res1.data.result,
                loader: "hide"
            });
            //_hideOverlay();
        } else {
            this.setState({
                next_data: [],
                message: res1.data.error,
                type: 'error',
                visibility: 'show',
                laoder: "hide"
            });
            // _hideOverlay();
        }

        return;
    }

    /**
     * 
     * @param {*} booking_date 
     * @param {*} booking_id 
     * @param {*} slot 
     */
    _handleJoin = async (booking_date, booking_id, slot) => {
        //alert(booking_date+'-'+date.getMonth()+'-'+date.getFullYear())
        let new_booking_date = booking_date + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
        await this.setState({
            loader: "show"
        })
        // _showOverlay();
        let reg = await _slotConfirmation(new_booking_date, booking_id, slot,'Doctor');
        if (reg.data.response == 'success') {
            this.setState({

                message: reg.data.message,
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });
            setTimeout(() => {
                return (
                    this.props.navigation("/doctors-history")
                )
            }, time_out);
            // _hideOverlay();
        } else {
            this.setState({
                message: reg.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            //_hideOverlay();
        }
    }

    /**
     * 
     */
    _handleIconClick = () => {
       $("#datepicker").datepicker('open');
    }

 
    render() {

        return (

            <React.Fragment>
                {/* <PatientHeader /> */}
                <DoctorsHeader />
                <main role="main">
                    <section id="timeSlot-page">
                        <div className="timeSlotContent">
                            <div className="container-fluid ">
                                <div className="row">
                                    <div className="col s12 no-padding">
                                        <ul className="tabs center-align">
                                            <li className="tab col s6"><Link className="active" to="#test1">{new Date(this.state.booking_date.replaceAll('-','/')).getDate()}<br /><small>Date</small></Link></li>
                                            {/* <li className="tab col s6"><Link to="#test2">{date.getDate() + 1}<br /><small>Tomorrow</small></Link></li> */}
                                            <li className="tab col s6"><Link to="#test2">Another<br /><small>Schedule</small></Link></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="oparcheecommonCode">
                                <div className="container">
                                    <div className="row">
                                        <div className="col s12">
                                            <div id="test1" className="col s12">
                                                {
                                                    this._getTabHtml(date.getDate())
                                                }
                                            </div>
                                            <div id="test2" className="col s12">
                                                <div className="white-bg z-depth-1">
                                                    <div className="center-align">
                                                        <h6>Select A Date</h6>
                                                        <h3>
                                                            <i className="material-icons pointer-p" onClick={() => { this._handleIconClick() }}> calendar_today</i>
                                                        </h3>
                                                        <h6 id="selected-date">{$('#datepicker').val()}</h6>
                                                    </div>
                                                    <input onChange={() => { this._handleChange() }} id="datepicker" style={{ "visibility": "hidden", "height": "0px" }} />

                                                </div>
                                                {

                                                    this._getNewTabHtml()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <DoctorsMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(ReSchedule);
