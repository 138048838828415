import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import {_logOut} from  './Constants';
import withNavigateHook from './withNavigateHook';
class PatientHeader extends Component {

     /**
      * 
      * @param {*} param 
      */
    _handleLogout=async(e)=>{
      e.preventDefault();
      let res= await _logOut('Patient');
      this.props.navigation(res);
     }

    render() {

        return (
            <React.Fragment>
                <header>

                    <div className="oparchee-bg1">

                        <div className="container">

                            <div className="row">

                                <div className="col s8">

                                    <div className="selectLocationDiv left-align">

                                        <a className='dropdown-trigger' href='#' data-target='dropdown1'><i className="material-icons">location_on</i> <strong>Mumbai</strong> <i className="material-icons">arrow_drop_down</i></a>

                                        <ul id='dropdown1' className='dropdown-content'>

                                            <li><a href="#!"><i className="material-icons">my_location</i> Detect my location</a></li>

                                            <li><a href="#!">Pune</a></li>

                                            <li><a href="#!">Lucknow</a></li>

                                            <li><a href="#!">Barabanki</a></li>

                                            <li><a href="#!">Kanpur</a></li>

                                        </ul>

                                    </div>

                                </div>

                                <div className="col s4">

                                    <div className="skipTxt right-align">
                                         <Link to="/" onClick={(e)=>{this._handleLogout(e)}}>Logout</Link>
                                        {/* <a href=""><i className="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="http://oparchee.desicoding.com/filter.html"><i className="material-icons">filter_list</i></a> */}
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </header>
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientHeader);
