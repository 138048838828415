import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import PatientHeader from './includes/PatientHeader';
import PatientMenu from './includes/PatientMenu';
import withNavigateHook from './includes/withNavigateHook';
import { _getDoctorSchedule, _slotConfirmation } from './api/Patient';
import Toast from './includes/Toast';
import { _getLoginStatus, _checkNull, _validateNumber, time_out, _showOverlay, _hideOverlay,_clearAllInterval, _getLocalLanguage } from './includes/Constants';
import PatientPageHeader from './includes/PatientPageHeader';
import Loader from './includes/Loader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import BookingConfirmModal from './includes/BookingConfirmModal';
const language =_getLocalLanguage();
const date = new Date();
let $ = window.$;

class PatientBookAppointment extends Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            next_data: [],
            user: {},
            message: '',
            type: '',
            visibility: 'hide',
            booking_id: 0,
            doctor_id: 0,
            loader: "hide",
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            form_data: {},
            send_date: '',
        }
        //alert(this.state.doctor_id)

    }

    /**
     * 
     */
    componentDidMount = async () => {
        let app = this;
        let url = window.location.search;
        let searchParams = new URLSearchParams(url);
        $('.tabs').tabs();
        $("#datepicker").datepicker({
            format: 'yyyy-mm-dd',
            minDate:new Date(),
            onSelect: function () {
                app._handleChange();
            },
            autoClose:true
        });
        let res = await _getLoginStatus();

        if (res['status']) {
            await this.setState({
                user: res.data,
                booking_id: searchParams.get('booking_id'),
                doctor_id: searchParams.get('doctor_id')
            });

            if (res['data']['user_type'] == 'Patient') {
               // setTimeout(() => {
                    this._getDoctorSchedule(searchParams.get('doctor_id'));
                //}, 300);

            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
    }



    /**
     * 
     */
    _getDoctorSchedule = async (doctor_id) => {

        await this.setState({
            loader: "show"
        })
        let res1 = await _getDoctorSchedule(doctor_id,'','');
        if (res1.data.response == "success") {
            this.setState({
                data: res1.data.result,
                loader: "hide"
            });
            //_hideOverlay();
        } else {
            this.setState({
                data: [],
                message: res1.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            // _hideOverlay();
        }

        return;
    }

    _getTabHtml = (booking_date) => {
        
        if (Object.keys(this.state.data).length > 0) {
            return (
                Object.keys(this.state.data).map((value, index) => {

                    return (
                        <div key={"key" + index} className="white-bg z-depth-1 commonBoxInner" style={{"marginBottom": "5px"}}>

                            <React.Fragment>
                                <h6>{value}</h6>
                                <ul className="timeSlotList">
                                    <li>
                                        <div className="timeTxt">
                                            <strong>
                                                {
                                                    this.state.data[value].status == 'open'
                                                        ?
                                                        this.state.data[value].timing
                                                        :
                                                        <del>{this.state.data[value].timing}</del>
                                                }
                                            </strong><br />

                                            {
                                                this.state.data[value].status == 'open'
                                                    ?
                                                    <div className="progress yellow">
                                                        <div className="determinate" style={{ width: "50%" }}></div>
                                                    </div>
                                                    :
                                                    <div className="progress gray">
                                                        <div className="determinate" style={{ width: "100%" }}></div>
                                                    </div>
                                            }


                                            {
                                                this.state.data[value].status == 'open'
                                                    ?
                                                    <small>{this.state.text_language[0].filling_fast_text}</small>
                                                    :
                                                    <small>{this.state.text_language[0].sorry_slots_text}</small>
                                            }

                                        </div>
                                        <div className="joinBtn">
                                            {
                                                this.state.data[value].status == 'open'
                                                    ?
                                                    <button onClick={() => { this._handleJoin(booking_date, this.state.booking_id, value) }} className="btn purple-btn">{this.state.text_language[0].join_text}</button>
                                                    :
                                                    <button className="btn gray-btn">{this.state.text_language[0].closed_text}</button>
                                            }

                                        </div>
                                    </li>

                                </ul>

                            </React.Fragment>



                        </div>
                    )
                })
            )
        }
    }

    /**
     * 
     * @param {*} booking_date 
     * @returns 
     */
    _getNewTabHtml = () => {
        let booking_date=$('#datepicker').val();
        let  new_booking_date=new Date(booking_date).getDate();
        //alert(new_booking_date);
        if (Object.keys(this.state.next_data).length > 0) {
            
            return (
                Object.keys(this.state.next_data).map((value, index) => {

                    return (
                        <div key={"key" + index} className="white-bg z-depth-1 commonBoxInner" style={{"marginBottom": "5px"}}>

                            <React.Fragment>
                                <h6>{value}</h6>
                                <ul className="timeSlotList">
                                    <li>
                                        <div className="timeTxt">
                                            <strong>
                                                {
                                                    this.state.next_data[value].status == 'open'
                                                        ?
                                                        this.state.next_data[value].timing
                                                        :
                                                        <del>{this.state.next_data[value].timing}</del>
                                                }
                                            </strong><br />

                                            {
                                                this.state.next_data[value].status == 'open'
                                                    ?
                                                    <div className="progress yellow">
                                                        <div className="determinate" style={{ width: "50%" }}></div>
                                                    </div>
                                                    :
                                                    <div className="progress gray">
                                                        <div className="determinate" style={{ width: "100%" }}></div>
                                                    </div>
                                            }


                                            {
                                                this.state.next_data[value].status == 'open'
                                                    ?
                                                    <small>{this.state.text_language[0].filling_fast_text}</small>
                                                    :
                                                    <small>{this.state.text_language[0].sorry_slots_text}</small>
                                            }

                                        </div>
                                        <div className="joinBtn">
                                            {
                                                this.state.next_data[value].status == 'open'
                                                    ?
                                                    <button onClick={() => { this._handleJoin(new_booking_date, this.state.booking_id, value) }} className="btn purple-btn">{this.state.text_language[0].join_text}</button>
                                                    :
                                                    <button className="btn gray-btn">{this.state.text_language[0].closed_text}</button>
                                            }

                                        </div>
                                    </li>

                                </ul>

                            </React.Fragment>



                        </div>
                    )
                })
            )
        }
    }

     /**
     * 
     */
      _handleChange = async () => {
        $('#selected-date').text($('#datepicker').val());
        await _clearAllInterval();
       
        await this._getNextDoctorSchedule();
    }

    _getNextDoctorSchedule =async()=>{
        await this.setState({
            loader: "show"
        })
        let date=$('#datepicker').val();
        let res1 = await _getDoctorSchedule(this.state.doctor_id,date,'');
        if (res1.data.response == "success") {
            this.setState({
                next_data: res1.data.result,
                loader: "hide"
            });
            //_hideOverlay();
        } else {
            this.setState({
                next_data: [],
                message: res1.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            // _hideOverlay();
        }

        return;
    }

    /**
     * 
     * @param {*} booking_date 
     * @param {*} booking_id 
     * @param {*} slot 
     */
    _handleJoin = async (booking_date, booking_id, slot) => {
        // alert('booking = '+booking_date);
        let send_booking_date=date.getFullYear()+ '-' + (date.getMonth() + 1) + '-' +booking_date;
        let new_booking_date = booking_date + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
        //  alert('send booking = '+send_booking_date);
        //  alert('new booking = '+new_booking_date);
        await this.setState({
            loader: "show",
            send_date:send_booking_date
        })
        // _showOverlay();
        let reg = await _slotConfirmation(new_booking_date, booking_id, slot,'');
        if (reg.data.response == 'success') {
            this.setState({
                message: reg.data.message,
                type: 'success',
                visibility: 'show',
                loader: "hide"
            });
           // setTimeout(() => {
                // return (
                //     this.props.navigation("/patient-history?booking_date="+send_booking_date)
                // )
           // }, time_out);
            // _hideOverlay();
        } else {
            this.setState({
                message: reg.data.error,
                type: 'error',
                visibility: 'show',
                loader: "hide"
            });
            //_hideOverlay();
        }
    }

    /**
     * 
     */
    _handleIconClick = () => {
       $("#datepicker").datepicker('open');
    }

 
    render() {

        return (

            <React.Fragment>
                  <Loader visibility={this.state.loader} />
                {/* <PatientHeader /> */}
                <PatientPageHeader />
                <main role="main">
                    <section id="timeSlot-page">
                        <div className="timeSlotContent">
                            <div className="container-fluid ">
                                <div className="row">
                                    <div className="col s12 no-padding">
                                        <ul className="tabs center-align">
                                            <li className="tab col s6"><Link className="active" to="#test1">{date.getDate()}<br /><small>{this.state.text_language[0].today_text}</small></Link></li>
                                            {/* <li className="tab col s6"><Link to="#test2">{date.getDate() + 1}<br /><small>Tomorrow</small></Link></li> */}
                                            <li className="tab col s6"><Link to="#test2">{this.state.text_language[0].another_text}<br /><small>{this.state.text_language[0].day_text}</small></Link></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="oparcheecommonCode">
                                <div className="container">
                                    <div className="row">
                                        <div className="col s12">
                                            <div id="test1" className="col s12">
                                                {
                                                    this._getTabHtml(date.getDate())
                                                }
                                            </div>
                                            <div id="test2" className="col s12">
                                                <div className="white-bg z-depth-1 commonBoxInner" style={{"marginBottom": "5px"}}>
                                                    <div className="center-align">
                                                        <h6>{this.state.text_language[0].select_a_date_text}</h6>
                                                        <h3>
                                                            <i className="material-icons pointer-p" onClick={() => { this._handleIconClick() }}> calendar_today</i>
                                                        </h3>
                                                        <h6 id="selected-date">{$('#datepicker').val()}</h6>
                                                    </div>
                                                    <input onChange={() => { this._handleChange() }} id="datepicker" style={{ "visibility": "hidden", "height": "0px" }} />

                                                </div>
                                                {

                                                    this._getNewTabHtml()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <PatientMenu />
                <BookingConfirmModal send_date={this.state.send_date} form_data={this.state.form_data} message={this.state.message} type={this.state.type} visibility={this.state.visibility} page={"refresh"}/>
               
                {/* <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} /> */}
            </React.Fragment>
        );
    }
}

export default withNavigateHook(PatientBookAppointment);
