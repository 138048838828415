import React, { Component } from 'react'
import { modal_time, _getLocalLanguage } from './Constants';
import { lang_en } from './language/en';
import { lang_hi } from './language/hi';
const language = _getLocalLanguage();
let $ = window.$;

export default class WelcomeModal extends Component {
    constructor(props) {

        super(props);
        // state declaration
        this.state = {
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            message: this.props.message,
            type: this.props.type,
            visibility: this.props.visibility
        }

    }


    componentDidMount = () => {
      

    }

    _checkVisibility = () => {
        
        if (this.props.visibility == 'show') {
            $('#modal-3').modal({
                dismissible: false
            });
            $('#modal_welcome_trigger')[0].click();
            setTimeout(() => {
                $('#modal-3').modal('close');
            }, modal_time);
        }
    }

    render() {

        return (
            <div>
                {
                    this._checkVisibility()
                }
                <a className="waves-effect waves-light btn modal-trigger" id="modal_welcome_trigger" style={{ display: "none" }} href="#modal-3">Modal</a>
                <div id="modal-3" className="modal br-radius">
                    <div className="modal-content">
                        <h1 className='center-align'>
                            {/* {this.props.} */}
                        </h1>
                        <div className='row center-align'>
                            <div className='col s12'>
                                {
                                        this.props.type == "success"
                                        ?
                                        <img className="responsive-img" src="images/namaste.png" alt="Welcome" />
                                        // <img className="responsive-img" src="images/welcome-back.png" alt="Welcome" />
                                        :
                                        this.props.type == "error"?
                                        <img className="responsive-img" src="images/stop.png" alt="Stop" />
                                        :
                                        ""
                                       
                                }
                            </div>
                        </div>
                        <div className='row center-align'>
                            <div>
                                {
                                    this.props.type == "success"
                                        ?
                                        <div className='col s12'>
                                            <h1>{this.state.text_language[0].welcome_text}</h1>
                                            <p>{this.state.text_language[0].we_welcome_text}</p>
                                        </div>
                                        :
                                        this.props.type == "error"?
                                        <div className='col s12'>
                                            <h1>{this.state.text_language[0].sorry_text}</h1>
                                            <p>{this.state.text_language[0].wrong_crendentials_text}</p>
                                        </div>
                                        :
                                        ""
                                }

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        )
    }
}
