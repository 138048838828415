import React, { Component } from 'react'
import { _getLocalLanguage } from './Constants';
import { lang_en } from './language/en';
import { lang_hi } from './language/hi';
const language= _getLocalLanguage();
const startDate = new Date();
 class Copyright extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text_language:language.lang == 'en'?lang_en:lang_hi
        }
    }
   
    render() {
        return (
            <div className="row">

                <div className="col s12 center-align">
                {this.state.text_language[0].copyright_text} {startDate.getFullYear()} {this.state.text_language[0].all_right_reserved_text}
                </div>
                <div className="col s12 center-align">
                {this.state.text_language[0].venture_text}
                </div>
            </div>
        )
    }
}

export default Copyright;
