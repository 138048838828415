import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import { _editDoctorProfile } from './api/Doctor';
import { _checkNull, _getLoginStatus, _validateNumber ,_validateEmail } from './includes/Constants';
import HospitalHeader from './includes/HospitalHeader';
import HospitalMenu from './includes/HospitalMenu';
// import DoctorsHeader from './includes/DoctorsHeader';
// import DoctorsMenu from './includes/DoctorsMenu';
import Loader from './includes/Loader';
import Toast from './includes/Toast';
import withNavigateHook from './includes/withNavigateHook';
class HospitalEditProfile extends Component {
   /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            list: [],
            message: '',
            type: '',
            visibility: 'hide',
            error_message: {
                name: '',
                phone_number: ''
            },
            loader:"hide"
        }
    }
    /**
     * 
     * @returns 
     */
    componentDidMount = async () => {
        let res = await _getLoginStatus();
        //console.log(res);
        if (res['status']) {
            await this.setState({
                user: res.data
            });
            if (res['data']['user_type'] == 'Doctor') {
              
            } else {
                this.props.navigation('/home');
            }

        } else {
            this.props.navigation('/home');
        }
 
    }

        /**
     * 
     * @param {*} e 
     */
         async _handleManualBooking(e) {
            e.preventDefault();
            //_showOverlay();
            await this.setState({
                loader:"show"
            })
            const data = new FormData(e.target);
           
            let name = data.get('name');
            let phone_number = data.get('phone_number');
            let email = data.get('email');
             
            let image=data.get('image');
    
            let res = await this._validateBooking(data);
            if (res['status']) {
    
                this.setState({
                    error_message: res.error
                });
    
                let reg = await _editDoctorProfile(name,'',phone_number, email,image,this.state.user.id,'Hospital');
                if (reg.data.response == 'success') {
                    if (window.localStorage) {
                        await window.localStorage.setItem('user', JSON.stringify(reg.data.result));
                        // await window.localStorage.setItem('user_token',JSON.stringify(res.data.token));
                        // window.location.href='/asset-pwa/engineer/dashboard';
                    }
                    this.setState({
                        message: reg.data.message,
                        type: 'success',
                        visibility: 'show',
                        loader:"hide"
                    });
                    
                    //_hideOverlay();
                } else {
                    this.setState({
                        message: reg.data.error,
                        type: 'error',
                        visibility: 'show',
                        loader:"hide"
                    });
                   // _hideOverlay();
                }
    
            } else {
                this.setState({
                    error_message: res.error,
                    loader:"hide"
                });
                //_hideOverlay();
            }
    
        }
    
        /**
         * 
         * @param {*} data 
         * @returns 
         */
        _validateBooking = (data) => {
            //console.log(data)
            let name = data.get('name');
            let phone_number = data.get('phone_number');
            let email = data.get('email');
    
            let response = [];
            response['status'] = false;
            response['error'] = [];
    
            if (typeof name != "undefined" && name != undefined && name.trim() != '' &&
                 typeof phone_number != "undefined" && phone_number != undefined && phone_number.trim() != '') {
                    if (_validateEmail(email)) {
                        if (!_validateNumber(phone_number)) {
                            response['error']['phone_number'] = "Please provide a valid phone number in numbers.";
                        } else {
                            if(phone_number.toString().length == 10){
                                response['status'] = true;
                            }else{
                                response['error']['phone_number'] = "Please provide a valid phone number.";
                            }
                            //response['status'] = true;
                        }
                    }else{
                        response['error']['email'] = "Please provide a valid Email Address.";
                    }
               
            } else {
    
                response['error']['name'] = _checkNull(name);
                response['error']['phone_number'] = _checkNull(phone_number);
                response['error']['email'] = _checkNull(email);
    
                if (response['error']['phone_number'].trim() == '' || response['error']['phone_number'] == null) {
    
                    if (_validateNumber(phone_number)) {
                        if(phone_number.toString().length == 10){
                            //response['status'] = true;
                        }else{
                            response['error']['phone_number'] = "Please provide a valid phone number.";
                        }
                    } else {
                        response['error']['phone_number'] = "Please provide a valid phone number in numbers.";
    
                    }
                }
                if (response['error']['email'].trim() == '' || response['error']['email'] == null) {

                    if (_validateEmail(email)) {
                        //response['status'] = true;
                    } else {
                        response['error']['email'] = "Please provide a valid Email Address.";
    
                    }
                }
    
            }
    
            return response;
        }


    render() {

        return (
            <React.Fragment>
                 <Loader visibility={this.state.loader}/>
                 <HospitalHeader />
                <main role="main">

                    <section id="profile-editing-option-page" className="oparcheecommonCode">

                        <div className="profile-editing-optionContent">

                            <div className="container">

                                <div className="row">

                                    <div className="col s12 l4 offset-l4">

                                        <div className="white-bg commonBoxInner">

                                            <div className="row">

                                                <div className="col s12 center-align">

                                                    {/* <img className="responsive-img logoImg" src="images/big-logo.png" alt="Oparchee" /> */}

                                                    <h1>Profile Editing Option</h1>

                                                </div>

                                            </div>

                                            <div className="row">

                                                
                                            <form method="post" onSubmit={(e) => this._handleManualBooking(e)} encType="multipart/form-data">

                                                    <div className="col s12">

                                                        <div className="input-field">

                                                            <input placeholder="" type="text"  defaultValue={this.state.user.name} name="name"  id="name" className="validate" />

                                                            <label className="active" htmlFor="name">Hospital Name</label>
                                                            <div className="error">{this.state.error_message.name}</div>

                                                        </div>
                                                        
                                                        <div className="input-field">

                                                            <input placeholder="" type="text"   defaultValue={this.state.user.phone_number} name="phone_number"   id="phone_number" className="validate" />

                                                            <label className="active" htmlFor="phone_number">Phone</label>
                                                            <div className="error">{this.state.error_message.phone_number}</div>

                                                        </div>

                                                        <div className="input-field">

                                                            <input placeholder="" type="email"  defaultValue={this.state.user.email} id="email" name="email" className="validate" />

                                                            <label className="active" htmlFor="email">Email</label>
                                                            {/* <div className="error">{this.state.error_message.email}</div> */}
                                                        </div>
                                                       

                                                        <h6 className="grayTxt">Profile Picture</h6>

                                                        <div className="input-field">

                                                            <div className="file-field input-field">

                                                                <div className="btn">

                                                                    <span><i className="material-icons">image</i></span>

                                                                    <input type="file" name="image" accept="image/*" />

                                                                </div>

                                                                <div className="file-path-wrapper">

                                                                    <input className="file-path validate" type="text" placeholder="Upload profile picture" />

                                                                </div>

                                                            </div>

                                                        </div>

                                                        <button type="submit" className="btn btn-primary continuebtn">Submit</button>

                                                    </div>

                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>

                </main>
                <HospitalMenu />
                {/* {
          this.state.visibility =='show'
          ?
          <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
          :
          ''
        } */}
                <Toast message={this.state.message} type={this.state.type} visibility={this.state.visibility} />
            </React.Fragment>
        );
    }
}

export default withNavigateHook(HospitalEditProfile);
