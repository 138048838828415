import React, { Component } from 'react'
import {
    Link
} from "react-router-dom";

import withNavigateHook from './includes/withNavigateHook';
import { _getLocalLanguage, _getLocationCookie, _getLoginStatus, _setLocationCookie, _showOverlay } from './includes/Constants';
import PatientPageHeader from './includes/PatientPageHeader';
import { lang_en } from './includes/language/en';
import { lang_hi } from './includes/language/hi';
import { _getStaticPages } from './api/Common';
const language = _getLocalLanguage();

class RefundPolicy extends Component {

    constructor(props) {

        super(props);
        // state declaration
        this.state = {
            text_language: language.lang == 'en' ? lang_en : lang_hi,
            user: {},
            content: {},
            selected_location: _getLocationCookie()
        }

    }
    /**
    * 
    * @returns 
    */
    componentDidMount = async () => {

        let res = await _getLoginStatus();

        if (res['status']) {
            let content = await _getStaticPages('refund_policy');
            // console.log(content);
            await this.setState({
                user: res.data,
                content: content.data.result
            });
            await this.setState({
                user: res.data
            });
        } else {
            this.props.navigation('/home');
        }
        // _showOverlay();
    }

    render() {
        return (
            <React.Fragment>
                <PatientPageHeader />
                <main role="main">

                    <section id="welcomePage" className="oparcheecommonCode">

                        <div className="welcomePageContent">

                            <div className="container">
                                <div className='white-bg center-align commonBoxInner m-center'>
                                    <div className="row pt-20">
                                        <div className="col s12">

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            <div dangerouslySetInnerHTML={{ __html: this.state.content.content }}></div>
                                        </div>

                                    </div>

                                </div >

                            </div >

                        </div >

                    </section >

                </main >
            </React.Fragment >

        )
    }
}

export default withNavigateHook(RefundPolicy)
