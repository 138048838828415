export const lang_en = [{
    "new_text": "New",
    "version_text": "Version",
    "patient_portal_text": "Patient's Portal",
    "doctor_portal_text": "Doctor's Portal",
    "phone_text": "Mobile",
    "mobile_text": "Mobile",
    "number_text": "Number",
    "email_text": "Email",
    "password_text": "Password",
    "forgot_password_text": "Forgot Password?",
    "login_text": "Login",
    "seamless_experience_text": "Want to have a seamless experience?",
    "download_our_app_text": "Download our app now.",
    "download_text": "Download",
    "copyright_text": "Copyright ©",
    "all_right_reserved_text": "All rights reserved",
    "venture_text": "A venture of KDRR PVT. LTD.",
    "no_account_text": "Don't have an account?",
    "register_text": "Register",
    "loading_div_text": "Loading please wait.....",
    "welcome_text": "Welcome!",
    "search_text": "Search",
    "home_text": "Home",
    "account_text": "Account",
    "emergency_text": "Emergency",
    "history_text": "History",
    "contact_us_text": "Contact Us",
    "faq_text": "FAQ's",
    "app_version_text": "App Version",
    "v_text": "V",
    "registration_text": "Registration",
    "full_name_text": "Full Name",
    "number_text": "Number",
    "confirm_text": "Confirm",
    "already_have_account_text": "Already have an account?",
    "login_text": "Login",
    "here_text": "Here",
    "dynamic_note_text": "Medical delivery is online. We will deliver you daily dose of healthiness.",
    "extra_text": "",
    "banner_text": "Book appointments with wide variety of doctors.",
    "book_text": "Book",
    "parchee_text": "Parchee",
    "other_text": "Other",
    "labs_text": "Labs",
    "medical_text": "Medical",
    "delivery_text": "Delivery",
    "ambulance_text": "Ambulance",
    "featured_services_text": "Featured Services",
    "service_text": "Service",
    "shoulder_text": "Shoulder",
    "pain_text": "Pain",
    "stomach_text": "Stomach",
    "headche_text": "Headache",
    "back_text": "Back",
    "cough_text": "Cough",
    "fever_text": "Fever",
    "acne_text": "Acne",
    "blood_pressure_text": "Blood Pressure",
    "blood_text": "Blood",
    "pressure_text": "Pressure",
    "diabetes_text": "Diabetes",
    "consult_text": "Consult",
    "now_text": "Now",
    "community_message_text": "We have a large community of doctors in your city.",
    "our_text": "Our",
    "mission_text": "Mission",
    "motto_text": "Motto",
    "our_mission_motto_text": "Our small venture was built keeping in mind the troubles a patient has to go through for availing even basic health services such as getting timely appointments for a doctor or a lab test or getting medicines that are needed. We aspire to bring ease to every patient by helping them save their precious time and effort, which should rather be spent on maintaining their own health.",
    "hospitals_text": "Hospitals",
    "doctors_text": "Doctors",
    "users_text": "Users",
    "an_text": "an",
    "book_an_appointment_text": "Book an appointment",
    "appointment_text": "Appointment",
    "appointments_text": "Appointments",
    "search_text": "Search",
    "status_text": "Status",
    "details_text": "Details",
    "of_text": "of",
    "patient_text": "Patient",
    "for_text": "for",
    "whom_text": "whom",
    "address_text": "Address",
    "age_text": "Age",
    "problem_text": "Problem",
    "doctor_text": "Doctor",
    "name_text": "Name",
    "booking_text": "Booking",
    "date_text": "Date",
    "fees_text": "Fees",
    "app_text": "App",
    "charges_text": "Charges",
    "prescription_text": "Prescription",
    "image_text": "Image",
    "upload_text": "Upload",
    "upload_multiple_text": "Upload one or more files",
    "submit_text": "Submit",
    "unregistered_text": "Unregistered",
    "book_unregistered_text": "Book unregistered doctors here",
    "no_doctor_found_text": "No doctor could be found.",
    "booking_text": "Booking",
    "slot_text": "Slot",
    "on_text": "On",
    "edit_text": "Edit",
    "profile_text": "Profile",
    "change_text": "Change",
    "logout_text": "Logout",
    "oparchee_text": "O-Parchee",
    "recover_text": "Recover",
    "consult_specialist_doctors_text": "Consult directly to our specialist doctors.",
    "click_on_icon_text": "Click on the icons to browse our specialist doctors.",
    "general_physician_text": "General Physician",
    "bone_text": "Bone",
    "specialist_text": "Specialist",
    "dentist_text": "Dentist",
    "gastro_text": "Gastro.",
    "heart_&_neuro_text": "Heart & Neuro.",
    "female_text": "Female",
    "new_version_text": "New Version",
    "new_version_summary_text": "New version of the app is available. Please click on Update button to update the app.",
    "update_text": "Update",
    "language_text": "Language",
    "choose_text": "Choose Language",
    "hindi_text": "Hindi",
    "english_text": "English",
    "continue_text": "Continue",
    "have_health_issues_text": "Have health issues?",
    "vision_motto_text": "Our Vision & Motto",
    "self_text": "Self",
    "loading_wait_text": "Loading please wait.....",
    "has_not_started_text": "has not started yet.",
    "is_consult_text": "is consulting the doctor now.",
    "has_done_consult_text": "has done consulting the doctor.",
    "token_num_text": "Token no.",
    "info_text": "Info.",
    "distance_text": "Distance",
    "filling_fast_text": "Filling fast.",
    "sorry_slots_text": "Sorry slots are full.",
    "closed_text": "Closed",
    "join_text": "Join",
    "today_text": "Today",
    "another_text": "Another",
    "day_text": "Day",
    "morning_text": "Morning",
    "evening_text": "Evening",
    "afternoon_text": "Afternoon",
    "select_a_date_text": "Select A Date",
    "help_&_support": "Help & Support",
    "we_welcome_text": "We welcome you on the O-Parchee.",
    "logout_message_text":"You have successfully logged out of O-Parchee",
    "sorry_text": "Sorry!",
    "wrong_crendentials_text": "Wrong phone number or password provided.",
    "booking_confirmation_text": "Booking Confirmation",
    "booking_added_text": "Booking added successfully.",
    "for_any_query_text": "For any queries contact us below",
    "contact_us_whatsapp_text": "Contact us on Whatsapp",
    "previous_order_text": "Previous Orders",
    "new_orders_text": "New Orders",
    "purchase_text": "Order",
    "order_details_text": "Order Details",
    "normal_text": "Normal",
    "urgent_text": "Urgent",
    "delivery_type_text": "Delivery Type",
    "order_confirmation_text": "Medical Order Confirmation",
    "order_added_text": "Order added successfully.",
    "delivery_text": "Delivery",
    "booking_error_text": "There was an error while making a booking.",
    "please_try_again_later_text": "Please try again later.",
    "amount_text": "Amount",
    "payment_text": "Payment",
    "city_text": "City",
    "doctor_prescription_image_text": "Doctor Prescription Image",
    "file_not_found_text": "File not found",
    "medical_delivery_history_text": "Medical Delivery History",
    "medical_delivery_text": "Medical Delivery",
    "km_charges_text": "* urgent delivery per KM charges.Distance between the delivery address and delivery pickup address.",
    "order_confirmation_call_text": "A registered pharmacist will call you to confirm your order & its payment details.",
    "account_settings_text": "Account Settings",
    "total_bookings_text": "Total Bookings",
    "booking_details_text": "Booking Details",
    "pending_booking_text": "Pending Booking",
    "cancelled_booking_text": "Cancelled Booking",
    "accepted_booking_text": "Accepted Booking",
    "agent_offer_text": "Special booking offer for registered booking agents.Get cash ₹ 1000 for every 70 new accepted bookings.",
    "about_us_text": "About Us",
    "refund_policy_text": "Refund Policy",
    "privacy_policy_text": "Privacy Policy",
    "terms_and_condition_text": "Terms And Condition",
    "test_name_text": "Test Name",
    "lab_text": "Lab",
    "test_text": "Test",
    "non_trauma_emergency_text": "Non Trauma Emergency",
    "trauma_emergency_text": "Trauma Emergency",
    "emergency_order_confirmation_call_text": "Our call center executive will call you to confirm your emergency medical booking and other details.",
    "deoria_text":"Deoria",
    "gorakhpur_text":"Gorakhpur",
    "government_text":"Government",
    "department_text":"Department",
    "ophthalmology_text":"Ophthalmology",
    "cardiology_text":"Cardiology",
    "ent_text":"Ears,Nose and Throat",
    "surgeon_text":"Surgeon",
    "general_physician_text":"General Physician",
    "chest_text":"Chest",
    "psychiatrist_text":"Psychiatrist",
    "dermatology_text":"Dermatology",
    "medicine_text":"Medicine",
    "obstetrics_and_gynaecology":"Obstetrics And Gynaecolgy",
    "namaste_text":"Namaste",
    "verified_oparchee_doctors":"Book appointment for verified Oparchee doctors"
    










}];